<div style="display: flex; width: 200px; height: 21px;" class="rb-document-upload">
  <p-button type="button"
            class="black-icon-btn"
            icon="pi pi-paperclip"
            [disabled]="disabled"
            (onClick)="click()"></p-button>
  <p-button type="button"
            class="black-icon-btn"
            icon="pi pi-trash"
            [disabled]="disabled"
            (onClick)="delete()"></p-button>
  <div style="flex: 1; min-width: 0;">
    <p-fileUpload
      #fileUpload
      [files]="files"
      [disabled]="disabled"
      [auto]="true"
      name="multipartFile"
      (onUpload)="finishedUpload($event)"
      (onBeforeUpload)="onBeforeUpload()"
      [url]="documentService.uploadUrl"
      accept=".pdf,.doc,.docx"
      maxFileSize="10000000"
      (onError)="onError($event)"
      invalidFileTypeMessageSummary=""
      invalidFileTypeMessageDetail="Invalid file type!"
      invalidFileSizeMessageSummary=""
      invalidFileSizeMessageDetail="Max size is {0}."
    >
      <ng-template let-file pTemplate="file"></ng-template>
      <ng-template pTemplate="content">
        <div style="display: flex;">
          <div *ngIf="document" class="link"><a href="{{document._links.download.href}}"
                                                download>{{document.fileName}}</a></div>
          <p-progressSpinner
            *ngIf="onProgress"
            [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="transparent"
            animationDuration=".5s"></p-progressSpinner>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
  <rb-error-marker [control]="ngControl.control"></rb-error-marker>
</div>
