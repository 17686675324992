import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FormService} from "../../../../../services/form.service";

@Component({
  selector: 'rb-product-composition-swiss-only',
  templateUrl: './product-composition-swiss-only.component.html',
  styleUrls: ['product-composition-swiss-only.component.scss', './../../data.component.scss']
})
export class ProductCompositionSwissOnlyComponent {
  @Input() form: FormGroup;

  constructor(public formService: FormService) {
  }
}
