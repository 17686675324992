<div *ngIf="user$ | async as user; else loading" class="container">
  <a (click)="op.toggle($event)" class="user">
    <rb-avatar [style.height]="'48px'"
               [style.width]="'48px'"
               class="user-avatar"
               text="{{user?.firstName.substr(0, 1)}}{{user?.lastName.substr(0, 1)}}"
    ></rb-avatar>
  </a>

  <p-overlayPanel #op [dismissable]="true">
    <div class="user-menu">
      <div class="user-info">
        <rb-avatar [style.height]="'60px'"
                   [style.width]="'60px'" class="user-menu-avatar"
                   text="{{user.firstName.substr(0, 1)}}{{user.lastName.substr(0, 1)}}">

        </rb-avatar>

        <div class="user-details">
          <div class="user-name">{{user.firstName}} {{user.lastName}}</div>
          <br/>
          <div class="user-email" pTooltip="{{user.email}}"
               tooltipStyleClass="user-email-tooltip">{{user.email}}</div>
        </div>
      </div>
      <div class="user-actions">
      </div>
    </div>
  </p-overlayPanel>
</div>

<ng-template #loading>
  <a class="user">
    <rb-avatar [style.height]="'48px'"
               [style.width]="'48px'"
               class="user-avatar"
               text="?"
    ></rb-avatar>
  </a>
</ng-template>
