import {AbstractControl, ValidationErrors} from "@angular/forms";
import {DateUtil} from "./date-util";
import {ScoringUtil} from "./scoring-helper/ScoringUtil";

export class MyValidators {
  public static dateNotInPast(control: AbstractControl): ValidationErrors {
    return control.value && control.value < DateUtil.getTodayAsUtcDate() ? {"error": "Date must not be in the past"} : null;
  }

  public static requiredIfParentWoodCompositionHasInput(control: AbstractControl): ValidationErrors {
    if (!control.value && control.parent && (
      control.parent.get('percentage').value
      || control.parent.get('volume').value.amount
      || control.parent.get('recycledPercentage').value
      || control.parent.get('recycledVolume').value.amount
      || control.parent.get('nonRecycledPercentage').value
      || control.parent.get('nonRecycledVolume').value.amount
    )) {
      return {"entireRow": "Please fill out the entire row!"};
    } else {
      return null;
    }
  }

  public static volumeRequiredIfParentWoodCompositionHasInput(control: AbstractControl): ValidationErrors {
    if (!control.value.amount && control.parent && (
      control.parent.get('percentage').value
      || control.parent.get('volume').value.amount
      || control.parent.get('recycledPercentage').value
      || control.parent.get('recycledVolume').value.amount
      || control.parent.get('nonRecycledPercentage').value
      || control.parent.get('nonRecycledVolume').value.amount
    )) {
      return {"entireRow": "Please fill out the entire row!"};
    } else {
      return null;
    }
  }

  public static sumOfPercentagesInParentComponentNodeMustBe100(control: AbstractControl): ValidationErrors {
    // @ts-ignore
    if (
      (control.parent?.parent?.get('massiveWoodNode').get('percentage').value || 0)
      + (control.parent?.parent?.get('particleBoardNode').get('percentage').value || 0)
      + (control.parent?.parent?.get('plywoodNode').get('percentage').value || 0)
      !== 100) {
      return {"sumPercentages": "The total of all fractions should be 100%."};
    } else {
      return null;
    }
  }

  public static requiredIfParentWoodSpeciesRecordHasInput(control: AbstractControl): ValidationErrors {
    if (!control.value && control.parent && (
      control.parent.get('woodSpecies').value
      || control.parent.get('volume').value.amount
      || control.parent.get('countryOfHarvest').value
      || control.parent.get('regionOfHarvest').value
    )) {
      return {"entireRow": "Please fill out the entire row!"};
    } else {
      return null;
    }
  }

  public static atLeastOneRowRequired(control: AbstractControl): ValidationErrors {
    if (
      !control.value
      && control.parent?.parent?.parent
      && !ScoringUtil.getWoodSpeciesRecordsForNatureSpeciesOriginWood(control.parent.parent.parent.value)
        .some(wsr => wsr.woodSpecies && wsr.volume.amount != null && wsr.countryOfHarvest && wsr.regionOfHarvest)
    ) {
      return {"atLeastOneRow": "Please fill out at least 1 row!"};
    } else {
      return null;
    }
  }
}
