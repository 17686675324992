import {Component, EventEmitter, Output} from '@angular/core';
import {ReferenceDataService} from "../../services/reference-data.service";
import {Volume, VolumeUnit} from "../contracts";
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Component({
  selector: 'rb-volume',
  templateUrl: './volume.component.html',
  styleUrls: ['./volume.component.scss'],
})
export class VolumeComponent implements ControlValueAccessor {
  @Output() onAmountChange: EventEmitter<Volume> = new EventEmitter();
  @Output() onUnitChange: EventEmitter<Volume> = new EventEmitter();

  // TODO_JORIS dit zit op zich nog wel scheef
  constructor(public referenceDataService: ReferenceDataService, public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };
  val: Volume;
  disabled: boolean;

  get value(): Volume {
    return this.val;
  }

  set value(val: Volume) {
    if (val && this.val !== val) {
      this.val = val;
      this.changed();
    }
  }

  changedAmount(val: number) {
    this.value = {
      unit: this.value.unit,
      amount: val
    };
    this.onAmountChange.emit(this.value);
  }

  changedUnit(val: number) {
    this.value = {
      unit: val,
      amount: this.value.amount
    };
    this.onUnitChange.emit(this.value);
  }

  changed() {
    this.onChange(this.val);
    this.onTouch(this.val);
  }

  writeValue(value: any) {
    this.value = value ? value : {amount: null, unit: VolumeUnit.M3};
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
