import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FormService} from "../../../../../../services/form.service";

@Component({
  selector: 'rb-repeatable-species-record',
  templateUrl: './repeatable-species-record.component.html',
  styleUrls: ['./repeatable-species-record.component.scss', '../../../data.component.scss']
})
export class RepeatableWoodSpeciesRecordComponent {
  @Input() title: string;
  @Input() records: FormGroup;

  constructor(public formService: FormService) {
  }
}
