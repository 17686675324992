<p-accordion [multiple]="true">
  <rb-repeatable-accordion-tab [records]="records"
                               [header]="computeForestryConcessionHeader()"
                               [newRecordSupplier]="formService.createForestryConcessionNode.bind(formService)">
    <ng-template #header let-forestryConcessionRecord let-index="index">
      <span>{{computeTitle('Forestry concession', index, forestryConcessionRecord.get('name')?.value)}}</span>
    </ng-template>
    <ng-template #record let-forestryConcessionRecord let-fcIndex="index">
      <rb-chain-search [nodeSummaries]="chainService.forestryConcessionNodeSummaries"
                       (selectedNode)="onConfirmedNode($event, fcIndex)"
                       [disabled]="forestryConcessionRecord.disabled"
      ></rb-chain-search>
      <div [formGroup]="forestryConcessionRecord">
        <div class="info-grid">
          <label for="forestry-concession-name-{{uid}}-{{fcIndex}}" class="required">Name:</label>
          <div>
            <rb-input id="forestry-concession-name-{{uid}}-{{fcIndex}}" formControlName="name"
                      classToApply="width-200px"></rb-input>
          </div>
          <label for="forestry-concession-country-{{uid}}-{{fcIndex}}" class="required">Country/Region:</label>
          <div style="display: flex; align-items: center;">
            <rb-dropdown inputId="forestry-concession-country-{{uid}}-{{fcIndex}}"
                         formControlName="country"
                         [options]="referenceDataService.getChainPartsCountries()"
                         styleClass="width-200px"></rb-dropdown>
            <span>/</span>
            <rb-input formControlName="region" classToApply="width-200px"></rb-input>
          </div>
          <label for="forestry-concession-certification-{{uid}}-{{fcIndex}}">Certification:</label>
          <rb-dropdown inputId="forestry-concession-certification-{{uid}}-{{fcIndex}}"
                       formControlName="certification"
                       [options]="referenceDataService.getChainPartsCertification()"
                       [group]="true"
                       styleClass="width-200px"></rb-dropdown>
          <label for="forestry-concession-coc-code-{{uid}}-{{fcIndex}}">CW/FM/CoC:</label>
          <rb-input id="forestry-concession-coc-code-{{uid}}-{{fcIndex}}" formControlName="cocCode"
                    classToApply="width-200px"></rb-input>
          <div></div>
          <div></div>
          <label for="forestry-concession-certification-type-{{uid}}-{{fcIndex}}">Certification Type and %<i
            class="pi pi-info-circle"
            pTooltip="(100%, Mix x%, Mix credit ...)"></i>
          </label>
          <rb-input id="forestry-concession-certification-type-{{uid}}-{{fcIndex}}"
                    formControlName="certificationTypeAndPercentage" classToApply="width-200px"></rb-input>
        </div>
      </div>
      <hr/>
      <rb-forest-managers-data
        [data]="forestryConcessionRecord.get('forestManagersData')"></rb-forest-managers-data>
      <rb-exporters-data
        [data]="forestryConcessionRecord.get('exportersData')"></rb-exporters-data>
    </ng-template>
  </rb-repeatable-accordion-tab>
</p-accordion>
