<fieldset>
  <legend>Product certification
    <rb-error-pill [formGroup]="form"></rb-error-pill>
  </legend>
  <form [formGroup]="form" class="productCertification">
    <div class="prod-cert-grid">
      <label for="certification">Certification:</label>
      <rb-dropdown inputId="certification"
                   [options]="referenceDataService.getCertificationOptions()"
                   formControlName="certification"
                   styleClass="width-200px">
      </rb-dropdown>
      <label for="coc">Coc Code:</label>
      <rb-input id="coc" formControlName="cocCode" classToApply="width-200px"></rb-input>
      <label for="certification-type">Certification Type and %<i class="pi pi-info-circle"
                                                                 pTooltip="(100%, Mix x%, Mix credit ...)"></i>
      </label>
      <rb-input id="certification-type"
                formControlName="certificationTypeAndPercentage"
                classToApply="width-200px"></rb-input>
      <rb-checkbox label="Certificate" formControlName="certificate"
      ></rb-checkbox>
      <div>
        <rb-document-upload
          formControlName="certificateDocument"
        ></rb-document-upload>
        <rb-calendar style="margin-left: 5px" formControlName="certificateDate"></rb-calendar>
      </div>
      <rb-checkbox label="Invoice" formControlName="invoice"
      ></rb-checkbox>
      <div>
        <rb-document-upload
          formControlName="invoiceDocument"
        ></rb-document-upload>
        <rb-calendar style="margin-left: 5px" formControlName="invoiceDate"></rb-calendar>
      </div>
    </div>
  </form>
</fieldset>
