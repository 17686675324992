<p-panel>
  <p-header>
    Identification
    <rb-error-pill [formGroup]="form.get('identification')"></rb-error-pill>
  </p-header>
  <form [formGroup]="form.get('identification')">
    <div style="display: flex; flex-wrap: wrap;">
      <div style="flex: 1 0 50%; padding-right: 5px; display: flex; flex-direction: column">
        <div class="content-block">
          <div class="pictures">
            <div>
              <rb-gallery formControlName="pictures"></rb-gallery>
            </div>
          </div>
        </div>
        <fieldset>
          <legend>Comments:</legend>
          <div class="fullSizeTextarea">
            <textarea formControlName="comments" pInputTextarea></textarea>
          </div>
        </fieldset>
      </div>
      <div style="flex: 1 0 50%; padding-left: 5px; display: flex; flex-direction: column">
        <rb-sourcing [eutrDocument]="eutrDocument" class="content-block"></rb-sourcing>
        <rb-supplier-identification [eutrDocument]="eutrDocument" class="content-block"></rb-supplier-identification>
        <rb-product-identification [eutrDocument]="eutrDocument" class="content-block"></rb-product-identification>
      </div>
    </div>
  </form>
</p-panel>
