<p-panel>
  <p-header>
    Data
    <rb-error-pill [formGroup]="form.get('data')"></rb-error-pill>
  </p-header>
  <div *ngIf="eutrDocument.identification.productIdentification.swissOnly">
    <span style="color: red">
      Regarding the Swiss ordinance of June, 4th 2010, you have the obligation to declare the wood species and the wood origin.
      For this purpose, kindly fill the table below.</span>
    <br/>
    <br/>
  </div>
  <div class="content-block">
    <div>
      <rb-product-composition *ngIf="form?.get('data')?.get('productComposition')?.value"
                              [form]="form"
      ></rb-product-composition>
      <rb-product-composition-swiss-only *ngIf="form?.get('data')?.get('productCompositionSwissOnly')?.value"
                                         [form]="form"></rb-product-composition-swiss-only>
    </div>
  </div>
  <div class="content-block" *ngIf="form?.get('data')?.get('originWood')?.value">
    <div>
      <rb-origin-wood [form]="form.get('data').get('originWood')"></rb-origin-wood>
    </div>
  </div>
  <div class="content-block" *ngIf="form?.get('data')?.get('productCertification')?.value">
    <div>
      <rb-product-certification
        [form]="form?.get('data')?.get('productCertification')"></rb-product-certification>
    </div>
  </div>
  <div class="content-block"
       *ngIf="form?.get('data')?.get('natureSpeciesOriginWood')?.value">
    <div>
      <rb-nature-species-origin-wood
        [form]="form?.get('data')"></rb-nature-species-origin-wood>
    </div>
  </div>
  <div class="content-block" *ngIf="form?.get('data')?.get('majorSpeciesOfMassiveWood')?.value">
    <rb-major-species-of-massive-wood
      [records]="form?.get('data')?.get('majorSpeciesOfMassiveWood')?.get('woodSpeciesRecords')"></rb-major-species-of-massive-wood>
  </div>
  <rb-wood-composition
    *ngIf="form?.get('data')?.get('woodComposition')?.value"
    [form]="form"></rb-wood-composition>
</p-panel>
