import {Injectable} from '@angular/core';
import {EutrDocument, State} from "../shared/contracts";
import {Role, UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private transitionMap: Map<State, State[]> = new Map();
  private permissionMap: Map<State, Role[]> = new Map();

  constructor(private userService: UserService) {
    this.transitionMap.set(State.NOT_FULFILLED, [State.IN_PROGRESS]);
    this.transitionMap.set(State.IN_PROGRESS, [State.PENDING_QE_VALIDATION]);
    this.transitionMap.set(State.PENDING_QE_VALIDATION, [State.IN_PROGRESS, State.REJECTED, State.FULFILLED]);
    this.transitionMap.set(State.REJECTED, [State.IN_PROGRESS]);
    this.transitionMap.set(State.FULFILLED, [State.IN_PROGRESS]);

    this.permissionMap.set(State.FULFILLED, [Role.QE]);
    this.permissionMap.set(State.REJECTED, [Role.QE]);
    this.permissionMap.set(State.IN_PROGRESS, [Role.QE]);
  }

  public getPossibleTransitions(eutrDocument: EutrDocument): State[] {
    let possibleTransitions = this.transitionMap.get(eutrDocument.state);
    possibleTransitions = possibleTransitions.filter(s => {
      const permissions = this.permissionMap.get(s);
      return !(permissions && !permissions.includes(this.userService.getUser().role));
    });
    return possibleTransitions;
  }
}
