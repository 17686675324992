// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environmentLocal.local.ts` with `environmentLocal.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'UAT',
  apiUrl: 'https://uat-reliancebox.scoptis.com/api/',
  backendLogin: 'https://uat-reliancebox.scoptis.com/api/redirect{?fragment}',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
