import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {EutrDocumentService} from "../services/eutr-document.service";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Section} from "../services/user.service";
import {FormGroup} from "@angular/forms";
import {EutrDocument} from "../shared/contracts";
import {FormService} from "../services/form.service";

@Component({
  selector: 'rb-eutr-page',
  templateUrl: './eutr-page.component.html',
  styleUrls: ['./eutr-page.component.scss'],
})
export class EutrPageComponent implements OnInit, AfterViewInit, OnDestroy {
  private sub: Subscription;
  public Section = Section;
  public form: FormGroup;
  public eutrDocument: EutrDocument;
  public errors: string[];

  constructor(
    public eutrDocumentService: EutrDocumentService,
    public formService: FormService,
    private router: Router
  ) {
    this.eutrDocumentService.getEutrDocumentAsObservable()
      .subscribe(eutrDocument => {

        this.eutrDocument = eutrDocument;
        // TODO_JORIS cleanup
        this.form = this.formService.getEutrForm();
      });
  }

  ngOnInit() {
    if (!this.eutrDocument) {
      console.error('Unexpected error - EutrDocument still null!');
    }
  }

  private removeFragmentFromUrl() {
    let urlTree = this.router.parseUrl(this.router.url);
    if (urlTree.fragment) {
      this.router.navigate([urlTree.toString().replace(/#[^#]+$/, '')]);
    }
  }

  private scrollToFragment() {
    const tree = this.router.parseUrl(this.router.url);
    if (tree.fragment) {
      const element = document.querySelector("#" + tree.fragment);
      if (element) {
        element.scrollIntoView({behavior: "auto", block: "start"});
      }
    }
    this.removeFragmentFromUrl();
  }

  ngAfterViewInit(): void {
    this.sub = this.router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        this.scrollToFragment();
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
