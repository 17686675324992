import {Component, Input} from '@angular/core';
import {EutrDocument} from "../../../../shared/contracts";

@Component({
  selector: 'rb-sourcing',
  templateUrl: './sourcing.component.html',
  styleUrls: ['./sourcing.component.scss']
})
export class SourcingComponent {
  @Input() eutrDocument: EutrDocument;

  constructor() {
  }
}
