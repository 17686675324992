import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  Agreement,
  AssemblySiteNode,
  ComponentNode,
  Conclusion,
  Document,
  EutrDocument,
  ExporterLegalityOfSaleAndExportRecord,
  ExporterPaymentOfFeesAndRoyaltiesRecord,
  ExportersData,
  ForestCertificationRecord,
  ForestLegalityOfHarvestRecord,
  ForestLegalRightToHarvestRecord,
  ForestManagersData,
  ForestPaymentOfTaxesAndRoyaltiesRecord,
  ForestryConcessionNode,
  Identification,
  JoineryWorkshopNode,
  MajorSpeciesOfMassiveWood,
  ManufacturerCertificationRecord,
  ManufacturerLegalityOfProcessingRecord,
  ManufacturerPaymentOfTaxesAndRoyaltiesRecord,
  ManufacturersData,
  NatureSpeciesOriginWood,
  OriginWood,
  ProductCertification,
  ProductComposition,
  ProductCompositionSwissOnly,
  SawmillNode,
  Scoring,
  State,
  StateLabel,
  TradingCompany,
  Volume,
  VolumeUnit,
  WoodComposition,
  WoodCompositionEvaluation,
  WoodCompositionNode,
  WoodSpeciesNode,
  WoodSpeciesRecord
} from "../shared/contracts";
import {FormValidationService} from "./form-validation.service";
import {HttpClient} from "@angular/common/http";
import {ConfirmationService, MessageService} from "primeng/api";
import {ReferenceDataService} from "./reference-data.service";
import {EutrDocumentService} from "./eutr-document.service";
import {PictureService} from "./picture.service";
import {Subject} from "rxjs";
import {ConclusionService} from "./conclusion.service";
import {debounceTime} from "rxjs/operators";
import {HttpRequestsService} from "./http-requests.service";
import {MyValidators} from "./my-validators";
import {ScoringService} from "./scoring.service";

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public form: FormGroup;
  public saving: boolean = false;
  public stateChangeAttempted: boolean;
  public formValidityO: Subject<void>;
  public scoringPropertyChangesO: Subject<void>;

  constructor(private fb: FormBuilder,
              private formValidationService: FormValidationService,
              private http: HttpClient,
              private httpRequestsService: HttpRequestsService,
              private pictureService: PictureService,
              private confirmationService: ConfirmationService,
              private referenceDataService: ReferenceDataService,
              private conclusionService: ConclusionService,
              private scoringService: ScoringService,
              private messageService: MessageService,
              private eutrDocumentService: EutrDocumentService) {
    this.eutrDocumentService.formService = this;
    this.scoringPropertyChangesO = new Subject<void>();
    // Emit events when form validity should be recalculated
    this.formValidityO = new Subject<void>();
    this.init();
  }

  init() {
    this.stateChangeAttempted = false;
  }

  private createEmptyVolume(): Volume {
    return {
      amount: null,
      unit: VolumeUnit.M3
    };
  }

  public initializeEutrForm(eutrDocument: EutrDocument): FormGroup {
    this.form = this.createEutrForm(eutrDocument);
    this.init();

    // This is needed to re-evaluate every formControl's validity. This is need because formControl validity can depend on other formControl values.
    this.form.valueChanges.subscribe(() => {
      this.formValidityO.next();
    });

    return this.form;
  }

  public getEutrForm(): FormGroup {
    return this.form;
  }

  public saveByChangeState(eutrDocument: EutrDocument, selectedState: State): void {
    this.confirmationService.confirm({
      key: "confirmStateChange",
      message: `Are you sure you want to set the EUTR to '${StateLabel.get(selectedState)}'?`,
      accept: () => {
        // recompute all validations, such that errors corresponding to deleted formGroups are no longer present!
        if ((selectedState === State.PENDING_QE_VALIDATION || selectedState === State.FULFILLED) && this.form.status === 'INVALID') {
          this.messageService.add({
            severity: 'error',
            summary: 'Validation errors!',
            detail: `Could not set the EUTR to \'${StateLabel.get(selectedState)}\' due to validation errors.`
          });
          this.form.markAllAsTouched();
          this.stateChangeAttempted = true;
          this.formValidityO.next();
        } else {
          eutrDocument.state = selectedState;
          return this.save(eutrDocument);
        }
      }
    });
  }

  //  - reinitialize / reload state?
  public save(eutrDocument: EutrDocument): Promise<EutrDocument> {
    this.saving = true;
    return this.http.put<EutrDocument>(eutrDocument._links.self.href, this.createEutrObject(this.form, eutrDocument))
      .toPromise()
      .then((response) => {
          this.eutrDocumentService.setDocument(response);
          this.saving = false;
          this.setFormPristine();
          return response;
        },
        (response) => {
          console.error('Error saving EutrDocument!');
          this.saving = false;
          return response;
        });
  }

  public printEutrForm(eutrDocument: EutrDocument): void {
    console.log(JSON.stringify(this.createEutrObject(this.form, eutrDocument)));
  }

  public formIsDirty(): boolean {
    return this.form.dirty;
  }

  public setFormPristine(): void {
    this.form.markAsPristine();
  }

  public createEutrForm(eutrDocument: EutrDocument): FormGroup {
    let form = this.fb.group({
      state: [eutrDocument.state],
      agreement: this.createAgreement(eutrDocument.agreement),
      identification: this.createIdentification(eutrDocument.identification),
      data: this.createData(eutrDocument),
      conclusion: this.createConclusion(eutrDocument.conclusion),
      scoring: eutrDocument.scoring ? eutrDocument.scoring : null
    });
    if (form.get('scoring').value === null) {
      form.get('scoring').setValue({
        countryAnalysis: {},
        supplyChainAnalysis: {},
        globalScore: null,
        globalScoreLabel: null
      });
      form.get('scoring').setValue(this.scoringService.recomputeScoring(form));
    }
    console.log(form, "Created form");
    return form;
  }

  public createNatureSpeciesOriginWood(natureSpeciesOriginWood: NatureSpeciesOriginWood): FormGroup {
    let formGroup = this.fb.group({
      recycledWoodPercentage: [natureSpeciesOriginWood ? natureSpeciesOriginWood.recycledWoodPercentage : null],
      recycledWoodVolume: natureSpeciesOriginWood ? natureSpeciesOriginWood.recycledWoodVolume : this.createEmptyVolume(),
      nonRecycledWoodPercentage: [natureSpeciesOriginWood ? natureSpeciesOriginWood.nonRecycledWoodPercentage : null],
      nonRecycledWoodVolume: natureSpeciesOriginWood ? natureSpeciesOriginWood.nonRecycledWoodVolume : this.createEmptyVolume(),
      massiveWoodWoodSpeciesRecords: this.mapRecords(natureSpeciesOriginWood ? natureSpeciesOriginWood.massiveWoodWoodSpeciesRecords : [null], this.createWoodSpeciesRecordForNatureSpeciesOriginWood.bind(this)),
      particleBoardWoodSpeciesRecords: this.mapRecords(natureSpeciesOriginWood ? natureSpeciesOriginWood.particleBoardWoodSpeciesRecords : [null], this.createWoodSpeciesRecordForNatureSpeciesOriginWood.bind(this)),
      plywoodWoodSpeciesRecords: this.mapRecords(natureSpeciesOriginWood ? natureSpeciesOriginWood.plywoodWoodSpeciesRecords : [null], this.createWoodSpeciesRecordForNatureSpeciesOriginWood.bind(this)),
    });
    formGroup.valueChanges
      .subscribe(() => (formGroup.get('massiveWoodWoodSpeciesRecords') as FormArray).controls
        .concat((formGroup.get('particleBoardWoodSpeciesRecords') as FormArray).controls)
        .concat((formGroup.get('plywoodWoodSpeciesRecords') as FormArray).controls)
        .forEach(wsr => Object.values((wsr as FormGroup).controls).forEach(formGroup => formGroup.updateValueAndValidity({emitEvent: false}))));
    return formGroup;
  }

  public createWoodSpeciesRecordForNatureSpeciesOriginWood(record: WoodSpeciesRecord): FormGroup {
    let group = this.fb.group({
      woodSpecies: [record ? record.woodSpecies : null, Validators.compose([MyValidators.requiredIfParentWoodSpeciesRecordHasInput, MyValidators.atLeastOneRowRequired])],
      volume: [record ? record.volume : this.createEmptyVolume(), Validators.compose([MyValidators.requiredIfParentWoodSpeciesRecordHasInput, MyValidators.atLeastOneRowRequired])],
      countryOfHarvest: [record ? record.countryOfHarvest : null, Validators.compose([MyValidators.requiredIfParentWoodSpeciesRecordHasInput, MyValidators.atLeastOneRowRequired])],
      regionOfHarvest: [record ? record.regionOfHarvest : null, Validators.compose([MyValidators.requiredIfParentWoodSpeciesRecordHasInput, MyValidators.atLeastOneRowRequired])]
    });
    this.markWoodSpeciesRecordAsTouched(group);
    return group;
  }

  public createWoodSpeciesRecord(record: WoodSpeciesRecord): FormGroup {
    let group = this.fb.group({
      woodSpecies: [record ? record.woodSpecies : null],
      volume: [record ? record.volume : this.createEmptyVolume()],
      countryOfHarvest: [record ? record.countryOfHarvest : null],
      regionOfHarvest: [record ? record.regionOfHarvest : null]
    }, {validators: this.formValidationService.woodSpeciesRecordValidator.bind(this)});
    this.markWoodSpeciesRecordAsTouched(group);
    return group;
  }

  private markWoodSpeciesRecordAsTouched(woodSpeciesRecord: FormGroup) {
    woodSpeciesRecord.get('woodSpecies').markAsTouched();
    woodSpeciesRecord.get('volume').markAsTouched();
    woodSpeciesRecord.get('countryOfHarvest').markAsTouched();
    woodSpeciesRecord.get('regionOfHarvest').markAsTouched();
  }

  public createMajorSpeciesOfMassiveWood(majorSpeciesOfMassiveWood: MajorSpeciesOfMassiveWood): FormGroup {
    return this.fb.group({
      woodSpeciesRecords: this.mapRecords(majorSpeciesOfMassiveWood ? majorSpeciesOfMassiveWood.woodSpeciesRecords : [null], this.createMassiveWoodSpeciesRecord.bind(this))
    });
  }

  public createMassiveWoodSpeciesRecord(record: WoodSpeciesRecord): FormGroup {
    return this.fb.group({
      woodSpecies: [record ? record.woodSpecies : null],
      volume: [record ? record.volume : this.createEmptyVolume()],
      countryOfHarvest: [record ? record.countryOfHarvest : null]
    }, {validators: this.formValidationService.massiveWoodSpeciesRecordValidator.bind(this.formValidationService)});
  }

  public createWoodComposition(woodComposition: WoodComposition): FormGroup {
    return this.fb.group({
      componentNodes: this.mapRecords(woodComposition ? woodComposition.componentNodes : [null], this.createComponentNode.bind(this))
    });
  }

  public createComponentNode(componentNode: ComponentNode): FormGroup {
    return this.fb.group({
      componentType: [componentNode ? componentNode.componentType : null, Validators.required],
      massiveWoodNode: this.createWoodCompositionNode(componentNode ? componentNode.massiveWoodNode : null),
      particleBoardNode: this.createWoodCompositionNode(componentNode ? componentNode.particleBoardNode : null),
      plywoodNode: this.createWoodCompositionNode(componentNode ? componentNode.plywoodNode : null),
    });
  }

  public createWoodCompositionNode(woodCompositionNode: WoodCompositionNode): FormGroup {
    let formGroup = this.fb.group({
      percentage: [woodCompositionNode ? woodCompositionNode.percentage : null, Validators.compose([MyValidators.requiredIfParentWoodCompositionHasInput, MyValidators.sumOfPercentagesInParentComponentNodeMustBe100])],
      volume: [woodCompositionNode ? woodCompositionNode.volume : this.createEmptyVolume(), MyValidators.volumeRequiredIfParentWoodCompositionHasInput],
      recycledPercentage: [woodCompositionNode ? woodCompositionNode.recycledPercentage : null, MyValidators.requiredIfParentWoodCompositionHasInput],
      recycledVolume: [woodCompositionNode ? woodCompositionNode.recycledVolume : this.createEmptyVolume(), MyValidators.volumeRequiredIfParentWoodCompositionHasInput],
      nonRecycledPercentage: [woodCompositionNode ? woodCompositionNode.nonRecycledPercentage : null, MyValidators.requiredIfParentWoodCompositionHasInput],
      nonRecycledVolume: [woodCompositionNode ? woodCompositionNode.nonRecycledVolume : this.createEmptyVolume(), MyValidators.volumeRequiredIfParentWoodCompositionHasInput],
      woodSpeciesNodes: woodCompositionNode
        ? this.mapRecords(woodCompositionNode.woodSpeciesNodes, this.createWoodSpeciesNode.bind(this))
        : this.fb.array([])
    });

    formGroup.get('percentage').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromWoodCompositionNode(formGroup);
      this.markRowFormControlsAsTouched(formGroup);
    });
    formGroup.get('volume').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromWoodCompositionNode(formGroup);
      this.markRowFormControlsAsTouched(formGroup);
    });
    formGroup.get('recycledPercentage').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromWoodCompositionNode(formGroup);
      this.markRowFormControlsAsTouched(formGroup);
    });
    formGroup.get('recycledVolume').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromWoodCompositionNode(formGroup);
      this.markRowFormControlsAsTouched(formGroup);
    });
    formGroup.get('nonRecycledPercentage').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromWoodCompositionNode(formGroup);
      this.markRowFormControlsAsTouched(formGroup);
    });
    formGroup.get('nonRecycledVolume').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromWoodCompositionNode(formGroup);
      this.markRowFormControlsAsTouched(formGroup);
    });

    return formGroup;
  }

  private markRowFormControlsAsTouched(woodCompositionNode: FormGroup) {
    woodCompositionNode.get('percentage').markAsTouched();
    woodCompositionNode.get('volume').markAsTouched();
    woodCompositionNode.get('recycledPercentage').markAsTouched();
    woodCompositionNode.get('recycledVolume').markAsTouched();
    woodCompositionNode.get('nonRecycledPercentage').markAsTouched();
    woodCompositionNode.get('nonRecycledVolume').markAsTouched();
  }

  public createWoodSpeciesNode(node: WoodSpeciesNode): FormGroup {
    let formGroup = this.fb.group({
      woodSpeciesRecord: this.createWoodSpeciesRecord(node ? node.woodSpeciesRecord : null),
      assemblySiteNodes: this.mapRecords(node ? node.assemblySiteNodes : [null], this.createAssemblySiteNode.bind(this))
    });

    formGroup.get('woodSpeciesRecord').get('countryOfHarvest').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => {
        this.traverseAssemblySiteNodes(formGroup);
      });

    return formGroup;
  }

  public createAssemblySiteNode(assemblySiteNode: AssemblySiteNode): FormGroup {
    let formGroup = this.fb.group({
      name: [assemblySiteNode ? assemblySiteNode.name : null, Validators.required],
      cocCode: [assemblySiteNode ? assemblySiteNode.cocCode : null],
      country: [assemblySiteNode ? assemblySiteNode.country : null, Validators.required],
      certification: [assemblySiteNode ? assemblySiteNode.certification : null],
      certificationTypeAndPercentage: [assemblySiteNode ? assemblySiteNode.certificationTypeAndPercentage : null],
      manufacturersData: this.createManufacturersData(assemblySiteNode ? assemblySiteNode.manufacturersData : null),
      exportersData: this.createExportersData(assemblySiteNode ? assemblySiteNode.exportersData : null),
      forestManagersData: this.createForestManagersDataWithoutAddingRecordsIfNoneExist(assemblySiteNode ? assemblySiteNode.forestManagersData : null),
      tradingCompanyCheckbox: !!(assemblySiteNode && assemblySiteNode.tradingCompany),
      tradingCompany: (assemblySiteNode && assemblySiteNode.tradingCompany)
        ? this.createTradingCompany(assemblySiteNode.tradingCompany)
        : null,
      joineryWorkshopNodes: this.mapRecords(assemblySiteNode ? assemblySiteNode.joineryWorkshopNodes : [null], this.createJoineryWorkshopNode.bind(this))
    }, {validators: this.formValidationService.assemblySiteNodeValidator.bind(this.formValidationService)});

    formGroup.get('tradingCompanyCheckbox').valueChanges.subscribe(isTradingCompany => {
      formGroup.setControl(
        'tradingCompany',
        isTradingCompany ? this.createTradingCompany(null) : new FormControl()
      );
    });

    formGroup.get('certification').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromAssemblySite(formGroup);
    });
    return formGroup;
  }

  public createManufacturerCertificationRecord(record: ManufacturerCertificationRecord): FormGroup {
    let formGroup = this.fb.group({
      certification: [record ? record.certification : null],
      document: [record ? record.document : null],
      date: [record ? record.date : null]
    }, {validators: this.formValidationService.manufacturerCertificationRecordValidator.bind(this.formValidationService)});

    formGroup.get('certification').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createManufacturerLegalityOfProcessingRecord(record: ManufacturerLegalityOfProcessingRecord): FormGroup {
    let formGroup = this.fb.group({
      legalityOfProcessing: [record ? record.legalityOfProcessing : null],
      document: [record ? record.document : null],
      date: [record ? record.date : null]
    }, {validators: this.formValidationService.manufacturerLegalityOfProcessingRecordValidator.bind(this.formValidationService)});

    formGroup.get('legalityOfProcessing').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createManufacturerPaymentOfTaxesAndRoyaltiesRecord(record: ManufacturerPaymentOfTaxesAndRoyaltiesRecord): FormGroup {
    let formGroup = this.fb.group({
      paymentOfTaxesAndRoyalties: [record ? record.paymentOfTaxesAndRoyalties : null],
      document: [record ? record.document : null],
      date: [record ? record.date : null]
    }, {validators: this.formValidationService.manufacturerPaymentOfTaxesAndRoyaltiesRecordValidator.bind(this.formValidationService)});

    formGroup.get('paymentOfTaxesAndRoyalties').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createExporterLegalityOfSaleAndExportRecord(record: ExporterLegalityOfSaleAndExportRecord): FormGroup {
    let formGroup = this.fb.group({
      legalityOfSaleAndExport: [record ? record.legalityOfSaleAndExport : null, Validators.required],
      document: [record ? record.document : null, Validators.required],
      date: [record ? record.date : null, Validators.compose([Validators.required, MyValidators.dateNotInPast])]
    });

    formGroup.get('legalityOfSaleAndExport').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createExporterPaymentOfFeesAndRoyaltiesRecord(record: ExporterPaymentOfFeesAndRoyaltiesRecord): FormGroup {
    let formGroup = this.fb.group({
      paymentOfFeesAndRoyalties: [record ? record.paymentOfFeesAndRoyalties : null],
      document: [record ? record.document : null],
      date: [record ? record.date : null]
    }, {validators: this.formValidationService.exporterPaymentOfFeesAndRoyaltiesRecordValidator.bind(this.formValidationService)});

    formGroup.get('paymentOfFeesAndRoyalties').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createForestManagersData(data: ForestManagersData): FormGroup {
    return this.fb.group({
      certificationRecords: this.mapRecords(data && data.certificationRecords.length > 0 ? data.certificationRecords : [null], this.createForestCertificationRecord.bind(this)),
      legalRightToHarvestRecords: this.mapRecords(data && data.legalRightToHarvestRecords.length > 0 ? data.legalRightToHarvestRecords : [null], this.createForestLegalRightToHarvestRecord.bind(this)),
      legalityOfHarvestRecords: this.mapRecords(data && data.legalityOfHarvestRecords.length > 0 ? data.legalityOfHarvestRecords : [null], this.createForestLegalityOfHarvestRecord.bind(this)),
      paymentOfTaxesAndRoyaltiesRecords: this.mapRecords(data && data.paymentOfTaxesAndRoyaltiesRecords.length > 0 ? data.paymentOfTaxesAndRoyaltiesRecords : [null], this.createForestPaymentOfTaxesAndRoyaltiesRecord.bind(this))
    });
  }

  public createForestManagersDataWithoutAddingRecordsIfNoneExist(data: ForestManagersData): FormGroup {
    return this.fb.group({
      certificationRecords: this.mapRecords(data?.certificationRecords ? data.certificationRecords : [], this.createForestCertificationRecord.bind(this)),
      legalRightToHarvestRecords: this.mapRecords(data?.legalRightToHarvestRecords ? data.legalRightToHarvestRecords : [], this.createForestLegalRightToHarvestRecord.bind(this)),
      legalityOfHarvestRecords: this.mapRecords(data?.legalityOfHarvestRecords ? data.legalityOfHarvestRecords : [], this.createForestLegalityOfHarvestRecord.bind(this)),
      paymentOfTaxesAndRoyaltiesRecords: this.mapRecords(data?.paymentOfTaxesAndRoyaltiesRecords ? data.paymentOfTaxesAndRoyaltiesRecords : [], this.createForestPaymentOfTaxesAndRoyaltiesRecord.bind(this))
    });
  }

  public createForestCertificationRecord(record: ForestCertificationRecord): FormGroup {
    let formGroup = this.fb.group({
      certification: [record ? record.certification : null],
      document: [record ? record.document : null],
      date: [record ? record.date : null]
    }, {validators: this.formValidationService.forestCertificationRecordValidator.bind(this.formValidationService)});

    formGroup.get('certification').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createForestLegalRightToHarvestRecord(record: ForestLegalRightToHarvestRecord): FormGroup {
    let formGroup = this.fb.group({
      legalRightToHarvest: [record ? record.legalRightToHarvest : null],
      document: [record ? record.document : null],
      date: [record ? record.date : null]
    }, {validators: this.formValidationService.forestLegalRightToHarvestRecordValidator.bind(this.formValidationService)});

    formGroup.get('legalRightToHarvest').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createForestLegalityOfHarvestRecord(data: ForestLegalityOfHarvestRecord): FormGroup {
    let formGroup = this.fb.group({
      legalityOfHarvest: [data ? data.legalityOfHarvest : null, Validators.required],
      document: [data ? data.document : null, Validators.required],
      date: [data ? data.date : null, Validators.compose([Validators.required, MyValidators.dateNotInPast])]
    });

    formGroup.get('legalityOfHarvest').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createForestPaymentOfTaxesAndRoyaltiesRecord(record: ForestPaymentOfTaxesAndRoyaltiesRecord): FormGroup {
    let formGroup = this.fb.group({
      paymentOfTaxesAndRoyalties: [record ? record.paymentOfTaxesAndRoyalties : null],
      document: [record ? record.document : null],
      date: [record ? record.date : null]
    }, {validators: this.formValidationService.forestPaymentOfTaxesAndRoyaltiesRecordValidator.bind(this.formValidationService)});

    formGroup.get('paymentOfTaxesAndRoyalties').valueChanges.subscribe(() => {
      formGroup.get('document').markAsTouched();
      formGroup.get('date').markAsTouched();
    });

    formGroup.get('document').valueChanges
      .pipe(debounceTime((0)))
      .subscribe(() => this.recomputeWoodCompositionEvaluation());

    return formGroup;
  }

  public createJoineryWorkshopNode(joineryWorkshopNode: JoineryWorkshopNode): FormGroup {
    let formGroup = this.fb.group({
      name: [joineryWorkshopNode ? joineryWorkshopNode.name : null, Validators.required],
      cocCode: [joineryWorkshopNode ? joineryWorkshopNode.cocCode : null],
      country: [joineryWorkshopNode ? joineryWorkshopNode.country : null, Validators.required],
      certification: [joineryWorkshopNode ? joineryWorkshopNode.certification : null],
      certificationTypeAndPercentage: [joineryWorkshopNode ? joineryWorkshopNode.certificationTypeAndPercentage : null],
      manufacturersData: this.createManufacturersData(joineryWorkshopNode ? joineryWorkshopNode.manufacturersData : null),
      exportersData: this.createExportersData(joineryWorkshopNode ? joineryWorkshopNode.exportersData : null),
      forestManagersData: this.createForestManagersDataWithoutAddingRecordsIfNoneExist(joineryWorkshopNode ? joineryWorkshopNode.forestManagersData : null),
      sawmillNodes: this.mapRecords(joineryWorkshopNode ? joineryWorkshopNode.sawmillNodes : [null], this.createSawmillNode.bind(this))
    });

    formGroup.get('certification').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromJoineryWorkshop(formGroup);
    });

    return formGroup;
  }

  public createSawmillNode(sawmillNode: SawmillNode): FormGroup {
    let formGroup = this.fb.group({
      name: [sawmillNode ? sawmillNode.name : null, Validators.required],
      cocCode: [sawmillNode ? sawmillNode.cocCode : null],
      country: [sawmillNode ? sawmillNode.country : null, Validators.required],
      certification: [sawmillNode ? sawmillNode.certification : null],
      certificationTypeAndPercentage: [sawmillNode ? sawmillNode.certificationTypeAndPercentage : null],
      manufacturersData: this.createManufacturersData(sawmillNode ? sawmillNode.manufacturersData : null),
      exportersData: this.createExportersData(sawmillNode ? sawmillNode.exportersData : null),
      forestManagersData: this.createForestManagersDataWithoutAddingRecordsIfNoneExist(sawmillNode ? sawmillNode.forestManagersData : null),
      forestryConcessionNodes: this.mapRecords(sawmillNode ? sawmillNode.forestryConcessionNodes : [null], this.createForestryConcessionNode.bind(this))
    });

    formGroup.get('certification').valueChanges.subscribe(() => {
      this.triggerTreeCuttingFromSawmill(formGroup);
    });
    return formGroup;
  }

  public createForestryConcessionNode(forestryConcessionNode: ForestryConcessionNode): FormGroup {
    return this.fb.group({
      name: [forestryConcessionNode ? forestryConcessionNode.name : null, Validators.required],
      cocCode: [forestryConcessionNode ? forestryConcessionNode.cocCode : null],
      country: [forestryConcessionNode ? forestryConcessionNode.country : null, Validators.required],
      region: [forestryConcessionNode ? forestryConcessionNode.region : null, Validators.required],
      certification: [forestryConcessionNode ? forestryConcessionNode.certification : null],
      certificationTypeAndPercentage: [forestryConcessionNode ? forestryConcessionNode.certificationTypeAndPercentage : null],
      exportersData: this.createExportersData(forestryConcessionNode ? forestryConcessionNode.exportersData : null),
      forestManagersData: this.createForestManagersData(forestryConcessionNode ? forestryConcessionNode.forestManagersData : null)
    });
  }

  public createEutrObject(form: FormGroup, originalEutrDocument: EutrDocument): EutrDocument {
    let modifiedIdentification: Identification = originalEutrDocument.identification;
    modifiedIdentification.pictureIds = form.get('identification').get('pictures').value.map(picture => picture.id);
    modifiedIdentification.comments = form.get('identification').get('comments').value;
    return {
      id: originalEutrDocument.id,
      state: originalEutrDocument.state,
      agreement: form.get('agreement').value,
      identification: originalEutrDocument.identification,
      data: form.get('data').value,
      conclusion: form.get('conclusion').value,
      scoring: form.get('scoring').value,
    };
  }

  public triggerNatureSpeciesOriginWood(): void {
    if (!this.form.get('identification').get('productIdentification').get('swissOnly').value) {
      const dateFormGroup: FormGroup = this.form.get('data') as FormGroup;
      const originWood = dateFormGroup.get('originWood');
      const productCertification = dateFormGroup.get('productCertification');
      if (originWood.get('supplierLocatedInEurope').value
        || originWood.get('flegtLicense').value
        || originWood.get('citesLicense').value
        || originWood.get('fromIndonesia').value
        || productCertification.get('certification').value != null
      ) {
        if (!dateFormGroup.get('natureSpeciesOriginWood').value) {
          dateFormGroup.setControl('woodComposition', new FormControl());
          dateFormGroup.setControl('natureSpeciesOriginWood', this.createNatureSpeciesOriginWood(null));
          this.recomputeWoodCompositionEvaluation();
        }
      } else if (!dateFormGroup.get('woodComposition').value) {
        dateFormGroup.setControl('natureSpeciesOriginWood', new FormControl());
        dateFormGroup.setControl('woodComposition', this.createWoodComposition(null));
      }
    }
  }

  private createAgreement(agreement: Agreement): FormGroup {
    let formGroup = this.fb.group({
      readAndAgreed: [agreement.readAndAgreed, Validators.requiredTrue]
    });
    formGroup.get('readAndAgreed').markAsTouched();

    formGroup.valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeScoring());

    return formGroup;
  }

  // TODO_JORIS: add all fields to formgroup!!
  private createIdentification(identification: Identification): FormGroup {
    return this.fb.group({
      productIdentification: this.fb.group({
        swissOnly: identification.productIdentification.swissOnly
      }),
      pictures: [identification.pictureIds.map(id => this.pictureService.getPicture(id))],
      comments: identification.comments
    });
  }

  private createData(document: EutrDocument): FormGroup {
    let formGroup: FormGroup;
    if (document.identification.productIdentification.swissOnly) {
      formGroup = this.fb.group({
        productComposition: null,
        productCompositionSwissOnly: this.createProductCompositionSwissOnly(document.data && document.data.productCompositionSwissOnly),
        originWood: null,
        productCertification: this.createProductCertification(document.data && document.data.productCertification),
        natureSpeciesOriginWood: null,
        majorSpeciesOfMassiveWood: document.data && document.data.productCompositionSwissOnly && document.data.productCompositionSwissOnly.allMassiveWoodIsRecycled
          ? null
          : this.createMajorSpeciesOfMassiveWood(document.data && document.data.majorSpeciesOfMassiveWood),
        woodComposition: null
      });
    } else {
      let scenario1 = document.data && document.data.originWood && (document.data.originWood.supplierLocatedInEurope
        || document.data.originWood.flegtLicense
        || document.data.originWood.citesLicense
        || document.data.originWood.fromIndonesia
        || document.data.productCertification.certification);
      formGroup = this.fb.group({
        productComposition: this.createProductComposition(document.data && document.data.productComposition),
        productCompositionSwissOnly: null,
        originWood: this.createOriginWood(document.data && document.data.originWood),
        productCertification: this.createProductCertification(document.data && document.data.productCertification),
        natureSpeciesOriginWood: scenario1
          ? this.createNatureSpeciesOriginWood(document.data && document.data.natureSpeciesOriginWood)
          : null,
        majorSpeciesOfMassiveWood: null,
        woodComposition: scenario1
          ? null
          : this.createWoodComposition(document.data && document.data.woodComposition)
      });
    }

    formGroup.valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeScoring());

    return formGroup;
  }

  private createProductComposition(productComposition: ProductComposition): FormGroup {
    return this.fb.group({
      wood: [productComposition ? productComposition.wood : null],
      plastic: [productComposition ? productComposition.plastic : null],
      metal: [productComposition ? productComposition.metal : null],
      glass: [productComposition ? productComposition.glass : null],
      otherMaterials: [productComposition ? productComposition.otherMaterials : null],
      netProductWeight: [productComposition ? productComposition.netProductWeight : null],
    });
  }

  private createProductCompositionSwissOnly(productCompositionSwissOnly: ProductCompositionSwissOnly): FormGroup {
    let formGroup = this.fb.group({
      massiveWoodPercentage: [productCompositionSwissOnly ? productCompositionSwissOnly.massiveWoodPercentage : null],
      massiveWoodVolume: [productCompositionSwissOnly ? productCompositionSwissOnly.massiveWoodVolume : null],
      particleBoardPercentage: [productCompositionSwissOnly ? productCompositionSwissOnly.particleBoardPercentage : null],
      particleBoardVolume: [productCompositionSwissOnly ? productCompositionSwissOnly.particleBoardVolume : null],
      otherMaterialsPercentage: [productCompositionSwissOnly ? productCompositionSwissOnly.otherMaterialsPercentage : null],
      otherMaterialsVolume: [productCompositionSwissOnly ? productCompositionSwissOnly.otherMaterialsVolume : null],
      mixedPlywood: [productCompositionSwissOnly ? productCompositionSwissOnly.mixedPlywood : null],
      allMassiveWoodIsRecycled: [productCompositionSwissOnly ? productCompositionSwissOnly.allMassiveWoodIsRecycled : null],
      netProductWeight: [productCompositionSwissOnly ? productCompositionSwissOnly.netProductWeight : null]
    });

    formGroup.get('allMassiveWoodIsRecycled').valueChanges.subscribe(allMassiveWoodIsRecycled => {
      (this.form.get('data') as FormGroup).setControl(
        'majorSpeciesOfMassiveWood',
        allMassiveWoodIsRecycled ? new FormControl() : this.createMajorSpeciesOfMassiveWood(null)
      );
    });

    return formGroup;
  }

  private createOriginWood(originWood: OriginWood): FormGroup {
    let formGroup: FormGroup = this.fb.group({
      supplierLocatedInEurope: [originWood ? originWood.supplierLocatedInEurope : false],
      supplierType: [originWood ? originWood.supplierType : null],
      flegtLicense: [originWood ? originWood.flegtLicense : null],
      flegtDocument: [originWood ? originWood.flegtDocument : null],
      citesLicense: [originWood ? originWood.citesLicense : null],
      citesDocument: [originWood ? originWood.citesDocument : null],
      fromIndonesia: [originWood ? originWood.fromIndonesia : false],
      svlkDocument: [originWood ? originWood.svlkDocument : null]
    }, {validators: this.formValidationService.originWoodValidator.bind(this.formValidationService)});

    formGroup.get('supplierLocatedInEurope').valueChanges.subscribe(() => {
      formGroup.get('supplierType').setValue(null);
      formGroup.get('supplierType').markAsTouched();
      this.triggerNatureSpeciesOriginWood();
    });
    formGroup.get('supplierType').valueChanges.subscribe(val => {
      this.propagateSupplierTypeChangeToConclusion(val);
    });
    formGroup.get('flegtLicense').valueChanges.subscribe(() => {
      formGroup.get('flegtDocument').markAsTouched();
      this.triggerNatureSpeciesOriginWood();
    });
    formGroup.get('flegtDocument').valueChanges.subscribe(val => {
      formGroup.get('flegtLicense').markAsTouched();
      this.propagateFlegtDocumentChangeToConclusion(val);
    });
    formGroup.get('citesLicense').valueChanges.subscribe(() => {
      formGroup.get('citesDocument').markAsTouched();
      this.triggerNatureSpeciesOriginWood();
    });
    formGroup.get('citesDocument').valueChanges.subscribe(val => {
      formGroup.get('citesLicense').markAsTouched();
      this.propagateCitesDocumentChangeToConclusion(val);
    });
    formGroup.get('fromIndonesia').valueChanges.subscribe(() => {
      formGroup.get('svlkDocument').markAsTouched();
      this.triggerNatureSpeciesOriginWood();
    });
    formGroup.get('svlkDocument').valueChanges.subscribe(val => {
      formGroup.get('fromIndonesia').markAsTouched();
      this.propagatesSvlkDocumentChangeToConclusion(val);
    });

    return formGroup;
  }

  private propagateSupplierTypeChangeToConclusion(supplierType: number) {
    let conclusion: FormGroup = this.form.get('conclusion') as FormGroup;
    let originWoodEvaluation: FormGroup = conclusion.get('originWoodEvaluation') as FormGroup;
    if (supplierType) {
      if (!originWoodEvaluation) {
        conclusion.addControl('originWoodEvaluation', this.createEmptyFormGroup(conclusion.disabled));
        originWoodEvaluation = conclusion.get('originWoodEvaluation') as FormGroup;
      }
      originWoodEvaluation.setControl('supplierLocatedInEurope', new FormControl({
        value: null,
        disabled: conclusion.disabled
      }));
    } else {
      if (originWoodEvaluation) {
        originWoodEvaluation.removeControl('supplierLocatedInEurope');
        if (Object.keys(originWoodEvaluation.controls).length === 0) {
          conclusion.removeControl('originWoodEvaluation');
        }
      }
    }
  }

  private propagateFlegtDocumentChangeToConclusion(document: Document) {
    let conclusion: FormGroup = this.form.get('conclusion') as FormGroup;
    let originWoodEvaluation: FormGroup = conclusion.get('originWoodEvaluation') as FormGroup;
    if (document != null) {
      if (!originWoodEvaluation) {
        conclusion.addControl('originWoodEvaluation', this.createEmptyFormGroup(conclusion.disabled));
        originWoodEvaluation = conclusion.get('originWoodEvaluation') as FormGroup;
      }
      originWoodEvaluation.setControl('flegtLicense', new FormControl({
        value: null,
        disabled: conclusion.disabled
      }));
    } else {
      originWoodEvaluation.removeControl('flegtLicense');
      if (Object.keys(originWoodEvaluation.controls).length === 0) {
        conclusion.removeControl('originWoodEvaluation');
      }
    }
  }

  private propagateCitesDocumentChangeToConclusion(document: Document) {
    let conclusion: FormGroup = this.form.get('conclusion') as FormGroup;
    let originWoodEvaluation: FormGroup = conclusion.get('originWoodEvaluation') as FormGroup;
    if (document != null) {
      if (!originWoodEvaluation) {
        conclusion.addControl('originWoodEvaluation', this.createEmptyFormGroup(conclusion.disabled));
        originWoodEvaluation = conclusion.get('originWoodEvaluation') as FormGroup;
      }
      originWoodEvaluation.setControl('citesLicense', new FormControl({
        value: null,
        disabled: conclusion.disabled
      }));
    } else {
      originWoodEvaluation.removeControl('citesLicense');
      if (Object.keys(originWoodEvaluation.controls).length === 0) {
        conclusion.removeControl('originWoodEvaluation');
      }
    }
  }

  private propagatesSvlkDocumentChangeToConclusion(document: Document) {
    let conclusion: FormGroup = this.form.get('conclusion') as FormGroup;
    let originWoodEvaluation: FormGroup = conclusion.get('originWoodEvaluation') as FormGroup;
    if (document != null) {
      if (!originWoodEvaluation) {
        conclusion.addControl('originWoodEvaluation', this.createEmptyFormGroup(conclusion.disabled));
        originWoodEvaluation = conclusion.get('originWoodEvaluation') as FormGroup;
      }
      originWoodEvaluation.setControl('fromIndonesia', new FormControl({
        value: null,
        disabled: conclusion.disabled
      }));
    } else {
      originWoodEvaluation.removeControl('fromIndonesia');
      if (Object.keys(originWoodEvaluation.controls).length === 0) {
        conclusion.removeControl('originWoodEvaluation');
      }
    }
  }

  private createProductCertification(productCertification: ProductCertification): FormGroup {
    let formGroup = this.fb.group({
      certification: [productCertification ? productCertification.certification : null],
      cocCode: [productCertification ? productCertification.cocCode : null],
      certificationTypeAndPercentage: [productCertification ? productCertification.certificationTypeAndPercentage : null],
      certificate: [productCertification ? productCertification.certificate : null],
      certificateDocument: [productCertification ? productCertification.certificateDocument : null],
      certificateDate: [productCertification ? productCertification.certificateDate : null],
      invoice: [productCertification ? productCertification.invoice : null],
      invoiceDocument: [productCertification ? productCertification.invoiceDocument : null],
      invoiceDate: [productCertification ? productCertification.invoiceDate : null]
    }, {validators: this.formValidationService.productCertificationValidator.bind(this.formValidationService)});
    formGroup.valueChanges.subscribe(() => {
      formGroup.markAllAsTouched();
    });

    formGroup.get('certification').valueChanges.subscribe(() => {
      this.triggerNatureSpeciesOriginWood();
    });
    formGroup.get('certificateDocument').valueChanges.subscribe(val => {
      formGroup.get('certificate').markAsTouched();
      this.propagateCertificateDocumentChangeToConclusion(val);
    });
    formGroup.get('invoiceDocument').valueChanges.subscribe(val => {
      formGroup.get('invoice').markAsTouched();
      this.propagateInvoiceDocumentChangeToConclusion(val);
    });

    return formGroup;
  }

  private propagateCertificateDocumentChangeToConclusion(document: Document) {
    let conclusion: FormGroup = this.form.get('conclusion') as FormGroup;
    let productCertificationEvaluation: FormGroup = conclusion.get('productCertificationEvaluation') as FormGroup;
    if (document != null) {
      if (!productCertificationEvaluation) {
        let control = this.createEmptyFormGroup(conclusion.disabled);
        conclusion.addControl('productCertificationEvaluation', control);
        productCertificationEvaluation = conclusion.get('productCertificationEvaluation') as FormGroup;
      }
      productCertificationEvaluation.setControl('certificationValidity', new FormControl({
        value: null,
        disabled: conclusion.disabled
      }));
      productCertificationEvaluation.setControl('certificationScope', new FormControl({
        value: null,
        disabled: conclusion.disabled
      }));
    } else {
      productCertificationEvaluation.removeControl('certificationValidity');
      productCertificationEvaluation.removeControl('certificationScope');
      if (Object.keys(productCertificationEvaluation.controls).length === 0) {
        conclusion.removeControl('productCertificationEvaluation');
      }
    }
  }

  private propagateInvoiceDocumentChangeToConclusion(document: Document) {
    let conclusion: FormGroup = this.form.get('conclusion') as FormGroup;
    let productCertificationEvaluation: FormGroup = conclusion.get('productCertificationEvaluation') as FormGroup;
    if (document != null) {
      if (!productCertificationEvaluation) {
        conclusion.addControl('productCertificationEvaluation', this.createEmptyFormGroup(conclusion.disabled));
        productCertificationEvaluation = conclusion.get('productCertificationEvaluation') as FormGroup;
      }
      productCertificationEvaluation.setControl('invoicesProvided', new FormControl({
        value: null,
        disabled: conclusion.disabled
      }));
    } else {
      productCertificationEvaluation.removeControl('invoicesProvided');
      if (Object.keys(productCertificationEvaluation.controls).length === 0) {
        conclusion.removeControl('productCertificationEvaluation');
      }
    }
  }

  private createEmptyFormGroup(disabled: boolean) {
    let formGroup = this.fb.group({});
    if (disabled) {
      formGroup.disable();
    }
    return formGroup;
  }

  private mapRecords(records, func): FormArray {
    return this.fb.array(records.map(r => func(r)));
  }

  private createManufacturersData(data: ManufacturersData): FormGroup {
    return this.fb.group({
      certificationRecords: this.mapRecords(data ? data.certificationRecords : [null], this.createManufacturerCertificationRecord.bind(this)),
      legalityOfProcessingRecords: this.mapRecords(data ? data.legalityOfProcessingRecords : [null], this.createManufacturerLegalityOfProcessingRecord.bind(this)),
      paymentOfTaxesAndRoyaltiesRecords: this.mapRecords(data ? data.paymentOfTaxesAndRoyaltiesRecords : [null], this.createManufacturerPaymentOfTaxesAndRoyaltiesRecord.bind(this))
    });
  }

  private createExportersData(data: ExportersData): FormGroup {
    return this.fb.group({
      legalityOfSaleAndExportRecords: this.mapRecords(data ? data.legalityOfSaleAndExportRecords : [null], this.createExporterLegalityOfSaleAndExportRecord.bind(this)),
      paymentOfFeesAndRoyaltiesRecords: this.mapRecords(data ? data.paymentOfFeesAndRoyaltiesRecords : [null], this.createExporterPaymentOfFeesAndRoyaltiesRecord.bind(this))
    });
  }

  private createTradingCompany(tradingCompany: TradingCompany): FormGroup {
    return this.fb.group({
      name: [tradingCompany ? tradingCompany.name : null],
      cocCode: [tradingCompany ? tradingCompany.cocCode : null],
      country: [tradingCompany ? tradingCompany.country : null],
      certification: [tradingCompany ? tradingCompany.certification : null],
      certificationTypeAndPercentage: [tradingCompany ? tradingCompany.certificationTypeAndPercentage : null],
      manufacturersData: this.createManufacturersData(tradingCompany ? tradingCompany.manufacturersData : null),
      exportersData: this.createExportersData(tradingCompany ? tradingCompany.exportersData : null)
    });
  }

  private createConclusion(conclusion: Conclusion): FormGroup {
    let conclusionControlsConfig = {comments: conclusion ? conclusion.comments : null} as any;

    if (conclusion) {
      if (conclusion.originWoodEvaluation) {
        conclusionControlsConfig.originWoodEvaluation = this.fb.group(conclusion.originWoodEvaluation);
      }
      if (conclusion.productCertificationEvaluation) {
        conclusionControlsConfig.productCertificationEvaluation = this.fb.group(conclusion.productCertificationEvaluation);
      }
      if (conclusion.woodCompositionEvaluation) {
        conclusionControlsConfig.woodCompositionEvaluation = this.fb.group(
          {woodCompositionDocumentEvaluations: this.fb.array(conclusion.woodCompositionEvaluation.woodCompositionDocumentEvaluations.map(record => this.fb.group(record)))}
        );
      }
    }

    let formGroup = this.fb.group(conclusionControlsConfig);

    formGroup.valueChanges
      .pipe(debounceTime(0))
      .subscribe(() => this.recomputeScoring());

    return formGroup;
  }

  public createScoring(scoring: Scoring): FormGroup {
    return this.fb.group(scoring);
  }

  private getCountryOfHarvestStartingFromAssemblySiteNodeFormGroup(assemblySiteNodeFormGroup: FormGroup) {
    return assemblySiteNodeFormGroup.parent.parent.value.woodSpeciesRecord.countryOfHarvest;
  }

  private getCountryOfHarvestStartingFromJoineryWorkshopNodeFormGroup(joineryWorkshopNodeFormGroup: FormGroup) {
    return this.getCountryOfHarvestStartingFromAssemblySiteNodeFormGroup(joineryWorkshopNodeFormGroup.parent.parent as FormGroup);
  }

  private getCountryOfHarvestStartingFromSawmillNodeFormGroup(sawmillNodeFormGroup: FormGroup) {
    return this.getCountryOfHarvestStartingFromJoineryWorkshopNodeFormGroup(sawmillNodeFormGroup.parent.parent as FormGroup);
  }

  private triggerTreeCuttingFromWoodCompositionNode(woodCompositionNode: FormGroup) {
    const woodSpeciesNodes: FormArray = woodCompositionNode.get('woodSpeciesNodes') as FormArray;
    if (woodCompositionNode.get('percentage').value !== null &&
      woodCompositionNode.get('volume').value !== null &&
      woodCompositionNode.get('volume').value.amount !== null &&
      woodCompositionNode.get('volume').value.unit !== null &&
      woodCompositionNode.get('recycledPercentage').value !== null &&
      woodCompositionNode.get('recycledVolume').value !== null &&
      woodCompositionNode.get('recycledVolume').value.amount !== null &&
      woodCompositionNode.get('recycledVolume').value.unit !== null &&
      woodCompositionNode.get('nonRecycledPercentage').value !== null &&
      woodCompositionNode.get('nonRecycledVolume').value !== null &&
      woodCompositionNode.get('nonRecycledVolume').value.amount !== null &&
      woodCompositionNode.get('nonRecycledVolume').value.unit !== null) {
      if (!woodSpeciesNodes.length) {
        woodSpeciesNodes.push(this.createWoodSpeciesNode(null));
      }
      if (woodCompositionNode.get('recycledPercentage').value === 100) {
        woodSpeciesNodes.controls.forEach(wsn => (wsn.get('assemblySiteNodes') as FormArray).clear());
      } else {
        woodSpeciesNodes.controls
          .filter(wsn => (wsn.get('assemblySiteNodes') as FormArray).length === 0)
          .forEach(wsn => (wsn.get('assemblySiteNodes') as FormArray).push(this.createAssemblySiteNode(null)));
      }
    } else if (
      (woodCompositionNode.get('percentage').value === null || woodCompositionNode.get('percentage').value === "") &&
      (woodCompositionNode.get('volume').value.amount === null || woodCompositionNode.get('volume').value.amount === "") &&
      (woodCompositionNode.get('recycledPercentage').value === null || woodCompositionNode.get('recycledPercentage').value === "") &&
      (woodCompositionNode.get('recycledVolume').value.amount === null || woodCompositionNode.get('recycledVolume').value.amount === "") &&
      (woodCompositionNode.get('nonRecycledPercentage').value === null || woodCompositionNode.get('nonRecycledPercentage').value === "") &&
      (woodCompositionNode.get('nonRecycledVolume').value.amount === null || woodCompositionNode.get('nonRecycledVolume').value.amount === "")
    ) {
      woodSpeciesNodes.clear();
    }
    this.recomputeWoodCompositionEvaluation();
  }

  private traverseAssemblySiteNodes(wsn: FormGroup): void {
    (wsn.get('assemblySiteNodes') as FormArray).controls.forEach(asn => {
      this.triggerTreeCuttingFromAssemblySite(asn as FormGroup);
      (asn.get('joineryWorkshopNodes') as FormArray).controls.forEach(jwn => {
        this.triggerTreeCuttingFromJoineryWorkshop(jwn as FormGroup);
        (jwn.get('sawmillNodes') as FormArray).controls.forEach(smn => {
          this.triggerTreeCuttingFromSawmill(smn as FormGroup);
        });
      });
    });
  }

  public triggerTreeCuttingFromAssemblySite(assemblySiteNode: FormGroup): void {
    const countryOfHarvest = this.getCountryOfHarvestStartingFromAssemblySiteNodeFormGroup(assemblySiteNode);
    if (this.countryIsNotNullAndNotPerceivedAsCorrupt(countryOfHarvest) && assemblySiteNode.get('certification').value) {
      (assemblySiteNode.get('joineryWorkshopNodes') as FormArray).clear();
      (assemblySiteNode.get('forestManagersData').get('legalityOfHarvestRecords') as FormArray).push(this.createForestLegalityOfHarvestRecord(null));
      this.recomputeWoodCompositionEvaluation();
    } else {
      if ((assemblySiteNode.get('joineryWorkshopNodes') as FormArray).controls.length === 0) {
        (assemblySiteNode.get('joineryWorkshopNodes') as FormArray).push(this.createJoineryWorkshopNode(null));
        (assemblySiteNode.get('forestManagersData').get('legalityOfHarvestRecords') as FormArray).clear();
        this.recomputeWoodCompositionEvaluation();
      }
    }
  }

  public triggerTreeCuttingFromJoineryWorkshop(joineryWorkshopNode: FormGroup): void {
    const countryOfHarvest = this.getCountryOfHarvestStartingFromJoineryWorkshopNodeFormGroup(joineryWorkshopNode);
    if (this.countryIsNotNullAndNotPerceivedAsCorrupt(countryOfHarvest) && joineryWorkshopNode.get('certification').value) {
      (joineryWorkshopNode.get('sawmillNodes') as FormArray).clear();
      (joineryWorkshopNode.get('forestManagersData').get('legalityOfHarvestRecords') as FormArray).push(this.createForestLegalityOfHarvestRecord(null));
      this.recomputeWoodCompositionEvaluation();
    } else {
      if ((joineryWorkshopNode.get('sawmillNodes') as FormArray).controls.length === 0) {
        (joineryWorkshopNode.get('sawmillNodes') as FormArray).push(this.createSawmillNode(null));
        (joineryWorkshopNode.get('forestManagersData').get('legalityOfHarvestRecords') as FormArray).clear();
        this.recomputeWoodCompositionEvaluation();
      }
    }
  }

  public triggerTreeCuttingFromSawmill(sawmillNode: FormGroup): void {
    const countryOfHarvest = this.getCountryOfHarvestStartingFromSawmillNodeFormGroup(sawmillNode);
    if (this.countryIsNotNullAndNotPerceivedAsCorrupt(countryOfHarvest) && sawmillNode.get('certification').value) {
      (sawmillNode.get('forestryConcessionNodes') as FormArray).clear();
      (sawmillNode.get('forestManagersData').get('legalityOfHarvestRecords') as FormArray).push(this.createForestLegalityOfHarvestRecord(null));
      this.recomputeWoodCompositionEvaluation();
    } else {
      if ((sawmillNode.get('forestryConcessionNodes') as FormArray).controls.length === 0) {
        (sawmillNode.get('forestryConcessionNodes') as FormArray).push(this.createForestryConcessionNode(null));
        (sawmillNode.get('forestManagersData').get('legalityOfHarvestRecords') as FormArray).clear();
        this.recomputeWoodCompositionEvaluation();
      }
    }
  }

  private countryIsNotNullAndNotPerceivedAsCorrupt(countryOfHarvest) {
    return countryOfHarvest && !this.referenceDataService.getHighCPIRiskCountries().includes(countryOfHarvest);
  }

  public recomputeWoodCompositionEvaluation() {
    let conclusion = this.form.get('conclusion').value;
    const woodCompositionEvaluation: WoodCompositionEvaluation = this.conclusionService.recomputeWoodCompositionEvaluation(
      conclusion,
      this.form.get('data').get('woodComposition').value
    );

    if (woodCompositionEvaluation.woodCompositionDocumentEvaluations.length) {
      conclusion.woodCompositionEvaluation = woodCompositionEvaluation;
    } else {
      delete conclusion.woodCompositionEvaluation;
    }

    this.form.setControl('conclusion', this.createConclusion(conclusion));
  }

  public recomputeScoring() {
    this.form.setControl('scoring', this.createScoring(this.scoringService.recomputeScoring(this.form)));
  }
}
