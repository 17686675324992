<div class="env-bar">
  <span>{{isProd ? '' : envName}}</span>
  <p-button (click)="formService.printEutrForm(eutrDocument)"
            class="black-icon-btn"
            icon="pi pi-print"></p-button>
  <p-button (click)="eutrDocumentService.createSwissOnly()"
            class="black-icon-btn"
            styleClass="temp"
            icon="pi pi-plus">SWISS ONLY
  </p-button>
  <p-button (click)="eutrDocumentService.createNotSwissOnly()"
            class="black-icon-btn"
            styleClass="temp"
            icon="pi pi-plus">NOT SWISS ONLY
  </p-button>
  <span>Welcome to Reliance Box!</span>
</div>
