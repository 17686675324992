import {Component, Input} from '@angular/core';
import {EutrDocument} from "../../../shared/contracts";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent {
  @Input() eutrDocument: EutrDocument;
  @Input() form: FormGroup;

  constructor() {
  }
}
