import {Component, HostListener} from '@angular/core';
import {versions} from "../environments/versions";
import {EutrDocumentService} from "./services/eutr-document.service";
import {environment} from "../environments/environment";
import {FormService} from "./services/form.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public formService: FormService, public eutrDocumentService: EutrDocumentService) {
    localStorage.setItem('version', versions.version);
    localStorage.setItem('revision', versions.revision);
    localStorage.setItem('branch', versions.branch);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (environment.name !== 'LOCAL' && this.formService.form && this.formService.form.dirty) {
      // Prevent browser/tab close without confirmation
      event.returnValue = true;
      return "You might have unsaved data. Are you sure you want to leave RelianceBox?";
    }
  }
}
