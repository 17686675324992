<div>
  <div class="actions">
    <p-button class="black-icon-btn"
              icon="pi pi-plus"
              [disabled]="disabled"
              (onClick)="clickOnFileUpload()"></p-button>
    <p-button class="black-icon-btn"
              icon="pi pi-trash"
              [disabled]="disabled"
              (onClick)="showConfirmDeleteModal()"></p-button>
    <p-fileUpload
      #fileUpload
      [files]="files"
      [disabled]="disabled"
      [auto]="true"
      name="multipartFile"
      (onUpload)="finishedUpload($event)"
      (onBeforeUpload)="onBeforeUpload()"
      [url]="pictureService.uploadUrl"
      accept="image/*"
      maxFileSize="10000000"
      (onError)="onError($event)"
      invalidFileTypeMessageSummary=""
      invalidFileTypeMessageDetail="Invalid file type!"
      invalidFileSizeMessageSummary=""
      invalidFileSizeMessageDetail="Max size is {0}."
    ></p-fileUpload>
  </div>
  <div class="galleria">
    <ng-container *ngIf="galleriaVisible">
      <p-galleria [images]="picturesForGallery"
                  [showCaption]="false"
                  [autoPlay]="false"
                  [activeIndex]="activeIndex"
                  (onImageChange)="activePictureChanged($event)"></p-galleria>
    </ng-container>
  </div>
</div>

<p-confirmDialog header="Remove Image" icon="pi pi-exclamation-triangle"></p-confirmDialog>
