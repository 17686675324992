import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {EutrDocumentService} from "./services/eutr-document.service";

@Injectable()
export class CanActivateGuard implements CanActivate {
  constructor(private eutrDocumentService: EutrDocumentService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return route.routeConfig.path === "EUTR/:id"
      ? this.eutrDocumentService.loadEutrDocument(+route.params.id)
        .then(result => true)
      : Promise.resolve(true);
  }
}
