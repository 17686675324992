import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../../../../services/reference-data.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-origin-wood',
  templateUrl: './origin-wood.component.html',
  styleUrls: ['./origin-wood.component.scss', './../../data.component.scss']
})
export class OriginWoodComponent {
  @Input() form: FormGroup;

  constructor(public referenceDataService: ReferenceDataService) {
  }
}
