import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {EutrDocument} from "../../shared/contracts";
import {EutrDocumentService} from "../../services/eutr-document.service";
import {UserService} from "../../services/user.service";
import {FormService} from "../../services/form.service";

@Component({
  selector: 'rb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() eutrDocument: EutrDocument;
  envName: string;
  isProd: boolean;


  constructor(public eutrDocumentService: EutrDocumentService, public formService: FormService, public userService: UserService) {
  }


  ngOnInit() {
    this.envName = environment.name;
    this.isProd = environment.production;
  }
}
