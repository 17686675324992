export class DateUtil {
  public static createDateObjectWithSameDateButInUtc(val: Date): Date {
    if (!val) {
      return val;
    } else {
      // getFullYear() returns the current year, whereas getYear() returns the current year minus 1900...
      // getDate() returns the dayOfMonth, whereas getDay() returns the dayOfWeek...
      return new Date(Date.UTC(val.getFullYear(), val.getMonth(), val.getDate()));
    }
  }

  public static getTodayAsUtcDate(): Date {
    return this.createDateObjectWithSameDateButInUtc(new Date());
  }
}
