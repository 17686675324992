<p-panel header="Conclusion">
  <p-accordion [multiple]="true">
    <rb-origin-wood-evaluation
      *ngIf="form?.get('conclusion')?.get('originWoodEvaluation')"
      [form]="form"></rb-origin-wood-evaluation>
  </p-accordion>
  <p-accordion [multiple]="true">
    <rb-product-certification-evaluation
      *ngIf="form?.get('conclusion')?.get('productCertificationEvaluation')"
      [form]="form"></rb-product-certification-evaluation>
  </p-accordion>
  <p-accordion [multiple]="true">
    <rb-wood-composition-evaluation
      *ngIf="form?.get('conclusion')?.get('woodCompositionEvaluation')"
      [form]="form"></rb-wood-composition-evaluation>
  </p-accordion>
  <form [formGroup]="form?.get('conclusion')">
    <fieldset>
      <legend>Comments:</legend>
      <div class="fullSizeTextarea">
        <textarea maxlength="1000" formControlName="comments" pInputTextarea></textarea>
      </div>
    </fieldset>
  </form>
</p-panel>
