import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MessageService} from "primeng/api";
import {HttpRequestsService} from "../services/http-requests.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private httpRequestsService: HttpRequestsService, private messageService: MessageService) {
  }

  removeRequest(req: HttpRequest<any>) {
    this.httpRequestsService.requests = this.httpRequestsService.requests.filter(obj => obj.req !== req);
    this.httpRequestsService.isLoading.next(this.httpRequestsService.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body && req.body instanceof FormData) {
      this.messageService.add({
        severity: 'info',
        summary: 'File upload started!',
        detail: '' + (req.body.get('multipartFile') as File).name + ' is uploading!'
      });
      this.httpRequestsService.requests.push({
        req: req,
        progress: 0,
        filename: (req.body.get('multipartFile') as File).name
      });
      this.httpRequestsService.isLoading.next(true);
    }
    return new Observable(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event.type == HttpEventType.UploadProgress) {
              this.httpRequestsService.requests.find(obj => obj.req === req).progress = Math.floor(event.loaded / (event.total || event.loaded) * 100);
            }
            if (event instanceof HttpResponse) {
              if (req.body && req.body instanceof FormData) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'File upload finished!',
                  detail: '' + (req.body.get('multipartFile') as File).name + ' finished uploading!'
                });
              }
              this.removeRequest(req);
              if (req.body && req.body instanceof FormData) {
                this.httpRequestsService.successfulUploads.push({
                  req: req,
                  filename: (req.body.get('multipartFile') as File).name
                });
              }
              observer.next(event);
            }
          },
          err => {
            if (req.body && req.body instanceof FormData) {
              this.messageService.add({
                severity: 'error',
                summary: 'File upload failed!',
                detail: '' + (req.body.get('multipartFile') as File).name + ' failed to upload!'
              });
            }
            this.removeRequest(req);
            if (req.body && req.body instanceof FormData) {
              this.httpRequestsService.failedUploads.push({
                req: req,
                filename: (req.body.get('multipartFile') as File).name
              });
            }
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
