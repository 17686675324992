import {
  ComponentNode,
  EutrDocument,
  NatureSpeciesOriginWood,
  WoodCompositionNode,
  WoodSpeciesRecord
} from "../../shared/contracts";

export class ScoringUtil {
  static getWoodCompositionNodes(componentNode: ComponentNode): WoodCompositionNode[] {
    return [
      componentNode.particleBoardNode,
      componentNode.plywoodNode,
      componentNode.massiveWoodNode
    ];
  }

  static getWoodSpeciesRecordsForNatureSpeciesOriginWood(natureSpeciesOriginWood: NatureSpeciesOriginWood): WoodSpeciesRecord[] {
    return natureSpeciesOriginWood.massiveWoodWoodSpeciesRecords
      .concat(natureSpeciesOriginWood.particleBoardWoodSpeciesRecords)
      .concat(natureSpeciesOriginWood.plywoodWoodSpeciesRecords);
  }

  static getAllWoodSpeciesRecordsInDocument(eutrDocument: EutrDocument): WoodSpeciesRecord[] {
    if (eutrDocument.data.natureSpeciesOriginWood) {
      return ScoringUtil.getWoodSpeciesRecordsForNatureSpeciesOriginWood(eutrDocument.data.natureSpeciesOriginWood);
    } else if (eutrDocument.data.majorSpeciesOfMassiveWood) {
      return eutrDocument.data.majorSpeciesOfMassiveWood.woodSpeciesRecords;
    } else if (eutrDocument.data.woodComposition) {
      return eutrDocument.data.woodComposition.componentNodes
        .flatMap(cn => ScoringUtil.getWoodCompositionNodes(cn))
        .flatMap(wcn => wcn.woodSpeciesNodes)
        .map(wsn => wsn.woodSpeciesRecord);
    } else if (eutrDocument.identification.productIdentification.swissOnly && eutrDocument.data.productCompositionSwissOnly.allMassiveWoodIsRecycled) {
      return [];
    } else {
      throw new Error();
    }
  }
}
