<fieldset>
  <p-panel header="Steinhoff Ethical Timber Sourcing Policy">
    <div class="content-block">
      <p>Steinhoff commitment is to prohibit the purchase, sale or importation of products made from timber or other
        timber-related materials and fibers that were illegally sourced from their country of origin.
        <br/>
        Our company has also a proactive approach in timber sourcing, linked to our environmental commitments.</p>
      <br/>
      <p>This policy concerns the products manufactured from timber, timber veneer, plywood, MDF, recycled and reclaimed
        wood, for all the suppliers of Steinhoff group.</p>
      <br/>
      <p>According to the EUTR (European Union Timber Regulation n° CE/995/2010, FLEGT regulation, and the Illegal
        Logging Prohibition Bill (Australian regulation), our suppliers are required:</p>
      <ol style="margin: 0;">
        <li>To meet the EU Timber Regulation</li>
        <li> To promote sustainable wood in Steinhoff products
        </li>
      </ol>
      <br/>
      <p>EUTR requests to identify the source of all timber materials that are used in the manufacturing of all timber
        related products, and to take all reasonable measures to ensure that the timber materials used meets the
        following requirements:</p>
      <ul style="margin: 0;">
        <li>Come from a known source;</li>
        <li>Sourced legally;</li>
        <li>Sourced from species not listed as threatened;</li>
        <li>Not sourced from countries in conflict (as identified/defined by the EU);</li>
        <li>Not sourced from protected forests or areas (high conservation areas) as has been identified by government;
        </li>
        <li>Documentation proving legality and source;</li>
      </ul>
      <br/>
      <p>Steinhoff supplier is required to know the geographic source of the timber, and confirm that the harvesting
        entity had a legal right to harvest.</p>
      <h2>1. Respect of EUTR and Australian regulation</h2>
      <p>EUTR has entered into force in March 2013 and the Australian Illegal Logged timber regulation will be
        applicable from November 2014.
        <br/>
        From April 2012, all suppliers are required to provide data and documentation (certificates, invoices) regarding
        the timber materials used in their products, at each step of the wood transformation and product manufacturing.
        <br/>
        If a supplier can not provide documentation, and the source of the timber material remains unknown, Steinhoff is
        committed to replace the timber material, replacing the supplier or shall cease purchasing the product.</p>

      <h2>2. Sustainability</h2>
      <p>Our long term aim is to have all timber materials used in the production of furniture products credibly and
        ethically sourced from verified well-managed forests under a Chain of Custody program (FSC or PEFC
        certificates).
        We also encourage the certification of all timber as much as possible.
        <br/>
        Our aim is to build long-term relationships with suppliers that share a common commitment to promote good
        practice in the management and supply of timber, and to minimize the environmental impact on their operations.
      </p>

      <h2>3. Specific requirements</h2>
      <p>
        <strong>Conforama suppliers certified by an external body against their EUTR due diligence system:</strong>
        <br/>
        Suppliers who have chosen to be certified by a well-known external body commit to inform Conforama they are
        certified.
        <br/>
        If suppliers certified by an external body don’t renew their certification, they commit to inform Conforama as
        well. In cases in which the non-renewal is proved, suppliers will be audited in the framework of Conforama EUTR
        process.
        <br/>
        <strong>SISL suppliers sourcing:</strong>
        <br/>
        Suppliers commit to inform SISL in case of a modification in their timber supply chain.
      </p>
      <br/>
      <p>
        <img src="assets/signature.png" alt="Signature">
        <br/>
        C. De Meerschman
        <br/>
        Quality Director
      </p>
    </div>
  </p-panel>
  <br/>
  <p-panel>
    <p-header>
      Supplier's Agreement with Policy
      <rb-error-pill [formGroup]="form?.get('agreement')"></rb-error-pill>
    </p-header>
    <form [formGroup]="form">
      <div class="content-block agreement" formGroupName="agreement">
        <div style="display: flex; flex-direction: row; justify-content: flex-start">
          <div style="display: flex; align-items: center">
            <p-checkbox binary="true" name="readAndAgreed" formControlName="readAndAgreed"></p-checkbox>
          </div>
          <div style="display: flex; justify-content: center">
            <ul>
              <li>I read and commit to comply with Steinhoff ethical timber sourcing policies.</li>
              <li>
                I signed Steinhoff EUTR attestation, declaring this way I am compliant to my country’s local laws,
                compliant
                to the EUTR (if concerned) and that I will provide all information and documentation on our company’s
                wood
                sourcing and traceability to Steinhoff brands on demand.
              </li>
              <li>I agree that all information declared will be verified during annual 3rd party audits and
                documentation
                checking.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </p-panel>
</fieldset>
