import {Component, Input} from '@angular/core';
import {FormArray} from "@angular/forms";
import {FormService} from "../../../../../../../services/form.service";
import {ReferenceDataService} from "../../../../../../../services/reference-data.service";
import {UidService} from "../../../../../../../services/uid.service";
import {ChainService} from "../../../../../../../services/chain.service";
import {ForestryConcessionNode} from "../../../../../../../shared/contracts";

@Component({
  selector: 'rb-forestry-concession-nodes',
  templateUrl: './forestry-concession-nodes.component.html',
  styleUrls: ['./forestry-concession-nodes.component.scss']
})
export class ForestryConcessionNodesComponent {
  @Input() records: FormArray;
  // TODO_JORIS fix naming ...
  @Input() hdr: string;
  uid: number;

  constructor(
    public formService: FormService,
    public referenceDataService: ReferenceDataService,
    private uidService: UidService,
    public chainService: ChainService,
  ) {
    this.uid = uidService.generateUid();
  }

  // TODO_JORIS cleanup
  computeTitle(base: string, index: number, data: string) {
    return base + " " + (index + 1) + (data ? (" - " + data) : "");
  }

  computeForestryConcessionHeader(): string {
    return this.hdr + "ForestryConcession";
  }

  onConfirmedNode(node: ForestryConcessionNode, index: number) {
    this.records.setControl(index, this.formService.createForestryConcessionNode(node));
    this.formService.recomputeWoodCompositionEvaluation();
  }
}
