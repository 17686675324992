<div [@openClose]="advancedSearching ? 'open' : 'closed'" class="advanced-tab">
  <!--  <div class="ui-g">
      <div class="ui-g-12"><p-radioButton name="group1" value="Supplier Item Reference: " label="Supplier Item Reference" [(ngModel)]="advancedFilter" inputId="opt1"></p-radioButton></div>
      <div class="ui-g-12"><p-radioButton name="group1" value="Customer: " label="Customer" [(ngModel)]="advancedFilter" inputId="opt2"></p-radioButton></div>
      <div class="ui-g-12"><p-radioButton name="group1" value="Customer Item Number: " label="Customer Item Number" [(ngModel)]="advancedFilter" inputId="opt3"></p-radioButton></div>
      <div class="ui-g-12"><p-radioButton name="group1" value="Customer and Customer Item Number: " label="Customer and Customer Item Number" [(ngModel)]="advancedFilter" inputId="opt4"></p-radioButton></div>
    </div>-->
  <div></div>
  <div class="ui-inputgroup advanced-filter">
    <span class="ui-inputgroup-addon"><p-checkbox  (ngModelChange)="enable('SIR')"[(ngModel)]="enableSIR"></p-checkbox></span>
    <input [(ngModel)]=supplierItemReference type="text" pInputText placeholder="Supplier item reference">
  </div>
  <div class="ui-inputgroup advanced-filter">
    <span class="ui-inputgroup-addon"><p-checkbox  (ngModelChange)="enable('Customer')"[(ngModel)]="enableCustomer"></p-checkbox></span>
    <input [(ngModel)]=customer type="text" pInputText placeholder="Customer">
  </div>
  <div class="ui-inputgroup advanced-filter">
    <span class="ui-inputgroup-addon"><p-checkbox (ngModelChange)="enable('CIN')" [(ngModel)]="enableCIN"></p-checkbox></span>
    <input [(ngModel)]=customerItemNumber type="text" pInputText placeholder="Customer Item Number">
  </div>

  <div class="advanced-filter">
    <p-button (onClick)="applyFilter()" label="Apply"></p-button>
  </div>
</div>

