import {Component, Input} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {ReferenceDataService} from "../../../../services/reference-data.service";
import {
  Document,
  WoodCompositionCertificationScopeVerdict,
  WoodCompositionCertificationValidityVerdict,
  WoodCompositionConsistencyVerdict,
  WoodCompositionCorrectnessVerdict,
  WoodCompositionDocumentEvaluation,
  WoodCompositionInvoicesVerdict
} from "../../../../shared/contracts";
import {DocumentService} from "../../../../services/document.service";

@Component({
  selector: 'rb-wood-composition-evaluation',
  templateUrl: './wood-composition-evaluation.component.html',
  styleUrls: ['./wood-composition-evaluation.component.scss']
})
export class WoodCompositionEvaluationComponent {
  @Input() form: FormGroup;

  constructor(public referenceDataService: ReferenceDataService, private documentService: DocumentService) {
  }

  isDocumentCompliant(index: number) {
    let woodCompositionDocumentEvaluation: WoodCompositionDocumentEvaluation = this.getFormGroupForDocumentByIndex(index).value;

    return (!woodCompositionDocumentEvaluation.hasOwnProperty('certificationValidity') || woodCompositionDocumentEvaluation.certificationValidity === WoodCompositionCertificationValidityVerdict.COMPLIANT)
      && (!woodCompositionDocumentEvaluation.hasOwnProperty('certificationScope') || woodCompositionDocumentEvaluation.certificationScope === WoodCompositionCertificationScopeVerdict.COMPLIANT)
      && (!woodCompositionDocumentEvaluation.hasOwnProperty('invoices') || woodCompositionDocumentEvaluation.invoices === WoodCompositionInvoicesVerdict.COMPLIANT)
      && (!woodCompositionDocumentEvaluation.hasOwnProperty('consistency') || woodCompositionDocumentEvaluation.consistency === WoodCompositionConsistencyVerdict.COMPLIANT)
      && (!woodCompositionDocumentEvaluation.hasOwnProperty('correctness') || woodCompositionDocumentEvaluation.correctness === WoodCompositionCorrectnessVerdict.COMPLIANT);
  }

  makeDocumentCompliant(index: number) {
    if (!this.isDocumentCompliant(index)) {
      let woodCompositionDocumentEvaluation: any = this.getFormGroupForDocumentByIndex(index).controls;
      if (woodCompositionDocumentEvaluation.hasOwnProperty('certificationValidity')) {
        woodCompositionDocumentEvaluation.certificationValidity.setValue(WoodCompositionCertificationValidityVerdict.COMPLIANT);
      }
      if (woodCompositionDocumentEvaluation.hasOwnProperty('certificationScope')) {
        woodCompositionDocumentEvaluation.certificationScope.setValue(WoodCompositionCertificationScopeVerdict.COMPLIANT);
      }
      if (woodCompositionDocumentEvaluation.hasOwnProperty('invoices')) {
        woodCompositionDocumentEvaluation.invoices.setValue(WoodCompositionInvoicesVerdict.COMPLIANT);
      }
      if (woodCompositionDocumentEvaluation.hasOwnProperty('consistency')) {
        woodCompositionDocumentEvaluation.consistency.setValue(WoodCompositionConsistencyVerdict.COMPLIANT);
      }
      if (woodCompositionDocumentEvaluation.hasOwnProperty('correctness')) {
        woodCompositionDocumentEvaluation.correctness.setValue(WoodCompositionCorrectnessVerdict.COMPLIANT);
      }
      this.form.markAsDirty();
    }
  }

  getDocumentByIndex(index: number): Document {
    return this.documentService.getDocument((this.getWoodCompositionDocumentEvaluationsFormArray().controls[index] as FormGroup).controls.document.value);
  }

  getFormGroupForDocumentByIndex(index: number): FormGroup {
    return (this.getWoodCompositionDocumentEvaluationsFormArray().controls[index] as FormGroup) as FormGroup;
  }

  getWoodCompositionDocumentEvaluationsFormArray(): FormArray {
    return this.getWoodCompositionEvaluationFormGroup().get('woodCompositionDocumentEvaluations') as FormArray;
  }

  getWoodCompositionEvaluationFormGroup() {
    return this.form.get('conclusion').get('woodCompositionEvaluation');
  }
}
