<fieldset>
  <legend>
    <span>The product is composed of:</span>
    <span style="font-size: x-small"> (% in weight)</span>
    <rb-error-pill [formGroup]="form.get('data')?.get('productComposition')"></rb-error-pill>
  </legend>
  <form [formGroup]="form">
    <div formGroupName="data">
      <div class="prod-comp-grid productComposition" formGroupName="productComposition">
        <label for="wood">Wood:</label>
        <rb-percentage inputId="wood" formControlName="wood"></rb-percentage>
        <label for="plastic">Plastic:</label>
        <rb-percentage inputId="plastic" formControlName="plastic"></rb-percentage>
        <label for="metal">Metal:</label>
        <rb-percentage inputId="metal" formControlName="metal"></rb-percentage>
        <label for="glass">Glass:</label>
        <rb-percentage inputId="glass" formControlName="glass"></rb-percentage>
        <label for="other-materials">Other materials:</label>
        <rb-percentage inputId="other-materials" formControlName="otherMaterials"></rb-percentage>
        <label class="bold">Total of materials: </label>
        <div class="total-percentage">
          <rb-percentage [disabled]="true" [ngModel]="getTotal()" [ngModelOptions]="{standalone: true}"></rb-percentage>
        </div>
        <label for="net-product-weight" class="bold">Net product weight:</label>
        <div>
          <rb-input type="number" id="net-product-weight"
                    formControlName="netProductWeight"></rb-input>
          <span style="margin-left: 2px;">kg</span>
        </div>
      </div>
    </div>
  </form>
</fieldset>
