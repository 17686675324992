//TODO_JORIS ; vs ,

export interface Resource {
  _links?: {
    self?: {
      href: string;
    }
  }
}

export interface RootResource {
  _links: {
    forestPaymentOfTaxesAndRoyaltiesOptions: {
      href: string;
    },
    certifications: {
      href: string;
    },
    documents: {
      href: string;
    },
    forestCertificationOptions: {
      href: string;
    },
    countries: {
      href: string;
    },
    supplierTypes: {
      href: string;
    },
    nodes: [
      {
        href: string;
      }
    ],
    verdictOptions: {
      href: string;
    },
    pictures: {
      href: string;
    },
    manufacturerPaymentOfTaxesAndRoyaltiesOptions: {
      href: string;
    },
    woodSpecies: {
      href: string;
    },
    chainCertificationTypes: {
      href: string;
    },
    eutrDocuments: {
      href: string;
    },
    componentTypeCategories: {
      href: string;
    },
    exporterPaymentOfFeesAndRoyaltiesOptions: {
      href: string;
    },
    forestLegalityOfHarvestOptions: {
      href: string;
    },
    manufacturerLegalityOfProcessingOptions: {
      href: string;
    },
    manufacturerCertificationOptions: {
      href: string;
    },
    exporterLegalityOfSaleAndExportOptions: {
      href: string;
    },
    chainCertificationTypeCategories: {
      href: string;
    },
    componentTypes: {
      href: string;
    },
    forestLegalRightToHarvestOptions: {
      href: string;
    },
    documentUpload: {
      href: string;
    },
    profile: {
      href: string;
    }
  }
}

export interface Document {
  id: number;
  fileName: string;
  _links: {
    download: {
      href: string
    }
  }
}

export interface Picture {
  id: number,
  fileName: string,
  _links: {
    download: {
      href: string
    }
  }
}

export interface Volume {
  amount: number;
  unit: VolumeUnit;
}

// TODO_JORIS cleanup
export enum VolumeUnit {
  MM3 = 1,
  CM3,
  M3,
}

export enum OriginWoodLicenseValidityVerdict {
  COMPLIANT = 11,
}

export enum ProductCertificationCertificationValidityVerdict {
  COMPLIANT = 14,
}

export enum ProductCertificationCertificationScopeVerdict {
  COMPLIANT = 16,
}

export enum ProductCertificationInvoicesProvidedVerdict {
  COMPLIANT = 18,
}

export enum WoodCompositionCertificationValidityVerdict {
  COMPLIANT = 1,
}

export enum WoodCompositionCertificationScopeVerdict {
  COMPLIANT = 3,
}

export enum WoodCompositionInvoicesVerdict {
  COMPLIANT = 5,
}

export enum WoodCompositionConsistencyVerdict {
  COMPLIANT = 7,
}

export enum WoodCompositionCorrectnessVerdict {
  COMPLIANT = 9,
}

// TODO_JORIS cleanup
export enum LegalInformation {
  UNDER_LEGALITY = 1,
  FORBIDDEN,
  ALERT,
}

export interface NatureSpeciesOriginWood {
  recycledWoodPercentage: number;
  recycledWoodVolume: Volume;
  nonRecycledWoodPercentage: number;
  nonRecycledWoodVolume: Volume;
  massiveWoodWoodSpeciesRecords: WoodSpeciesRecord[];
  particleBoardWoodSpeciesRecords: WoodSpeciesRecord[];
  plywoodWoodSpeciesRecords: WoodSpeciesRecord[];
}

export interface OriginWood {
  supplierLocatedInEurope: boolean;
  supplierType: number;
  flegtLicense: boolean;
  flegtDocument: number;
  citesLicense: boolean;
  citesDocument: number;
  fromIndonesia: boolean;
  svlkDocument: string;
}

export interface ProductCertification {
  certification: number;
  cocCode: string;
  certificationTypeAndPercentage: string;
  certificate: boolean;
  certificateDocument: number;
  certificateDate: Date;
  invoice: boolean;
  invoiceDocument: number;
  invoiceDate: Date;
}

export interface Sourcing {
  date: Date;
  sourcingOffice: string;
  asiaQualityGroup: string;
  qualityEngineer: string;
  qualityEngineerEmail: string;
}

export interface SupplierIdentification {
  supplierName: string;
  supplierSourcingCode: string;
  sisProCode: string;
  supplierMail: string;
  code: string;
}

export interface ProductIdentification {
  swissOnly: boolean;
  supplierModelName: string;
  productFamily: string;
  productName: string;
  items: ProductIdentificationItem[];
}

export interface ProductIdentificationItem {
  brand: string;
  code: string;
  name: string;
  colour: string;
}

export interface ProductComposition {
  wood: number;
  plastic: number;
  metal: number;
  glass: number;
  otherMaterials: number;
  netProductWeight: number;
}

export interface ProductCompositionSwissOnly {
  massiveWoodPercentage: number;
  massiveWoodVolume: Volume;
  particleBoardPercentage: number;
  particleBoardVolume: Volume;
  otherMaterialsPercentage: number;
  otherMaterialsVolume: Volume;
  netProductWeight: number;
  mixedPlywood: boolean;
  allMassiveWoodIsRecycled: boolean;
}

export interface WoodSpeciesRecord {
  woodSpecies: number;
  volume: Volume;
  countryOfHarvest: number;
  regionOfHarvest: string;
}

export interface WoodSpecies {
  id: number;
  scientificName: string;
  commonName: string;
  legalInformation: LegalInformation;
}

export interface Data {
  productComposition?: ProductComposition;
  productCompositionSwissOnly?: ProductCompositionSwissOnly;
  originWood?: OriginWood;
  productCertification?: ProductCertification;
  natureSpeciesOriginWood?: NatureSpeciesOriginWood;
  majorSpeciesOfMassiveWood?: MajorSpeciesOfMassiveWood;
  woodComposition?: WoodComposition;
}

export interface Agreement {
  readAndAgreed: boolean;
}

export interface Identification {
  pictureIds: number[];
  comments: string;
  sourcing: Sourcing;
  supplierIdentification: SupplierIdentification;
  productIdentification: ProductIdentification
}

export interface EutrDocument extends Resource {
  id: number;
  createdBy?: string;
  creationTime?: Date;
  modifiedBy?: string;
  modificationTime?: Date;
  state: State;
  agreement: Agreement;
  identification: Identification;
  data?: Data;
  conclusion?: Conclusion;
  scoring?: Scoring;
  _links?: {
    self: {
      href: string;
    },
    download?: {
      href: string;
    },
    findAllPicturesForEutrDocument?: {
      href: string;
    },
    findAllDocumentsForEutrDocument?: {
      href: string;
    },
  }
}

export interface MajorSpeciesOfMassiveWood {
  woodSpeciesRecords: WoodSpeciesRecord[];
}

export interface WoodComposition {
  componentNodes: ComponentNode[]
}

export interface ComponentNode {
  componentType: number;
  massiveWoodNode: WoodCompositionNode;
  particleBoardNode: WoodCompositionNode;
  plywoodNode: WoodCompositionNode;
}

export interface WoodCompositionNode {
  percentage: number;
  volume: Volume;
  recycledPercentage: number;
  recycledVolume: Volume;
  nonRecycledPercentage: number;
  nonRecycledVolume: Volume;
  woodSpeciesNodes: WoodSpeciesNode[];
}

export interface WoodSpeciesNode {
  woodSpeciesRecord: WoodSpeciesRecord;
  assemblySiteNodes: AssemblySiteNode[];
}

export interface NodeInfo {
  name: string;
  cocCode: string;
  country: number;
  certification: number;
  certificationTypeAndPercentage: string;
}

export interface AssemblySiteNode extends NodeInfo {
  manufacturersData: ManufacturersData;
  exportersData: ExportersData;
  forestManagersData: ForestManagersData;
  tradingCompany: TradingCompany;
  joineryWorkshopNodes: JoineryWorkshopNode[];
}

export interface TradingCompany extends NodeInfo {
  manufacturersData: ManufacturersData;
  exportersData: ExportersData;
}

export interface JoineryWorkshopNode extends NodeInfo {
  manufacturersData: ManufacturersData;
  exportersData: ExportersData;
  forestManagersData: ForestManagersData;
  sawmillNodes: SawmillNode[];
}

export interface SawmillNode extends NodeInfo {
  manufacturersData: ManufacturersData;
  exportersData: ExportersData;
  forestManagersData: ForestManagersData;
  forestryConcessionNodes: ForestryConcessionNode[];
}

export interface ForestryConcessionNode extends NodeInfo {
  region: string;
  forestManagersData: ForestManagersData;
  exportersData: ExportersData;
}

export interface ManufacturersData {
  certificationRecords: ManufacturerCertificationRecord[];
  legalityOfProcessingRecords: ManufacturerLegalityOfProcessingRecord[];
  paymentOfTaxesAndRoyaltiesRecords: ManufacturerPaymentOfTaxesAndRoyaltiesRecord[];
}

export interface ManufacturerCertificationRecord {
  certification: number;
  document: number;
  date: Date;
}

export interface ManufacturerLegalityOfProcessingRecord {
  legalityOfProcessing: number;
  document: number;
  date: Date;
}

export interface ManufacturerPaymentOfTaxesAndRoyaltiesRecord {
  paymentOfTaxesAndRoyalties: number;
  document: number;
  date: Date;
}

export interface ExportersData {
  legalityOfSaleAndExportRecords: ExporterLegalityOfSaleAndExportRecord[];
  paymentOfFeesAndRoyaltiesRecords: ExporterPaymentOfFeesAndRoyaltiesRecord[];
}

export interface ExporterLegalityOfSaleAndExportRecord {
  legalityOfSaleAndExport: number;
  document: number;
  date: Date;
}

export interface ExporterPaymentOfFeesAndRoyaltiesRecord {
  paymentOfFeesAndRoyalties: number;
  document: number;
  date: Date;
}

export interface ForestManagersData {
  certificationRecords: ForestCertificationRecord[];
  legalRightToHarvestRecords: ForestLegalRightToHarvestRecord[];
  legalityOfHarvestRecords: ForestLegalityOfHarvestRecord[];
  paymentOfTaxesAndRoyaltiesRecords: ForestPaymentOfTaxesAndRoyaltiesRecord[];
}

export interface ForestCertificationRecord {
  certification: number;
  document: number;
  date: Date;
}

export interface ForestLegalRightToHarvestRecord {
  legalRightToHarvest: number;
  document: number;
  date: Date;
}

export interface ForestLegalityOfHarvestRecord {
  legalityOfHarvest: number;
  document: number;
  date: Date;
}

export interface ForestPaymentOfTaxesAndRoyaltiesRecord {
  paymentOfTaxesAndRoyalties: number;
  document: number;
  date: Date;
}

export interface Conclusion {
  originWoodEvaluation?: OriginWoodEvaluation;
  productCertificationEvaluation?: ProductCertificationEvaluation;
  woodCompositionEvaluation?: WoodCompositionEvaluation;
  comments: string;
}

export interface OriginWoodEvaluation {
  supplierLocatedInEurope?: OriginWoodLicenseValidityVerdict;
  flegtLicense?: OriginWoodLicenseValidityVerdict;
  citesLicense?: OriginWoodLicenseValidityVerdict;
  fromIndonesia?: OriginWoodLicenseValidityVerdict;
}

export interface ProductCertificationEvaluation {
  certificationValidity?: ProductCertificationCertificationValidityVerdict;
  certificationScope?: ProductCertificationCertificationScopeVerdict;
  invoicesProvided?: ProductCertificationInvoicesProvidedVerdict;
}

export interface WoodCompositionEvaluation {
  woodCompositionDocumentEvaluations: WoodCompositionDocumentEvaluation[];
}

export interface WoodCompositionDocumentEvaluation {
  document: number;
  certificationValidity?: WoodCompositionCertificationValidityVerdict;
  certificationScope?: WoodCompositionCertificationScopeVerdict;
  invoices?: WoodCompositionInvoicesVerdict;
  consistency?: WoodCompositionConsistencyVerdict;
  correctness?: WoodCompositionCorrectnessVerdict;
}

export interface Scoring {
  // TODO_JORIS: should maybe not always be present ?
  countryAnalysis: CountryAnalysis;
  supplyChainAnalysis: SupplyChainAnalysis;
  globalScore: number;
  globalScoreLabel: GlobalScoringLabel;
}

export interface CountryAnalysis {
  sanctionOnu?: ScoringRecord;
  cpiRisk?: ScoringRecord;
  armedConflict?: ScoringRecord;
}

export enum GlobalScoringLabel {
  GREEN = "GREEN",
  AMBER = "AMBER",
  RED = "RED",
}

export interface SupplyChainAnalysis {
  completelyRecycled?: ScoringRecord;
  documentedAndApproved?: ScoringRecord;
  supplierLocatedInEurope?: ScoringRecord;
  certifications?: ScoringRecord;
  timberSpecies?: ScoringRecord;
  chainComplete?: ScoringRecord;
  legalityDocuments?: ScoringRecord;
  complexityOfTheChain?: ScoringRecord;
  brokenChainOfCustody?: ScoringRecord;
  sislEthicalPolicy?: ScoringRecord;
}

export interface Country {
  sanctionOnu: boolean;
  cpiindex: number;
  armedConflicts: boolean;
  nationalBan: boolean;
  value: number;
  label: string;
}

export interface ScoringRecord {
  significance?: Significance;
  remarks?: string;
  score?: number;
}

export enum Significance {
  YES = "YES",
  NO = "NO",
  HIGH = "HIGH",
  MEDIAL = "MEDIAL",
  LOW = "LOW",
  FORBIDDEN = "FORBIDDEN",
  ALLOWED = "ALLOWED",
}

export enum State {
  NOT_FULFILLED = "NOT_FULFILLED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING_QE_VALIDATION = "PENDING_QE_VALIDATION",
  REJECTED = "REJECTED",
  FULFILLED = "FULFILLED"
}

export const StateLabel = new Map<String, string>([
  [State.NOT_FULFILLED, 'Not Fulfilled'],
  [State.IN_PROGRESS, 'In Progress'],
  [State.PENDING_QE_VALIDATION, 'Pending QE validation'],
  [State.REJECTED, 'Rejected'],
  [State.FULFILLED, 'Fulfilled']
]);

export interface NodeSummary {
  id: number;
  name: string;
  country: number;
  children: NodeSummary[];
}
