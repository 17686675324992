<div class="uploads">
  <p-accordion [multiple]="true">
    <p-accordionTab [header]="'Pending uploads (' + httpRequestsService.requests.length + ')'" [selected]="true">
      <ul *ngIf="httpRequestsService.requests.length > 0">
        <li *ngFor="let obj of httpRequestsService.requests">{{obj.filename}}
          <p-progressBar [value]="obj.progress"></p-progressBar>
        </li>
      </ul>
      <p *ngIf="httpRequestsService.requests.length === 0" class="subtext">
        There are no pending uploads...
      </p>
    </p-accordionTab>
    <p-accordionTab [header]="'Successful uploads (' + httpRequestsService.successfulUploads.length + ')'">
      <ul *ngIf="httpRequestsService.successfulUploads.length > 0">
        <li *ngFor="let obj of httpRequestsService.successfulUploads">
          {{obj.filename}}
        </li>
      </ul>
      <p *ngIf="httpRequestsService.successfulUploads.length === 0" class="subtext">
        There are no successful uploads yet...
      </p>
    </p-accordionTab>
    <p-accordionTab [header]="'Failed uploads (' + httpRequestsService.failedUploads.length + ')'">
      <ul *ngIf="httpRequestsService.failedUploads.length > 0">
        <li *ngFor="let obj of httpRequestsService.failedUploads">
          {{obj.filename}}
        </li>
      </ul>
      <p *ngIf="httpRequestsService.failedUploads.length === 0" class="subtext">
        There are no failed uploads...
      </p>
    </p-accordionTab>
  </p-accordion>
</div>
