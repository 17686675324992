<fieldset>
  <legend>Nature, species and origin of the wood
    <rb-error-pill [formGroup]="getNatureSpeciesOriginWoodFormGroup()"></rb-error-pill>
  </legend>
  <form [formGroup]="getNatureSpeciesOriginWoodFormGroup()">
    <div class="grid-two-cols-uneven-div-flex">
      <label for="recycled-percentage">Recycled wood:</label>
      <div>
        <div>
          <rb-percentage inputId="recycled-percentage"
                         formControlName="recycledWoodPercentage"></rb-percentage>
        </div>
        <div>
          <rb-volume formControlName="recycledWoodVolume"></rb-volume>
        </div>
      </div>
      <label for="non-recycled-percentage">Non-recycled wood:</label>
      <div>
        <div>
          <rb-percentage inputId="non-recycled-percentage"
                         formControlName="nonRecycledWoodPercentage"></rb-percentage>
        </div>
        <div>
          <rb-volume formControlName="nonRecycledWoodVolume"></rb-volume>
        </div>
      </div>
      <div style="margin-top: 10px; margin-bottom: 10px;">
        For non-recycled wood:
      </div>
    </div>
    <div class="massive-wood">
      <rb-repeatable-species-record title="Massive wood species"
                                    [records]="getNatureSpeciesOriginWoodFormGroup().get('massiveWoodWoodSpeciesRecords')"></rb-repeatable-species-record>
    </div>
    <div class="particle-board">
      <rb-repeatable-species-record title="Particle board species"
                                    [records]="getNatureSpeciesOriginWoodFormGroup().get('particleBoardWoodSpeciesRecords')"></rb-repeatable-species-record>
    </div>
    <div class="plywood">
      <rb-repeatable-species-record title="Plywood species"
                                    [records]="getNatureSpeciesOriginWoodFormGroup().get('plywoodWoodSpeciesRecords')"></rb-repeatable-species-record>
    </div>
  </form>
</fieldset>
