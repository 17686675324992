<form [formGroup]="woodSpeciesRecord">
  <div class="species-grid">
    <rb-wood-species class="grid2x2" formControlName="woodSpecies"></rb-wood-species>
    <rb-volume formControlName="volume"></rb-volume>
    <rb-dropdown formControlName="countryOfHarvest"
                 [options]="referenceDataService.getCountriesOfHarvest()"
                 styleClass="width-200px"
    ></rb-dropdown>
    <rb-input formControlName="regionOfHarvest" classToApply="width-200px"></rb-input>
  </div>
</form>
