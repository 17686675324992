<div class="navigation">
  <div class="root-level" [rbHideIfUnauthorized]="Section.POLICYAGREEMENT"
    [state]="eutrDocument.state">
    <a routerLink="." fragment="policyAndAgreement" contentsLink>Policy and agreement
      <span
        *ngIf="shouldShowError(getAgreement())"
        class="pi pi-exclamation-circle error"></span>
    </a>
  </div>
  <div class="root-level" [rbHideIfUnauthorized]="Section.IDENTIFICATION"
    [state]="eutrDocument.state">
    <a routerLink="." fragment="identification" contentsLink>Identification
      <span
        *ngIf="shouldShowError(getIdentification())"
        class="pi pi-exclamation-circle error"></span>
    </a>
  </div>
  <div class="root-level" [rbHideIfUnauthorized]="Section.DATA"
    [state]="eutrDocument.state">
    <a routerLink="." fragment="data" contentsLink>Data
      <span
        *ngIf="shouldShowError(getData())"
        class="pi pi-exclamation-circle error"></span>
    </a>
    <div class="sub-level"
      *ngFor="let comp of getComponentNodes(); let i = index">
      <a routerLink="." [fragment]="'Component'+(i)" contentsLink>
        Component {{i + 1}}
        <span *ngIf="shouldShowError(comp)"
          class="pi pi-exclamation-circle error"></span>
      </a>
      <div class="sub-level" *ngFor="let d of getMassiveWoodSpeciesNodes(comp); let j = index">
        <a routerLink="." [fragment]="'Component'+(i)+'MassiveWood'+(j)" contentsLink>
          Massive wood species {{j + 1}}
          <span *ngIf="shouldShowError(d)"
            class="pi pi-exclamation-circle error"></span>
        </a>
        <div class="sub-level" *ngFor="let a of getAssemblySiteNodes(d); let k = index">
          <a routerLink="." [fragment]="'Component' + (i) + 'MassiveWood' + (j) + 'AssemblySite' + (k)" contentsLink>
            {{getAssemblySiteName(a, k + 1)}}
            <span *ngIf="shouldShowError(a)"
              class="pi pi-exclamation-circle error"></span>
          </a>
          <div class="sub-level" *ngFor="let jw of getJoineryWorkshopNodes(a); let l = index">
            <a routerLink="."
              [fragment]="'Component' + (i) + 'MassiveWood' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l)"
               contentsLink>
              {{getJoineryWorkshopName(jw, l + 1)}}
              <span
                *ngIf="shouldShowError(jw)"
                class="pi pi-exclamation-circle error"></span>
            </a>
            <div class="sub-level" *ngFor="let smn of getSawmillNodes(jw); let m = index">
              <a routerLink="."
                [fragment]="'Component' + (i) + 'MassiveWood' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l) + 'Sawmill' + (m)"
                 contentsLink>
                {{getSawmillName(smn, m + 1)}}
                <span
                  *ngIf="shouldShowError(smn)"
                  class="pi pi-exclamation-circle error"></span>
              </a>
              <div class="sub-level" *ngFor="let fcn of getForestryConcessionNodes(smn); let n = index">
                <a routerLink="."
                  [fragment]="'Component' + (i) + 'MassiveWood' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l) + 'Sawmill' + (m) + 'ForestryConcession' + (n)"
                   contentsLink>
                  {{getForestryConcessionName(fcn, n + 1)}}
                  <span
                    *ngIf="shouldShowError(fcn)"
                    class="pi pi-exclamation-circle error"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-level" *ngFor="let d of getParticleBoardSpeciesNodes(comp); let j = index">
        <a routerLink="." [fragment]="'Component'+(i)+'ParticleBoard'+(j)" contentsLink>Particle board
          species {{j + 1}}
          <span *ngIf="shouldShowError(d)"
            class="pi pi-exclamation-circle error"></span>
        </a>
        <div class="sub-level" *ngFor="let a of getAssemblySiteNodes(d); let k = index">
          <a routerLink="." [fragment]="'Component' + (i) + 'ParticleBoard' + (j) + 'AssemblySite'+(k)"
             contentsLink>Assembly site {{k + 1}}
            <span
              *ngIf="shouldShowError(a)"
              class="pi pi-exclamation-circle error"></span>
          </a>
          <div class="sub-level" *ngFor="let jw of getJoineryWorkshopNodes(a); let l = index">
            <a routerLink="."
              [fragment]="'Component' + (i) + 'ParticleBoard' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l)"
               contentsLink>
              {{getJoineryWorkshopName(jw, l + 1)}}
              <span
                *ngIf="shouldShowError(jw)"
                class="pi pi-exclamation-circle error"></span>
            </a>
            <div class="sub-level" *ngFor="let smn of getSawmillNodes(jw); let m = index">
              <a routerLink="."
                [fragment]="'Component' + (i) + 'ParticleBoard' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l) + 'Sawmill' + (m)"
                 contentsLink>
                {{getSawmillName(smn, m + 1)}}
                <span
                  *ngIf="shouldShowError(smn)"
                  class="pi pi-exclamation-circle error"></span>
              </a>
              <div class="sub-level" *ngFor="let fcn of getForestryConcessionNodes(smn); let n = index">
                <a routerLink="."
                  [fragment]="'Component' + (i) + 'ParticleBoard' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l) + 'Sawmill' + (m) + 'ForestryConcession' + (n)"
                   contentsLink>
                  {{getForestryConcessionName(fcn, n + 1)}}
                  <span
                    *ngIf="shouldShowError(fcn)"
                    class="pi pi-exclamation-circle error"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-level" *ngFor="let d of getPlywoodSpeciesNodes(comp); let j = index">
        <a routerLink="." [fragment]="'Component'+(i)+'Plywood'+(j)" contentsLink>Plywood
          species {{j + 1}}
          <span *ngIf="shouldShowError(d)"
            class="pi pi-exclamation-circle error"></span>
        </a>
        <div class="sub-level" *ngFor="let a of getAssemblySiteNodes(d); let k = index">
          <a routerLink="." [fragment]="'Component' + (i) + 'Plywood' + (j) + 'AssemblySite'+(k)"
             contentsLink>Assembly site {{k + 1}}
            <span
              *ngIf="shouldShowError(a)"
              class="pi pi-exclamation-circle error"></span>
          </a>
          <div class="sub-level" *ngFor="let jw of getJoineryWorkshopNodes(a); let l = index">
            <a routerLink="."
              [fragment]="'Component' + (i) + 'Plywood' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l)"
               contentsLink>
              {{getJoineryWorkshopName(jw, l + 1)}}
              <span
                *ngIf="shouldShowError(jw)"
                class="pi pi-exclamation-circle error"></span>
            </a>
            <div class="sub-level" *ngFor="let smn of getSawmillNodes(jw); let m = index">
              <a routerLink="."
                [fragment]="'Component' + (i) + 'Plywood' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l) + 'Sawmill' + (m)"
                 contentsLink>
                {{getSawmillName(smn, m + 1)}}
                <span
                  *ngIf="shouldShowError(smn)"
                  class="pi pi-exclamation-circle error"></span>
              </a>
              <div class="sub-level" *ngFor="let fcn of getForestryConcessionNodes(smn); let n = index">
                <a routerLink="."
                  [fragment]="'Component' + (i) + 'Plywood' + (j) + 'AssemblySite' + (k) + 'JoineryWorkshop' + (l) + 'Sawmill' + (m) + 'ForestryConcession' + (n)"
                   contentsLink>
                  {{getForestryConcessionName(fcn, n + 1)}}
                  <span
                    *ngIf="shouldShowError(fcn)"
                    class="pi pi-exclamation-circle error"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="root-level" [rbHideIfUnauthorized]="Section.CONCLUSION"
    [state]="eutrDocument.state">
    <a routerLink="." fragment="conclusion" contentsLink>Conclusion</a>
  </div>
  <div class="root-level" [rbHideIfUnauthorized]="Section.SCORING"
    [state]="eutrDocument.state">
    <a routerLink="." fragment="scoring" contentsLink>Scoring</a>
  </div>
</div>
