import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {FormService} from "../../services/form.service";

@Component({
  selector: 'rb-error-pill',
  templateUrl: './error-pill.component.html',
  styleUrls: ['./error-pill.component.scss']
})
export class ErrorPillComponent implements OnInit {

  @Input() formGroup: FormGroup;
  public nbOfErrors: number;

  constructor(public formService: FormService) {
  }

  ngOnInit() {
    this.updateNbOfErrors();
    this.formGroup.valueChanges.subscribe(() => {
      this.updateNbOfErrors();
    });
  }

  private updateNbOfErrors(): void {
    this.nbOfErrors = Object.values(this.formGroup.controls)
      .filter(control => control.status === "INVALID")
      .filter(control => control instanceof FormControl)
      .length;
  }
}
