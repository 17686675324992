<rb-repeatable-accordion-tab [records]="records"
                             [header]="computeHeader()"
                             [newRecordSupplier]="formService.createWoodSpeciesNode.bind(formService)">
  <ng-template #header let-woodRecord let-index="index">
    <span>{{computeTitle(title, index, referenceDataService.resolveWoodSpecies(woodRecord.get('woodSpeciesRecord').get('woodSpecies').value)?.scientificName)}}</span>
  </ng-template>
  <ng-template #record let-woodRecord let-nodeIndex="index">
    <div class="species-grid">
      <div class="grid2x2">
        <span class="required">Scientific name</span>
        <span class="required">Common name</span>
      </div>
      <div>
        <span style="display: inline-block; width: 80px;" class="required">Volume</span>
        <span style="display: inline-block" class="required">Unit</span>
      </div>
      <span class="required">Country of harvest</span>
      <span class="required">Region of harvest</span>
    </div>
    <rb-wood-species-record [woodSpeciesRecord]="woodRecord.get('woodSpeciesRecord')"></rb-wood-species-record>
    <rb-assembly-site-nodes
      [records]="woodRecord.get('assemblySiteNodes')"
      [hdr]="computeHeaderWithIndex(nodeIndex)"
    ></rb-assembly-site-nodes>
  </ng-template>
</rb-repeatable-accordion-tab>
