<div class="header-bar">
  <div class="logo">
    <img src="assets/scoptis_logo.png" alt="Scoptis logo">
  </div>
  <div>
    <h1>EUTR / FLEGT Declaration - Swiss ordinance of June, 4th 2010 - Australian Illegal Logging Prohibition Bill
    </h1>
  </div>
  <div class="action-area">
    <div>
      <p-button styleClass="ui-button-secondary" icon="pi pi-question-circle" label="Help"></p-button>
    </div>
    <div>
      <rb-user></rb-user>
    </div>
  </div>
</div>
