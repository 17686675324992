import {Component, Input, OnInit} from '@angular/core';
import {FormService} from "../../services/form.service";
import {EutrDocumentService} from "../../services/eutr-document.service";
import {EutrDocument} from "../contracts";
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'rb-discard-button',
  templateUrl: './discard-button.component.html',
  styleUrls: ['./discard-button.component.scss']
})
export class DiscardButtonComponent implements OnInit {

  @Input() eutrDocument: EutrDocument;

  constructor(public formService: FormService, private eutrDocumentService: EutrDocumentService, private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
  }

  discardChanges() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to discard all changes?',
      key: 'discard',
      accept: () => {
        this.eutrDocumentService.loadEutrDocument(this.eutrDocument.id);
      }
    });
  }

}
