import {Component, Input, OnInit} from '@angular/core';
import {Section} from "../../../services/user.service";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {EutrDocument} from "../../../shared/contracts";
import {FormService} from "../../../services/form.service";

@Component({
  selector: 'rb-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public Section = Section;
  @Input() form: FormGroup;
  @Input() eutrDocument: EutrDocument;

  constructor(public formService: FormService) {
  }

  ngOnInit() {
  }

  public getAgreement(): AbstractControl {
    if (!this.form.get('agreement')) {
      return null;
    } else {
      return this.form.get('agreement');
    }
  }

  public getIdentification(): AbstractControl {
    if (!this.form.get('identification')) {
      return null;
    } else {
      return this.form.get('identification');
    }
  }

  public getData(): AbstractControl {
    if (!this.form.get('data')) {
      return null;
    } else {
      return this.form.get('data');
    }
  }

  public getComponentNodes() {
    if (!(this.form.get('data') && this.form.get('data').get('woodComposition') && this.form.get('data').get('woodComposition').get('componentNodes'))) {
      return [];
    } else {
      return (this.form.get('data').get('woodComposition').get('componentNodes') as FormArray).controls;
    }
  }

  public getMassiveWoodSpeciesNodes(comp) {
    if (!comp || !comp.get('massiveWoodNode')) {
      return [];
    } else {
      return (comp.get('massiveWoodNode').get('woodSpeciesNodes') as FormArray).controls;
    }
  }

  public getParticleBoardSpeciesNodes(comp) {
    if (!comp || !comp.get('particleBoardNode')) {
      return [];
    } else {
      return (comp.get('particleBoardNode').get('woodSpeciesNodes') as FormArray).controls;
    }
  }

  public getPlywoodSpeciesNodes(comp) {
    if (!comp || !comp.get('plywoodNode')) {
      console.log("No plywood nodes found!");
      return [];
    } else {
      return (comp.get('plywoodNode').get('woodSpeciesNodes') as FormArray).controls;
    }
  }

  public getAssemblySiteNodes(node) {
    if (!node || !node.get('assemblySiteNodes')) {
      return [];
    } else {
      return (node.get('assemblySiteNodes') as FormArray).controls;
    }
  }

  public getJoineryWorkshopNodes(node) {
    if (!node || !node.get('joineryWorkshopNodes')) {
      return [];
    } else {
      return (node.get('joineryWorkshopNodes') as FormArray).controls;
    }
  }

  public getSawmillNodes(node) {
    if (!node || !node.get('sawmillNodes')) {
      return [];
    } else {
      return (node.get('sawmillNodes') as FormArray).controls;
    }
  }

  public getForestryConcessionNodes(node) {
    if (!node || !node.get('forestryConcessionNodes')) {
      return [];
    } else {
      return (node.get('forestryConcessionNodes') as FormArray).controls;
    }
  }

  public getAssemblySiteName(site, index) {
    if (!site || !site.get('name').value) {
      return "Assembly site " + index;
    } else {
      return "Assembly site " + index + " - " + site.get('name').value;
    }
  }

  public getJoineryWorkshopName(workshop, index) {
    if (!workshop || !workshop.get('name').value) {
      return "Joinery workshop " + index;
    } else {
      return "Joinery workshop " + index + " - " + workshop.get('name').value;
    }
  }

  public getSawmillName(sawmill, index) {
    if (!sawmill || !sawmill.get('name').value) {
      return "Sawmill " + index;
    } else {
      return "Sawmill " + index + " - " + sawmill.get('name').value;
    }
  }

  public getForestryConcessionName(concession, index) {
    if (!concession || !concession.get('name').value) {
      return "Forestry concession " + index;
    } else {
      return "Forestry concession " + index + " - " + concession.get('name').value;
    }
  }

  public hasError(control: AbstractControl) {
    const levelsToIgnore = ["woodComposition", "massiveWoodNode", "particleBoardNode", "plywoodNode", "woodSpeciesNodes", "assemblySiteNodes", "joineryWorkshopNodes", "sawmillNodes", "forestryConcessionNodes"];
    if (control instanceof FormControl) {
      return control.invalid;
    } else if (control instanceof FormGroup) {
      if (control.errors !== null) {
        return true;
      }
      for (let key in control.controls) {
        if (!levelsToIgnore.includes(key)) {
          if (control.controls[key].invalid) {
            return true;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  public shouldShowError(control: AbstractControl): boolean {
    return this.formService.stateChangeAttempted && this.hasError(control);
  }

}
