<p-accordion [multiple]="true">
  <rb-repeatable-accordion-tab [records]="records"
                               [header]="computeAssemblySiteHeader()"
                               [newRecordSupplier]="formService.createAssemblySiteNode.bind(formService)">
    <ng-template #header let-assemblySiteRecord let-asIndex="index">
      <span>{{computeTitle('Assembly site', asIndex, assemblySiteRecord.get('name').value)}}</span>
    </ng-template>
    <ng-template #record let-assemblySiteRecord let-asIndex="index">
      <rb-chain-search [nodeSummaries]="chainService.assemblySiteNodeSummaries"
                       (selectedNode)="onConfirmedNode($event, asIndex)"
                       [disabled]="assemblySiteRecord.disabled"
      ></rb-chain-search>
      <div [formGroup]="assemblySiteRecord">
        <div class="info-grid">
          <rb-checkbox label="Trading company"
                       formControlName="tradingCompanyCheckbox"
          ></rb-checkbox>
          <span></span>
          <label for="assembly-site-country-{{uid}}-{{asIndex}}" class="required">Country:</label>
          <rb-dropdown inputId="assembly-site-country-{{uid}}-{{asIndex}}"
                       formControlName="country"
                       [options]="referenceDataService.getChainPartsCountries()"
                       styleClass="width-200px"></rb-dropdown>
          <label for="assembly-site-name-{{uid}}-{{asIndex}}" class="required">Name:</label>
          <rb-input id="assembly-site-name-{{uid}}-{{asIndex}}" formControlName="name"
                    classToApply="width-200px"></rb-input>
          <label for="assembly-site-coc-code-{{uid}}-{{asIndex}}">CoC code:</label>
          <rb-input id="assembly-site-coc-code-{{uid}}-{{asIndex}}" formControlName="cocCode"
                    classToApply="width-200px"></rb-input>
          <label for="assembly-site-certification-{{uid}}-{{asIndex}}">Certification:</label>
          <rb-dropdown inputId="assembly-site-certification-{{uid}}-{{asIndex}}"
                       formControlName="certification"
                       [options]="referenceDataService.getChainPartsCertification()"
                       [autoDisplayFirst]="false"
                       [group]="true"
                       styleClass="width-200px"></rb-dropdown>
          <label for="assembly-site-certification-type-{{uid}}-{{asIndex}}">Certification Type and %<i
            class="pi pi-info-circle"
            pTooltip="(100%, Mix x%, Mix credit ...)"></i>
          </label>
          <rb-input id="assembly-site-certification-type-{{uid}}-{{asIndex}}"
                    formControlName="certificationTypeAndPercentage" classToApply="width-200px"></rb-input>
        </div>
      </div>
      <hr/>
      <rb-manufacturers-data
        [data]="assemblySiteRecord.get('manufacturersData')"></rb-manufacturers-data>
      <rb-exporters-data
        [data]="assemblySiteRecord.get('exportersData')"></rb-exporters-data>
      <rb-forest-managers-data
        [data]="assemblySiteRecord.get('forestManagersData')"></rb-forest-managers-data>
      <div *ngIf="assemblySiteRecord.get('tradingCompany')?.value">
        <p-accordionTab selected=true header="Trading company">
          <div [formGroup]="assemblySiteRecord.get('tradingCompany')">
            <div class="info-grid">
              <label for="trading-company-name-{{uid}}-{{asIndex}}" class="required">Name:</label>
              <rb-input id="trading-company-name-{{uid}}-{{asIndex}}" formControlName="name"></rb-input>
              <label for="trading-company-country-{{uid}}-{{asIndex}}" class="required">Country:</label>
              <rb-dropdown inputId="trading-company-country-{{uid}}-{{asIndex}}"
                           formControlName="country"
                           [options]="referenceDataService.getChainPartsCountries()"
                           styleClass="width-200px"></rb-dropdown>
              <label for="trading-company-certification-{{uid}}-{{asIndex}}">Certification:</label>
              <rb-dropdown inputId="trading-company-certification-{{uid}}-{{asIndex}}"
                           formControlName="certification"
                           [options]="referenceDataService.getChainPartsCertification()"
                           [group]="true"
                           styleClass="width-200px"></rb-dropdown>
              <label for="trading-company-coc-code-{{uid}}-{{asIndex}}">CoC code:</label>
              <rb-input id="trading-company-coc-code-{{uid}}-{{asIndex}}" formControlName="cocCode"></rb-input>
              <div></div>
              <div></div>
              <label for="trading-company-certification-type-{{uid}}-{{asIndex}}">Certification Type and %<i
                class="pi pi-info-circle"
                pTooltip="(100%, Mix x%, Mix credit ...)"></i>
              </label>
              <rb-input id="trading-company-certification-type-{{uid}}-{{asIndex}}"
                        formControlName="certificationTypeAndPercentage"></rb-input>
            </div>
          </div>
          <hr/>
          <rb-manufacturers-data
            [data]="assemblySiteRecord.get('tradingCompany').get('manufacturersData')"></rb-manufacturers-data>
          <rb-exporters-data
            [data]="assemblySiteRecord.get('tradingCompany').get('exportersData')"></rb-exporters-data>
        </p-accordionTab>
      </div>
      <rb-joinery-workshop-nodes
        [records]="assemblySiteRecord.get('joineryWorkshopNodes')"
        [hdr]="computeAssemblySiteHeaderWithIndex(asIndex)"
      ></rb-joinery-workshop-nodes>
    </ng-template>
  </rb-repeatable-accordion-tab>
</p-accordion>
