import {Injectable} from '@angular/core';
import {EutrDocument, Picture} from "../shared/contracts";
import {HttpClient} from "@angular/common/http";
import {RootResourceService} from "./root-resource.service";

@Injectable({
  providedIn: 'root'
})
export class PictureService {
  private pictures: Picture[];

  constructor(private http: HttpClient, private rootResourceService: RootResourceService) {
  }

  getPicture(pictureId: number): Picture {
    return this.pictures
      .find(pic => pic.id === pictureId);
  }

  get uploadUrl(): string {
    return this.rootResourceService.rootResource._links.documentUpload.href;
  }

  async loadPicturesForEutrDocument(eutrDocument: EutrDocument) {
    return this.http.get<any>(eutrDocument._links.findAllPicturesForEutrDocument.href)
      .toPromise()
      .then(resp => {
          this.pictures = resp._embedded.pictures;
        }
      );
  }
}
