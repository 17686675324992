import {Component} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {DateUtil} from "../../services/date-util";

@Component({
  selector: 'rb-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements ControlValueAccessor {
  constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };
  selectedDate: Date;
  disabled: boolean;

  get value() {
    return this.selectedDate;
  }

  /**
   * TD;DR: p-calendar does not natively support UTC.
   * On selecting a date, p-calendar sets the model value to new Date(dateMeta.year, dateMeta.month, dateMeta.day).
   * new Date(x, y(*), z) constructs a new Date object with time 00:00:00 and a timezone based on the user's system's timezone.
   * E.g.:
   * Selecting 13/02/2020 with system's timezone Brussels results in the following Date object:
   * Thu Feb 13 2020 00:00:00 GMT+0100 (Central European Standard Time)
   * The timezone-dependency causes a problem.
   * Angular's http client converts all outgoing dates to UTC(GMT+0) (as it should), such that this date becomes
   * Wed, 12 Feb 2020 23:00:00 GMT
   * The backend will deserialize "2020-02-12T23:00:00.000Z" as 12/02/2020, which is different from the selected date.
   * We only care about the date part - we don't care about the time part, nor do we care about timezones.
   * This method converts its input to a new Date object in UTC(GMT+0) with the same date part,
   * such that the backend will store the exact Date (dd/mm/yyyy) the user selected.
   * (*) Note that, as opposed to year and day, the month param is zero-based...
   */
  set value(val) {
    if (val !== undefined && this.selectedDate !== val) {
      this.selectedDate = DateUtil.createDateObjectWithSameDateButInUtc(val);
      this.onChange(this.selectedDate);
      this.onTouch(this.selectedDate);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  writeValue(obj) {
    this.value = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
