import {Component, Input} from '@angular/core';
import {EutrDocument} from "../../../../shared/contracts";

@Component({
  selector: 'rb-supplier-identification',
  templateUrl: './supplier-identification.component.html',
  styleUrls: ['./supplier-identification.component.scss']
})
export class SupplierIdentificationComponent {
  @Input() eutrDocument: EutrDocument;

  constructor() {
  }
}
