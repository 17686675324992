<fieldset>
  <legend>{{title}}</legend>
  <rb-repeatable-row [records]="records" [newRecordSupplier]="formService.createWoodSpeciesRecord.bind(formService)">
    <ng-template #header let-header>
      <div class="species-grid">
        <div class="grid2x2">
          <span>Scientific name</span>
          <span>Common name</span>
        </div>
        <div>
          <span style="display: inline-block; width: 80px;">Volume</span>
          <span style="display: inline-block;">Unit</span>
        </div>
        <span>Country of harvest</span>
        <span>Region of harvest</span>
      </div>
    </ng-template>
    <ng-template #record let-record let-index="index" let-records="records">
      <rb-wood-species-record [woodSpeciesRecord]="record"></rb-wood-species-record>
    </ng-template>
  </rb-repeatable-row>
</fieldset>
