import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../services/reference-data.service";
import {FormGroup} from "@angular/forms";
import {FormService} from "../../services/form.service";

@Component({
  selector: 'rb-wood-species-record',
  templateUrl: './wood-species-record.component.html',
  styleUrls: ['./wood-species-record.component.scss']
})
export class WoodWoodSpeciesRecordComponent {
  @Input() woodSpeciesRecord: FormGroup;

  constructor(public referenceDataService: ReferenceDataService, public formService: FormService) {
  }
}
