import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EutrPageComponent} from './eutr-page/eutr-page.component';
import {PageNotFoundComponent} from "./error-pages/page-not-found/page-not-found.component";
import {CanDeactivateGuard} from "./can-deactivate-guard.guard";
import {CanActivateGuard} from "./can-activate-guard.guard";

const routes: Routes = [
  {
    path: 'EUTR/:id',
    component: EutrPageComponent,
    canActivate: [CanActivateGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
