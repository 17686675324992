import {Component, Input} from '@angular/core';
import {ModalServiceService} from "../../../../services/modal-service.service";
import {EutrDocument, ProductIdentificationItem} from "../../../../shared/contracts";

@Component({
  selector: 'rb-product-identification',
  templateUrl: './product-identification.component.html',
  styleUrls: ['./product-identification.component.scss']
})
export class ProductIdentificationComponent {
  @Input() eutrDocument: EutrDocument;

  constructor(private modalService: ModalServiceService) {
  }

  addItem() {
    const dr = this.modalService.openAddItemModalComponent({
      items: this.eutrDocument.identification.productIdentification.items
    }, {
      header: 'Add items',
      width: '1200px',
      closeOnEscape: true,
      dismissableMask: true,
      closable: true
    });

    dr.onClose.subscribe((r) => {
        if (!r || !r.length) {
          return;
        }

        this.eutrDocument.identification.productIdentification.items.push(...r.filter((i) => !this.eutrDocument.identification.productIdentification.items.find((ii) => ii.code === i.code)));
      },
      (e) => {
        console.log(e);
      });
  }

  removeItem(item: ProductIdentificationItem) {
    this.eutrDocument.identification.productIdentification.items = this.eutrDocument.identification.productIdentification.items.filter((i) => i.code !== item.code);
  }
}
