import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-nature-species-origin-wood',
  templateUrl: './nature-species-origin-wood.component.html',
  styleUrls: ['./nature-species-origin-wood.component.scss', '../../data.component.scss']
})
export class NatureSpeciesOriginWoodComponent {
  @Input() form: FormGroup;

  constructor() {
  }

  public getNatureSpeciesOriginWoodFormGroup(): FormGroup {
    return this.form.get('natureSpeciesOriginWood') as FormGroup;
  }
}
