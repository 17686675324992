import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../../../../services/reference-data.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-product-certification',
  templateUrl: './product-certification.component.html',
  styleUrls: ['product-certification.component.scss', './../../data.component.scss']
})
export class ProductCertificationComponent {
  @Input() form: FormGroup;

  constructor(public referenceDataService: ReferenceDataService) {
  }
}
