import {Injectable} from '@angular/core';
import {EutrDocument, State} from "../shared/contracts";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {DocumentService} from "./document.service";
import {PictureService} from "./picture.service";
import {FormValidationService} from "./form-validation.service";
import {FormService} from "./form.service";
import {ReferenceDataService} from "./reference-data.service";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {Router} from "@angular/router";
import {RootResourceService} from "./root-resource.service";

@Injectable({
  providedIn: 'root'
})
export class EutrDocumentService {
  eutrDocument: EutrDocument;
  eutrDocumentObservable: Subject<EutrDocument>;
  public initialized: boolean = false;
  // TODO_JORIS: fix circular dependency!
  public formService: FormService;

  constructor(
    private http: HttpClient,
    private rootResourceService: RootResourceService,
    private referenceDataService: ReferenceDataService,
    private documentService: DocumentService,
    private pictureService: PictureService,
    private formValidationService: FormValidationService,
    private router: Router,
  ) {
    this.eutrDocumentObservable = new ReplaySubject(1);
  }

  // TODO_JORIS cleanup
  async loadEutrDocument(id: number) {
    console.log('Initializing EutrDocument with id %d.', id);
    this.initialized = false;
    if (!this.referenceDataService.initialized) {
      await this.referenceDataService.loadReferenceData();
    }
    // TODO_JORIS is dit ok?
    return this.http.get<any>(this.rootResourceService.rootResource._links.eutrDocuments.href + '/' + id)
      .toPromise()
      .then(eutrDocument => {
        if (eutrDocument.state === State.NOT_FULFILLED) {
          eutrDocument.state = State.IN_PROGRESS;
          console.log('First time opening document, so changing state from NOT_FULFILLED to IN_PROGRESS.', id);
          return this.http.put<any>(eutrDocument._links.self.href, eutrDocument).toPromise();
        } else {
          return Promise.resolve(eutrDocument);
        }
      })
      .then(async resp => {
          await this.documentService.loadDocumentsForEutrDocument(resp);
          await this.pictureService.loadPicturesForEutrDocument(resp);
          this.formService.initializeEutrForm(resp);
          this.setDocument(resp);

          this.initialized = true;
        }
      );
  }

  public setDocument(eutrDocument: EutrDocument) {
    this.eutrDocument = eutrDocument;
    this.eutrDocumentObservable.next(this.eutrDocument);

    if (this.eutrDocument && this.eutrDocument.identification && this.eutrDocument.identification.productIdentification.swissOnly) {
      eutrDocument.scoring = {
        countryAnalysis: {},
        supplyChainAnalysis: {},
        globalScore: null,
        globalScoreLabel: null
      };
    } else {
      eutrDocument.scoring = {
        countryAnalysis: {},
        supplyChainAnalysis: {},
        globalScore: null,
        globalScoreLabel: null
      };
    }
  }

  createSwissOnly() {
    this.http.post(environment.apiUrl + 'eutrDocuments/swissOnly', null)
      .subscribe((res: EutrDocument) => {
        this.router.navigate(['/EUTR/' + res.id]);
      });
  }

  createNotSwissOnly() {
    this.http.post(environment.apiUrl + 'eutrDocuments', null)
      .subscribe((res: EutrDocument) => {
        this.router.navigate(['/EUTR/' + res.id]);
      });
  }

  getEutrDocument(): EutrDocument {
    return this.eutrDocument;
  }

  getEutrDocumentAsObservable(): Observable<EutrDocument> {
    return this.eutrDocumentObservable;
  }
}
