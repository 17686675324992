<p-dropdown [inputId]="inputId"
            [options]="options"
            [autoDisplayFirst]="autoDisplayFirst"
            [showClear]="true"
            [group]="group"
            [formControl]="ngControl.control"
            [styleClass]="styleClass"
            (onChange)="valueChanged($event)"
></p-dropdown>
<rb-error-marker [control]="ngControl.control"></rb-error-marker>
