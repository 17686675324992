<ng-container [formGroup]="formGroup">
  <label for="percentage-{{uid}}">{{title}}</label>
  <div>
    <rb-percentage inputId="percentage-{{uid}}"
                   formControlName="percentage"
    ></rb-percentage>
    <rb-volume formControlName="volume"
               (onAmountChange)="volumeAmountChanged($event)"
               (onUnitChange)="unitChanged($event)"
    ></rb-volume>
  </div>
  <div>
    <label for="recycled-percentage-{{uid}}">recycled:</label>
    <rb-percentage inputId="recycled-percentage-{{uid}}"
                   formControlName="recycledPercentage"
                   (onPercentageChange)="recycledPercentageChanged($event)"
    ></rb-percentage>
    <rb-volume *ngIf="formGroup.get('volume').value.amount; else elseBlock"
               formControlName="recycledVolume"
               (onAmountChange)="recycledVolumeAmountChanged($event)"
               (onUnitChange)="unitChanged($event)"
    ></rb-volume>
    <ng-template #elseBlock>
      <rb-volume [ngModel]="formGroup.get('recycledVolume').value"
                 [ngModelOptions]="{standalone: true}"
                 [disabled]="true"
      ></rb-volume>
    </ng-template>
  </div>
  <div>
    <label for="non-recycled-percentage-{{uid}}">non-recycled:</label>
    <rb-percentage inputId="non-recycled-percentage-{{uid}}"
                   formControlName="nonRecycledPercentage"
                   (onPercentageChange)="nonRecycledPercentageChanged($event)"
    ></rb-percentage>
    <rb-volume *ngIf="formGroup.get('volume').value.amount; else elseBlock"
               formControlName="nonRecycledVolume"
               (onAmountChange)="nonRecycledVolumeAmountChanged($event)"
               (onUnitChange)="unitChanged($event)"
    ></rb-volume>
    <ng-template #elseBlock>
      <rb-volume [ngModel]="formGroup.get('nonRecycledVolume').value"
                 [ngModelOptions]="{standalone: true}"
                 [disabled]="true"
      ></rb-volume>
    </ng-template>
  </div>
</ng-container>
