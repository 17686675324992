<fieldset>
  <legend>
    <span>The product is composed of:</span>
    <span style="font-size: x-small"> (% in volume)</span>
    <rb-error-pill [formGroup]="form.get('data').get('productCompositionSwissOnly')"></rb-error-pill>
  </legend>
  <form [formGroup]="form">
    <div formGroupName="data">
      <div formGroupName="productCompositionSwissOnly">
        <div class="prod-comp-swiss-only-grid">
          <label for="massive-wood-percentage">Massive wood:</label>
          <div>
            <rb-percentage inputId="massive-wood-percentage"
                           formControlName="massiveWoodPercentage"></rb-percentage>
            <rb-volume formControlName="massiveWoodVolume"></rb-volume>
          </div>
          <label for="particle-board-percentage">Particle board:</label>
          <div>
            <rb-percentage inputId="particle-board-percentage"
                           formControlName="particleBoardPercentage"></rb-percentage>
            <rb-volume formControlName="particleBoardVolume"></rb-volume>
          </div>
          <label for="other-materials-percentage">Other materials:</label>
          <div>
            <rb-percentage inputId="other-materials-percentage"
                           formControlName="otherMaterialsPercentage"></rb-percentage>
            <rb-volume formControlName="otherMaterialsVolume"></rb-volume>
          </div>
          <label for="net-product-weight" class="bold">Net product weight:</label>
          <div>
            <rb-input type="number" id="net-product-weight"
                      formControlName="netProductWeight"></rb-input>
            <span style="margin-left: 2px;">kg</span>
          </div>
        </div>
        <div>
          <rb-checkbox label="Mixed plywood"
                       formControlName="mixedPlywood"></rb-checkbox>
        </div>
        <div>
          <rb-checkbox label="All massive wood in the product is recycled material"
                       formControlName="allMassiveWoodIsRecycled"
          ></rb-checkbox>
        </div>
      </div>
    </div>
  </form>
</fieldset>
