import {AfterViewInit, Directive, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {TocService} from "./toc.service";

@Directive({
  selector: '[contents]',
  exportAs: 'contents',
})
export class ContentsDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() scrollingView: HTMLElement;

  _onScroll$: Subject<Event> = new Subject<Event>(); // tslint:disable-line:variable-name

  private scrollFun: EventListenerOrEventListenerObject = (event: Event) => this._onScroll$.next(event);

  constructor(private tocService: TocService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.tocService.registerContentsDirective(this);
    this.unsubscribeScrollEventListener();
    this.subscribeScrollEventListener();

  }

  ngOnChanges() {
    this.unsubscribeScrollEventListener();
    this.subscribeScrollEventListener();
  }

  ngOnDestroy() {
    this.unsubscribeScrollEventListener();
    this.tocService.unregisterContentsDirective(this);
  }

  // Subscribe to scrollingView scroll events. Sections will detectChanges() on scroll changes.
  subscribeScrollEventListener() {
    this.scrollingView.addEventListener('scroll', this.scrollFun, false);
  }

  unsubscribeScrollEventListener() {
    this.scrollingView.removeEventListener('scroll', this.scrollFun, false);
  }

}
