import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../../../../../../services/reference-data.service";
import {FormService} from "../../../../../../../services/form.service";
import {FormArray} from "@angular/forms";

@Component({
  selector: 'rb-wood-species-nodes',
  templateUrl: './wood-species-nodes.component.html',
  styleUrls: ['./wood-species-nodes.component.scss']
})
export class WoodSpeciesNodesComponent {
  @Input() records: FormArray;
  @Input() title: string;
  @Input() prevHeader: String;
  @Input() label: String;

  constructor(
    public referenceDataService: ReferenceDataService,
    public formService: FormService
  ) {
  }

  computeTitle(base: string, index: number, data: string) {
    return base + " " + (index + 1) + (data ? (" - " + data) : "");
  }

  computeHeader() {
    return '' + this.prevHeader + this.label;
  }

  computeHeaderWithIndex(index) {
    return '' + this.prevHeader + this.label + index;
  }
}
