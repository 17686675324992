import {Injectable} from '@angular/core';
import {
  AddItemDialog,
  AddItemDialogData,
  AddItemModalComponent
} from "../eutr-page/pages/identification/product-identification/add-item-modal/add-item-modal.component";
import {DialogService, DynamicDialogConfig} from "primeng/dynamicdialog";


@Injectable({
  providedIn: 'root'
})
export class ModalServiceService {

  constructor(private dialogService: DialogService) {
  }

  openAddItemModalComponent(data: AddItemDialogData, config: DynamicDialogConfig): AddItemDialog {
    return this.dialogService.open(AddItemModalComponent, {...config, data});
  }
}
