<fieldset>
  <legend>Sourcing:</legend>
  <div class="grid-two-cols">
    <span>Date:</span>
    <span>{{eutrDocument?.identification?.sourcing?.date.toLocaleString()}}</span>
    <span>Sourcing Office:</span>
    <span>{{eutrDocument?.identification?.sourcing?.sourcingOffice}}</span>
    <span>Asia Quality Group:</span>
    <span>{{eutrDocument?.identification?.sourcing?.asiaQualityGroup}}</span>
    <span>Quality engineer / NPD / QCC:</span>
    <span>{{eutrDocument?.identification?.sourcing?.qualityEngineer}}</span>
    <span>Quality engineer mail:</span>
    <span>{{eutrDocument?.identification?.sourcing?.qualityEngineerEmail}}</span>
  </div>
</fieldset>
