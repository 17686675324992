import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {EutrDocument, StateLabel} from "../contracts";
import {SelectItem} from "primeng/api";
import {StateService} from "../../services/state.service";
import {FormService} from "../../services/form.service";
import {Dropdown} from "primeng/dropdown";
import {HttpRequestsService} from "../../services/http-requests.service";

@Component({
  selector: 'rb-state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['./state-selector.component.scss']

})
export class StateSelectorComponent implements OnChanges {
  @ViewChild('dropdown') dropdown: Dropdown;
  @Input() eutrDocument: EutrDocument;
  public stateDropdownOptions: SelectItem[];

  constructor(public stateService: StateService, public formService: FormService, private httpRequestsService: HttpRequestsService) {
  }

  ngOnChanges(changes: any) {

    // TODO_JORIS: willen we daar niet eerder een fixed order in?
    this.stateDropdownOptions = [changes.eutrDocument.currentValue.state, ...this.stateService.getPossibleTransitions(changes.eutrDocument.currentValue)]
      .map(s => ({
          label: StateLabel.get(s),
          value: s
        })
      );
    // TODO_JORIS: don't want this here but can't get it to work, so workaround ...
    if (this.dropdown) {
      this.dropdown.writeValue(this.eutrDocument.state);
    }
  }

  dropdownValueChanged() {
    let selectedValue = this.dropdown.value;
    // revert change in dropdown value; the value will "automatically" be updated if user successfully saves eutrDocument
    this.dropdown.writeValue(this.eutrDocument.state);

    this.formService.saveByChangeState(this.eutrDocument, selectedValue);
  }

  pendingUploads(): boolean {
    return !!this.httpRequestsService.requests.length;
  }
}
