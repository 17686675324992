import {Injectable} from '@angular/core';
import {
  Conclusion,
  ExportersData,
  ForestManagersData,
  ManufacturersData,
  WoodComposition,
  WoodCompositionDocumentEvaluation,
  WoodCompositionEvaluation,
  WoodSpeciesNode
} from "../shared/contracts";
import {ScoringUtil} from "./scoring-helper/ScoringUtil";

@Injectable({
  providedIn: 'root'
})
export class ConclusionService {
  public recomputeWoodCompositionEvaluation(conclusion: Conclusion, woodComposition: WoodComposition): WoodCompositionEvaluation {
    // TODO_JORIS: cleanup
    if (!woodComposition) {
      return {woodCompositionDocumentEvaluations: []};
    }
    let woodCompositionDocumentEvaluations: Map<number, WoodCompositionDocumentEvaluation>;
    woodCompositionDocumentEvaluations = conclusion.woodCompositionEvaluation
      ? new Map(conclusion.woodCompositionEvaluation.woodCompositionDocumentEvaluations
        .map(woodCompositionDocumentEvaluation => [woodCompositionDocumentEvaluation.document, woodCompositionDocumentEvaluation]))
      : new Map<number, WoodCompositionDocumentEvaluation>();

    let updatedDocumentEvaluations = woodComposition.componentNodes
      .flatMap(cn => ScoringUtil.getWoodCompositionNodes(cn))
      .flatMap(wcn => wcn.woodSpeciesNodes)
      .flatMap(wsn => this.createWoodCompositionDocumentEvaluationsWoodSpeciesNode(wsn))
      .map(documentEvaluation =>
        woodCompositionDocumentEvaluations.has(documentEvaluation.document)
          ? woodCompositionDocumentEvaluations.get(documentEvaluation.document)
          : documentEvaluation
      );

    return {woodCompositionDocumentEvaluations: updatedDocumentEvaluations};
  }

  private createWoodCompositionDocumentEvaluationsWoodSpeciesNode(woodSpeciesNode: WoodSpeciesNode) {
    return woodSpeciesNode.assemblySiteNodes.flatMap(assemblySiteNode =>
      []
        .concat(this.createWoodCompositionDocumentEvaluationsForManufacturersData(assemblySiteNode.manufacturersData))
        .concat(this.createWoodCompositionDocumentEvaluationsForExportersData(assemblySiteNode.exportersData))
        .concat(this.createWoodCompositionDocumentEvaluationsForForestManagersData(assemblySiteNode.forestManagersData))
        .concat(assemblySiteNode.joineryWorkshopNodes.flatMap(joineryWorkshopNode =>
          []
            .concat(this.createWoodCompositionDocumentEvaluationsForManufacturersData(joineryWorkshopNode.manufacturersData))
            .concat(this.createWoodCompositionDocumentEvaluationsForExportersData(joineryWorkshopNode.exportersData))
            .concat(this.createWoodCompositionDocumentEvaluationsForForestManagersData(assemblySiteNode.forestManagersData))
            .concat(joineryWorkshopNode.sawmillNodes.flatMap(sawmillNode =>
              []
                .concat(this.createWoodCompositionDocumentEvaluationsForManufacturersData(sawmillNode.manufacturersData))
                .concat(this.createWoodCompositionDocumentEvaluationsForExportersData(sawmillNode.exportersData))
                .concat(this.createWoodCompositionDocumentEvaluationsForForestManagersData(assemblySiteNode.forestManagersData))
                .concat(sawmillNode.forestryConcessionNodes.flatMap(forestryConcessionNode =>
                  []
                    .concat(this.createWoodCompositionDocumentEvaluationsForForestManagersData(forestryConcessionNode.forestManagersData))
                    .concat(this.createWoodCompositionDocumentEvaluationsForExportersData(forestryConcessionNode.exportersData))
                ))
            ))
        ))
    );
  }

  private createWoodCompositionDocumentEvaluationsForManufacturersData(manufacturersData: ManufacturersData): WoodCompositionDocumentEvaluation[] {
    return []
      .concat(manufacturersData.certificationRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createCertificationDocumentEvaluation(documentId)))
      .concat(manufacturersData.legalityOfProcessingRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createNonCertificationDocumentEvaluation(documentId)))
      .concat(manufacturersData.paymentOfTaxesAndRoyaltiesRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createNonCertificationDocumentEvaluation(documentId)));
  }

  private createWoodCompositionDocumentEvaluationsForExportersData(exportersData: ExportersData): WoodCompositionDocumentEvaluation[] {
    return []
      .concat(exportersData.legalityOfSaleAndExportRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createCertificationDocumentEvaluation(documentId)))
      .concat(exportersData.paymentOfFeesAndRoyaltiesRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createNonCertificationDocumentEvaluation(documentId))
      );
  }

  private createWoodCompositionDocumentEvaluationsForForestManagersData(forestManagersData: ForestManagersData): WoodCompositionDocumentEvaluation[] {
    return []
      .concat(forestManagersData.certificationRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createCertificationDocumentEvaluation(documentId)))
      .concat(forestManagersData.legalRightToHarvestRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createNonCertificationDocumentEvaluation(documentId)))
      .concat(forestManagersData.legalityOfHarvestRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createNonCertificationDocumentEvaluation(documentId)))
      .concat(forestManagersData.paymentOfTaxesAndRoyaltiesRecords
        .map(certificationRecord => certificationRecord.document)
        .filter(documentId => documentId)
        .map(documentId => this.createNonCertificationDocumentEvaluation(documentId)));
  }

  private createCertificationDocumentEvaluation(documentId: number): WoodCompositionDocumentEvaluation {
    return {
      document: documentId,
      certificationValidity: null,
      certificationScope: null,
      invoices: null,
      consistency: null,
    };
  }

  private createNonCertificationDocumentEvaluation(documentId: number): WoodCompositionDocumentEvaluation {
    return {
      document: documentId,
      correctness: null,
    };
  }
}
