<p-accordion [multiple]="true">
  <rb-repeatable-accordion-tab [records]="records"
                               [header]="computeSawmillHeader()"
                               [newRecordSupplier]="formService.createSawmillNode.bind(formService)">
    <ng-template #header let-sawmillRecord let-smIndex="index">
      <span>{{computeTitle('Sawmill', smIndex, sawmillRecord.get('name')?.value)}}</span>
    </ng-template>
    <ng-template #record let-sawmillRecord let-smIndex="index">
      <rb-chain-search [nodeSummaries]="chainService.sawmillNodeSummaries"
                       (selectedNode)="onConfirmedNode($event, smIndex)"
                       [disabled]="sawmillRecord.disabled"
      ></rb-chain-search>
      <div [formGroup]="sawmillRecord">
        <div class="info-grid">
          <label for="sawmill-name-{{uid}}-{{smIndex}}" class="required">Name:</label>
          <rb-input id="sawmill-name-{{uid}}-{{smIndex}}" formControlName="name" classToApply="width-200px"></rb-input>
          <label for="sawmill-country-{{uid}}-{{smIndex}}" class="required">Country:</label>
          <rb-dropdown inputId="sawmill-country-{{uid}}-{{smIndex}}"
                       formControlName="country"
                       [options]="referenceDataService.getChainPartsCountries()"
                       styleClass="width-200px"></rb-dropdown>
          <label for="sawmill-certification-{{uid}}-{{smIndex}}">Certification:</label>
          <rb-dropdown inputId="sawmill-certification-{{uid}}-{{smIndex}}"
                       formControlName="certification"
                       [options]="referenceDataService.getChainPartsCertification()"
                       [group]="true"
                       styleClass="width-200px"></rb-dropdown>
          <label for="sawmill-coc-code-{{uid}}-{{smIndex}}">CoC code:</label>
          <rb-input id="sawmill-coc-code-{{uid}}-{{smIndex}}" formControlName="cocCode"
                    classToApply="width-200px"></rb-input>
          <div></div>
          <div></div>
          <label for="sawmill-certification-type-{{uid}}-{{smIndex}}">Certification Type and %<i
            class="pi pi-info-circle"
            pTooltip="(100%, Mix x%, Mix credit ...)"></i>
          </label>
          <rb-input id="sawmill-certification-type-{{uid}}-{{smIndex}}"
                    formControlName="certificationTypeAndPercentage" classToApply="width-200px"></rb-input>
        </div>
      </div>
      <hr/>
      <rb-manufacturers-data
        [data]="sawmillRecord.get('manufacturersData')"></rb-manufacturers-data>
      <rb-exporters-data
        [data]="sawmillRecord.get('exportersData')"></rb-exporters-data>
      <rb-forest-managers-data
        [data]="sawmillRecord.get('forestManagersData')"></rb-forest-managers-data>
      <rb-forestry-concession-nodes
        [records]="sawmillRecord.get('forestryConcessionNodes')"
        [hdr]="computeSawmillHeaderWithIndex(smIndex)"
      ></rb-forestry-concession-nodes>
    </ng-template>
  </rb-repeatable-accordion-tab>
</p-accordion>
