<p-accordionTab selected=true header="Product Certification">
  <form [formGroup]="getProductCertificationEvaluationFormGroup()" class="outer-content">
    <p-accordion [multiple]="true">
      <!--      TODO_JORIS die ngif zou meer conclusion-data-related moeten zijn -->
      <ng-container *ngIf="getCertificationDocument()">
        <p-accordionTab selected=true>
          <p-header>
            <div>
              <span>{{getCertificationDocument().fileName}}</span>
              <div (click)="$event.stopPropagation();" style="display: flex">
                <a href="{{getCertificationDocument()._links.download.href}}" download style="padding: 0">
                  <p-button type="button"
                            class="black-icon-btn"
                            icon="pi pi-file"></p-button>
                </a>
                <p-button type="button"
                          class="black-icon-btn"
                          icon="pi pi-thumbs-up"
                          [disabled]="getProductCertificationEvaluationFormGroup().disabled"
                          (onClick)="makeCertificationCompliant()"></p-button>
              </div>
            </div>
            <span *ngIf="isCertificationCompliant()" class="compliant">Compliant</span>
            <span *ngIf="!isCertificationCompliant()" class="not-compliant">Not Compliant</span>
          </p-header>
          <div class="verdicts">
            <label for="pc-certification-validity">Certification Validity</label>
            <p-dropdown inputId="pc-certification-validity"
                        formControlName="certificationValidity"
                        [options]="referenceDataService.getProductCertificationCertificationValidityOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
            <label for="pc-certification-scope">Certification Scope</label>
            <p-dropdown inputId="pc-certification-scope"
                        formControlName="certificationScope"
                        [options]="referenceDataService.getProductCertificationCertificationScopeOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </div>
        </p-accordionTab>
      </ng-container>
      <ng-container *ngIf="getInvoicesDocument()">
        <p-accordionTab selected=true>
          <p-header>
            <div>
              <span>{{getInvoicesDocument().fileName}}</span>
              <div (click)="$event.stopPropagation();" style="display: flex">
                <a href="{{getInvoicesDocument()._links.download.href}}" download style="padding: 0">
                  <p-button type="button"
                            class="black-icon-btn"
                            icon="pi pi-file"></p-button>
                </a>
                <p-button type="button"
                          class="black-icon-btn"
                          icon="pi pi-thumbs-up"
                          [disabled]="getProductCertificationEvaluationFormGroup().disabled"
                          (onClick)="makeInvoicesCompliant()"></p-button>
              </div>
            </div>
            <span *ngIf="isInvoicesCompliant()" class="compliant">Compliant</span>
            <span *ngIf="!isInvoicesCompliant()" class="not-compliant">Not Compliant</span>
          </p-header>
          <div class="verdicts">
            <label for="pc-invoices-provided">Invoices provided</label>
            <p-dropdown inputId="pc-invoices-provided"
                        formControlName="invoicesProvided"
                        [options]="referenceDataService.getProductCertificationInvoicesProvidedOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </div>
        </p-accordionTab>
      </ng-container>
    </p-accordion>
  </form>
</p-accordionTab>
