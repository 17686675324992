import {Injectable} from '@angular/core';
import {ReferenceDataService} from "./reference-data.service";
import {
  Country,
  EutrDocument,
  GlobalScoringLabel,
  LegalInformation,
  OriginWoodLicenseValidityVerdict,
  ProductCertificationCertificationScopeVerdict,
  ProductCertificationCertificationValidityVerdict,
  ProductCertificationInvoicesProvidedVerdict,
  Scoring,
  Significance,
  WoodSpecies
} from "../shared/contracts";
import {ScoringWoodChainHelper} from "./scoring-helper/ScoringWoodChainHelper";
import {ScoringUtil} from "./scoring-helper/ScoringUtil";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class ScoringService {
  private static readonly MISSING_INFORMATION = 'Not all information was provided';
  public byPassRulesApplicable: boolean;

  constructor(private referenceDataService: ReferenceDataService) {
  }

  public isCountryAnalysisNegligible(scoring: Scoring): boolean {
    return !scoring
      || !scoring.countryAnalysis
      || !scoring.countryAnalysis.cpiRisk
      || !scoring.countryAnalysis.armedConflict
      || !scoring.countryAnalysis.sanctionOnu
      || !(ScoringService.isSignificanceYesOrHigh(scoring.countryAnalysis.cpiRisk.significance)
        || ScoringService.isSignificanceYesOrHigh(scoring.countryAnalysis.armedConflict.significance)
        || ScoringService.isSignificanceYesOrHigh(scoring.countryAnalysis.sanctionOnu.significance));
  }

  public isSupplyChainAnalysisNegligible(scoring: Scoring): boolean {
    return scoring.globalScoreLabel === GlobalScoringLabel.GREEN;
  }

  public recomputeScoring(formGroup: FormGroup): Scoring {
    const clonedEutr = JSON.parse(JSON.stringify(formGroup.getRawValue()));
    this.recomputeCountryAnalysis(clonedEutr);
    this.recomputeCompletelyRecycled(clonedEutr);
    this.recomputeDocumentedAndApproved(formGroup, clonedEutr);
    this.recomputeSupplierLocatedInEurope(formGroup, clonedEutr);
    this.recomputeCertifications(formGroup, clonedEutr);
    this.recomputeTimberSpecies(clonedEutr);
    this.recomputeChainComplete(formGroup, clonedEutr);
    this.recomputeLegalityDocuments(clonedEutr);
    this.recomputeComplexityOfTheChain(clonedEutr);
    this.recomputeBrokenChainOfCustody(clonedEutr);
    this.recomputeSislEthicalPolicy(clonedEutr);
    this.propagateToGlobalScore(clonedEutr);
    return clonedEutr.scoring;
  }

  private recomputeCountryAnalysis(eutrDocument: EutrDocument) {
    let countriesOfHarvestIds = ScoringUtil.getAllWoodSpeciesRecordsInDocument(eutrDocument)
      .map(wsr => wsr.countryOfHarvest)
      .filter(countryOfHarvest => countryOfHarvest);
    this.updateSanctionOnu(eutrDocument, countriesOfHarvestIds);
    this.updateCpiRisk(eutrDocument, countriesOfHarvestIds);
    this.updateArmedConflict(eutrDocument, countriesOfHarvestIds);
  }

  private recomputeCompletelyRecycled(eutrDocument: EutrDocument) {
    eutrDocument.scoring.supplyChainAnalysis.completelyRecycled =
      this.isCompletelyRecycled(eutrDocument)
        ? {significance: Significance.YES}
        : undefined;
  }

  private recomputeDocumentedAndApproved(formGroup: FormGroup, eutrDocument: EutrDocument) {
    let originWoodEvaluation = eutrDocument.conclusion.originWoodEvaluation;
    if (
      originWoodEvaluation?.flegtLicense === undefined
      && originWoodEvaluation?.citesLicense === undefined
      && originWoodEvaluation?.fromIndonesia === undefined
    ) {
      eutrDocument.scoring.supplyChainAnalysis.documentedAndApproved = undefined;
      return;
    }

    const remarks: string[] = [];
    if (originWoodEvaluation?.flegtLicense !== undefined && originWoodEvaluation?.flegtLicense !== OriginWoodLicenseValidityVerdict.COMPLIANT) {
      remarks.push('The FLEGT document is not compliant');
    }
    if (originWoodEvaluation?.citesLicense !== undefined && originWoodEvaluation?.citesLicense !== OriginWoodLicenseValidityVerdict.COMPLIANT) {
      remarks.push('The CITES document is not compliant');
    }
    if (originWoodEvaluation?.fromIndonesia !== undefined && originWoodEvaluation?.fromIndonesia !== OriginWoodLicenseValidityVerdict.COMPLIANT) {
      remarks.push('The SVLK number is not compliant');
    }
    if (
      !formGroup.get('data').get('originWood').get('flegtLicense').valid
      || !formGroup.get('data').get('originWood').get('flegtDocument').valid
      || !formGroup.get('data').get('originWood').get('citesLicense').valid
      || !formGroup.get('data').get('originWood').get('citesDocument').valid
      || !formGroup.get('data').get('originWood').get('fromIndonesia').valid
      || !formGroup.get('data').get('originWood').get('svlkDocument').valid
      || !formGroup.get('data').get('natureSpeciesOriginWood').valid
    ) {
      remarks.push(ScoringService.MISSING_INFORMATION);
    }

    eutrDocument.scoring.supplyChainAnalysis.documentedAndApproved =
      remarks.length ? {
        significance: Significance.NO,
        remarks: remarks.join("\n")
      } : {
        significance: Significance.YES
      };
  }

  private recomputeSupplierLocatedInEurope(formGroup: FormGroup, eutrDocument: EutrDocument) {
    if (eutrDocument.conclusion.originWoodEvaluation?.supplierLocatedInEurope === undefined) {
      eutrDocument.scoring.supplyChainAnalysis.supplierLocatedInEurope = undefined;
      return;
    }

    const remarks = [];
    if (eutrDocument.conclusion.originWoodEvaluation.supplierLocatedInEurope !== OriginWoodLicenseValidityVerdict.COMPLIANT) {
      remarks.push('License for product supplier is located in EU is not valid');
    }
    if (!formGroup.get('data').get('originWood').get('supplierType').valid
      || !formGroup.get('data').get('natureSpeciesOriginWood').valid
    ) {
      remarks.push(ScoringService.MISSING_INFORMATION);
    }

    eutrDocument.scoring.supplyChainAnalysis.supplierLocatedInEurope =
      remarks.length
        ? {
          significance: Significance.NO,
          remarks: remarks.join("\n")
        }
        : {
          significance: Significance.YES
        };
  }

  private recomputeCertifications(formGroup: FormGroup, eutrDocument: EutrDocument) {
    let productCertificationEvaluation = eutrDocument.conclusion.productCertificationEvaluation;
    if (!productCertificationEvaluation) {
      eutrDocument.scoring.supplyChainAnalysis.certifications = undefined;
      return;
    }

    const remarks = [];
    if (productCertificationEvaluation.certificationValidity !== undefined && productCertificationEvaluation.certificationValidity !== ProductCertificationCertificationValidityVerdict.COMPLIANT) {
      remarks.push('Certification Validity is not compliant');
    }
    if (productCertificationEvaluation.certificationScope !== undefined && productCertificationEvaluation.certificationScope !== ProductCertificationCertificationScopeVerdict.COMPLIANT) {
      remarks.push('Certification Scope is not compliant');
    }
    if (productCertificationEvaluation.invoicesProvided !== undefined && productCertificationEvaluation.invoicesProvided !== ProductCertificationInvoicesProvidedVerdict.COMPLIANT) {
      remarks.push('Invoices provided are not compliant');
    }
    if (!formGroup.get('data').get('productCertification').valid
      || !formGroup.get('data').get('natureSpeciesOriginWood').valid
      || !formGroup.get('data').get('majorSpeciesOfMassiveWood').valid
    ) {
      remarks.push(ScoringService.MISSING_INFORMATION);
    }

    eutrDocument.scoring.supplyChainAnalysis.certifications =
      remarks.length
        ? {
          significance: Significance.NO,
          score: 0,
          remarks: remarks.join("\n")
        }
        : {
          significance: Significance.YES,
          score: 2000
        };
  }

  private recomputeTimberSpecies(eutrDocument: EutrDocument) {
    const woodSpecies = this.referenceDataService.getWoodSpecies();
    const woodSpeciesRecordsWithWoodSpeciesSelected = ScoringUtil.getAllWoodSpeciesRecordsInDocument(eutrDocument)
      .filter(s => s.woodSpecies);

    if (!woodSpeciesRecordsWithWoodSpeciesSelected.length) {
      eutrDocument.scoring.supplyChainAnalysis.timberSpecies = undefined;
    } else {
      const forbidden: WoodSpecies[] = [...new Set(woodSpeciesRecordsWithWoodSpeciesSelected
        .map(s => woodSpecies.find(ws => ws.id === s.woodSpecies))
        .filter(s => s.legalInformation === LegalInformation.FORBIDDEN))];
      const alert: WoodSpecies[] = [...new Set(woodSpeciesRecordsWithWoodSpeciesSelected
        .map(s => woodSpecies.find(ws => ws.id === s.woodSpecies))
        .filter(s => s.legalInformation === LegalInformation.ALERT))];

      let remarks = forbidden.map(s => s.commonName).join(", ");
      let alertNames = alert.map(s => s.commonName).join(", ");
      if (alertNames) {
        if (remarks) {
          remarks = remarks + "\n";
        }
        remarks = remarks + "Alerts: " + alertNames;
      }

      eutrDocument.scoring.supplyChainAnalysis.timberSpecies = {
        significance: forbidden.length ? Significance.FORBIDDEN : Significance.ALLOWED,
        remarks: remarks
      };
    }
  }

  private recomputeChainComplete(formGroup: FormGroup, eutrDocument: EutrDocument) {
    if (eutrDocument.data.natureSpeciesOriginWood || this.isCompletelyRecycled(eutrDocument)) {
      eutrDocument.scoring.supplyChainAnalysis.chainComplete = undefined;
    } else {
      if (
        (eutrDocument.identification.productIdentification.swissOnly && !formGroup.get('data').get('majorSpeciesOfMassiveWood').valid)
        || (eutrDocument.data.woodComposition && !formGroup.get('data').get('woodComposition').valid)
      ) {
        eutrDocument.scoring.supplyChainAnalysis.chainComplete = {
          significance: Significance.NO,
          remarks: ScoringService.MISSING_INFORMATION
        };
      } else {
        eutrDocument.scoring.supplyChainAnalysis.chainComplete = {
          significance: Significance.YES,
        };
      }
    }
  }

  private recomputeLegalityDocuments(eutrDocument: EutrDocument) {
    if (ScoringWoodChainHelper.notEligibleForCountingFields(eutrDocument) || this.isCompletelyRecycled(eutrDocument)) {
      eutrDocument.scoring.supplyChainAnalysis.legalityDocuments = undefined;
      return;
    }

    let filledInRatio = ScoringWoodChainHelper.calcFilledInRatioForLegalityDocument(eutrDocument);
    if (filledInRatio > 0.5) {
      eutrDocument.scoring.supplyChainAnalysis.legalityDocuments = {
        score: 1000,
        significance: Significance.YES
      };
    } else if (filledInRatio >= 0.3 && filledInRatio <= 0.5) {
      eutrDocument.scoring.supplyChainAnalysis.legalityDocuments = {
        score: 500,
        significance: Significance.YES
      };
    } else {
      eutrDocument.scoring.supplyChainAnalysis.legalityDocuments = {
        score: 0,
        significance: Significance.NO
      };
    }

    let allDocsCompliant = ScoringWoodChainHelper.allDocumentsOfLegalityCertificationsAreCompliant(eutrDocument);
    if (allDocsCompliant) {
      eutrDocument.scoring.supplyChainAnalysis.legalityDocuments.score += 1000;
    }
  }

  private recomputeComplexityOfTheChain(eutrDocument: EutrDocument): void {
    if (ScoringWoodChainHelper.notEligibleForCountingFields(eutrDocument) || this.isCompletelyRecycled(eutrDocument)) {
      eutrDocument.scoring.supplyChainAnalysis.complexityOfTheChain = undefined;
      return;
    }

    const numberOfChainsScore = ScoringWoodChainHelper.getNumberOfChainsScore(eutrDocument);
    const majorCalculation = ScoringWoodChainHelper.getMajorCalculationScore(eutrDocument);
    eutrDocument.scoring.supplyChainAnalysis.complexityOfTheChain = !!numberOfChainsScore || !!majorCalculation
      ? {
        score: numberOfChainsScore + majorCalculation,
        significance: Significance.YES
      }
      : {
        score: 0,
        significance: Significance.NO
      };
  }

  private recomputeBrokenChainOfCustody(eutrDocument: EutrDocument) {
    let countriesOfHarvest = this.referenceDataService.getCountriesOfHarvest();
    if (ScoringWoodChainHelper.notEligibleForCountingFields(eutrDocument) || !ScoringWoodChainHelper.anyChainHasCertificateAndHighCpiCountry(eutrDocument, countriesOfHarvest)) {
      eutrDocument.scoring.supplyChainAnalysis.brokenChainOfCustody = undefined;
      return;
    }

    let totalScore = 0;
    let significance = Significance.NO;
    totalScore += ScoringService.certifiedChainsOfCustodyPartScore(eutrDocument, countriesOfHarvest);
    totalScore += ScoringWoodChainHelper.documentedChainOfCustodyScore(eutrDocument, countriesOfHarvest);
    if (ScoringWoodChainHelper.allCertificationDocumentsOfLegalityCertificationsAreCompliant(eutrDocument)) {
      totalScore += 1000;
      significance = Significance.YES;
    }
    eutrDocument.scoring.supplyChainAnalysis.brokenChainOfCustody = {
      significance: significance,
      score: totalScore
    };
  }

  private recomputeSislEthicalPolicy(eutrDocument: EutrDocument) {
    eutrDocument.scoring.supplyChainAnalysis.sislEthicalPolicy = eutrDocument.agreement.readAndAgreed
      ? {
        significance: Significance.YES,
        remarks: 'Signed'
      }
      : {
        significance: null,
        remarks: 'Not Signed'
      };
  }

  private static isSignificanceYesOrHigh(s: Significance): boolean {
    return s === Significance.YES || s === Significance.HIGH;
  }

  private static certifiedChainsOfCustodyPartScore(eutrDocument: EutrDocument, countriesOfHarvest: Country[]): number {
    const totalCertifiedChainsOfCustody = ScoringWoodChainHelper.calcNumberOfCertifiedChainsOfCustodies(eutrDocument, countriesOfHarvest);
    const totalChainsOfCustody = ScoringWoodChainHelper.numberOfLeavesInChainTree(eutrDocument.data.woodComposition.componentNodes);
    const ratio = totalCertifiedChainsOfCustody / totalChainsOfCustody;

    return ratio > 0.5 ? 1000 : ratio > 0 ? 500 : 0;
  }

  private isCompletelyRecycled(eutrDocument: EutrDocument): boolean {
    if (eutrDocument.data.natureSpeciesOriginWood) {
      return eutrDocument.data.natureSpeciesOriginWood.recycledWoodPercentage === 100;
    } else if (eutrDocument.data.productCompositionSwissOnly) {
      return eutrDocument.data.productCompositionSwissOnly.allMassiveWoodIsRecycled;
    } else if (eutrDocument.data.woodComposition) {
      let recycledPercentages = eutrDocument.data.woodComposition.componentNodes
        .flatMap(cn => ScoringUtil.getWoodCompositionNodes(cn))
        .map(wcn => wcn.recycledPercentage)
        .filter(p => p);

      return recycledPercentages.length && recycledPercentages.every(p => p === 100);
    } else {
      throw new Error("Expected to be in 1 of these scenario's");
    }
  }

  private calculateLabel(value: number, eutrDocument: EutrDocument): GlobalScoringLabel {
    const allWoodSpeciesRecordsInDocument = ScoringUtil.getAllWoodSpeciesRecordsInDocument(eutrDocument);
    const forbiddenSpeciesInDocument = allWoodSpeciesRecordsInDocument
      .map(wsr => wsr.woodSpecies)
      .filter(woodSpeciesId => woodSpeciesId)
      .map(woodSpeciesId => this.referenceDataService.resolveWoodSpecies(woodSpeciesId))
      .some(woodSpecies => woodSpecies.legalInformation === LegalInformation.FORBIDDEN);
    const forbiddenCountriesInDocument = allWoodSpeciesRecordsInDocument
      .map(wsr => wsr.countryOfHarvest)
      .filter(countryId => countryId)
      .map(countryId => this.referenceDataService.resolveCountryOfHarvest(countryId))
      .some(country => country.sanctionOnu || country.armedConflicts || country.cpiindex <= ScoringWoodChainHelper.LOW_CPI_THRESHOLD);

    // Bypass rules
    if (!eutrDocument.agreement.readAndAgreed) {
      this.byPassRulesApplicable = true;
      return GlobalScoringLabel.RED;
    }
    let missingInformation = Object.values(eutrDocument.scoring.countryAnalysis)
      .concat(Object.values(eutrDocument.scoring.supplyChainAnalysis))
      .some(value => value && value.remarks && value.remarks.includes(ScoringService.MISSING_INFORMATION));
    if (missingInformation) {
      this.byPassRulesApplicable = true;
      return GlobalScoringLabel.RED;
    } else if (forbiddenSpeciesInDocument) {
      this.byPassRulesApplicable = true;
      return GlobalScoringLabel.RED;
    } else if (eutrDocument.conclusion.originWoodEvaluation) {
      this.byPassRulesApplicable = true;
      return Object.values(eutrDocument.conclusion.originWoodEvaluation).every(val => val === OriginWoodLicenseValidityVerdict.COMPLIANT)
        ? GlobalScoringLabel.GREEN
        : GlobalScoringLabel.RED;
    } else if (forbiddenCountriesInDocument) {
      this.byPassRulesApplicable = true;
      return GlobalScoringLabel.RED;
    } else if (this.isCompletelyRecycled(eutrDocument)) {
      this.byPassRulesApplicable = true;
      return GlobalScoringLabel.GREEN;
    }
    this.byPassRulesApplicable = false;

    if (eutrDocument.identification.productIdentification.swissOnly) {
      if (2000 <= value) {
        return GlobalScoringLabel.GREEN;
      } else if (value === 0) {
        return GlobalScoringLabel.RED;
      } else {
        return GlobalScoringLabel.AMBER;
      }
    }

    if (eutrDocument.scoring.supplyChainAnalysis.certifications) {
      if (4500 <= value) {
        return GlobalScoringLabel.GREEN;
      } else if (value < 3000) {
        return GlobalScoringLabel.RED;
      } else {
        return GlobalScoringLabel.AMBER;
      }
    } else {
      if (5000 <= value) {
        return GlobalScoringLabel.GREEN;
      } else if (value < 3000) {
        return GlobalScoringLabel.RED;
      } else {
        return GlobalScoringLabel.AMBER;
      }
    }
  }

  private updateSanctionOnu(eutrDocument: EutrDocument, countriesOfHarvestIds: number[]) {
    if (!countriesOfHarvestIds.length) {
      eutrDocument.scoring.countryAnalysis.sanctionOnu = undefined;
      return;
    }
    const onuCountriesInDocument = this.referenceDataService.getOnuCountries()
      .filter(c => countriesOfHarvestIds.includes(c.value));
    eutrDocument.scoring.countryAnalysis.sanctionOnu = onuCountriesInDocument.length
      ? {
        significance: Significance.YES,
        score: 0,
        remarks: onuCountriesInDocument.map(c => c.label).join(", ")
      }
      : {
        significance: Significance.NO,
        score: 1000,
        remarks: ''
      };
  }

  private updateCpiRisk(eutrDocument: EutrDocument, countriesOfHarvestIds: number[]) {
    if (!countriesOfHarvestIds.length) {
      eutrDocument.scoring.countryAnalysis.cpiRisk = undefined;
      return;
    }
    const countriesOfHarvestInDocument = this.referenceDataService.getCountriesOfHarvest()
      .filter(c => countriesOfHarvestIds.includes(c.value));

    const highCountries = countriesOfHarvestInDocument.filter(c => c.cpiindex <= ScoringWoodChainHelper.LOW_CPI_THRESHOLD);
    const medialCountries = countriesOfHarvestInDocument.filter(c => c.cpiindex > ScoringWoodChainHelper.LOW_CPI_THRESHOLD && c.cpiindex <= 60);

    if (highCountries.length) {
      eutrDocument.scoring.countryAnalysis.cpiRisk = {
        score: 0,
        significance: Significance.HIGH,
        remarks: highCountries.map(c => c.label).join(", ")
      };
    } else if (medialCountries.length) {
      eutrDocument.scoring.countryAnalysis.cpiRisk = {
        score: 500,
        significance: Significance.MEDIAL,
        remarks: medialCountries.map(c => c.label).join(", ")
      };
    } else {
      eutrDocument.scoring.countryAnalysis.cpiRisk = {
        score: 1000,
        significance: Significance.LOW
      };
    }
  }

  private updateArmedConflict(eutrDocument: EutrDocument, countriesOfHarvestIds: number[]) {
    if (!countriesOfHarvestIds.length) {
      eutrDocument.scoring.countryAnalysis.armedConflict = undefined;
      return;
    }
    const armedConflictCountriesInDocument = this.referenceDataService.getArmedConflictCountries()
      .filter(c => countriesOfHarvestIds.includes(c.value));

    eutrDocument.scoring.countryAnalysis.armedConflict = armedConflictCountriesInDocument.length
      ? {
        score: 0,
        significance: Significance.YES,
        remarks: armedConflictCountriesInDocument.map(c => c.label).join(", ")
      }
      : {
        score: 1000,
        significance: Significance.NO,
      };
  }

  private propagateToGlobalScore(eutrDocument: EutrDocument) {
    let globalScore = [
      eutrDocument.scoring.countryAnalysis.sanctionOnu,
      eutrDocument.scoring.countryAnalysis.cpiRisk,
      eutrDocument.scoring.countryAnalysis.armedConflict,
      eutrDocument.scoring.supplyChainAnalysis.supplierLocatedInEurope,
      eutrDocument.scoring.supplyChainAnalysis.certifications,
      eutrDocument.scoring.supplyChainAnalysis.timberSpecies,
      eutrDocument.scoring.supplyChainAnalysis.chainComplete,
      eutrDocument.scoring.supplyChainAnalysis.legalityDocuments,
      eutrDocument.scoring.supplyChainAnalysis.complexityOfTheChain,
      eutrDocument.scoring.supplyChainAnalysis.brokenChainOfCustody,
      eutrDocument.scoring.supplyChainAnalysis.sislEthicalPolicy,
    ]
      .filter(r => r)
      .map(r => r.score)
      .filter(s => s)
      .reduce((a, b) => a + b, 0);

    eutrDocument.scoring.globalScoreLabel = this.calculateLabel(globalScore, eutrDocument);
    eutrDocument.scoring.globalScore = globalScore;
  }
}
