<div class="container">
  <div class="control-block">
    <div class="search-bar">
      <div class="ui-inputgroup">
        <button (click)="search()" *ngIf="!isSearching" icon="pi pi-search" pButton type="button"></button>
        <button (click)="search()" *ngIf="isSearching" icon="pi pi-spin pi-spinner" pButton type="button"></button>
        <input (keydown.enter)="search()" [(ngModel)]="searchTerm" [disabled]="isSearching" pInputText
               placeholder="Search on item code"
               type="text">

        <button label="Advanced options" *ngIf="!advancedSearching" (click)="toggleAdvancedSearching()"
                icon="pi pi-chevron-down" iconPos="right" pButton
                type="button"></button>
        <button label="Advanced options" *ngIf="advancedSearching" (click)="toggleAdvancedSearching()"
                icon="pi pi-chevron-up" iconPos="right" pButton
                type="button"></button>
      </div>
      <rb-advanced-options [advancedSearching]=advancedSearching
                           (applyFilterEvent)="applyAdvancedFilter($event)">
      </rb-advanced-options>
    </div>
  </div>

  <!--  TODO_JORIS extract component -->
  <table class="simple-table">
    <tr>
      <th>Brand/Subs.</th>
      <th>Code</th>
      <th>Name</th>
      <th>Colour</th>
      <th></th>
    </tr>
    <tr *ngFor="let item of items">
      <td>{{item.brand}}</td>
      <td>{{item.code}}</td>
      <td>{{item.name}}</td>
      <td>{{item.colour}}</td>
      <td>
        <p-button (onClick)="removeItem(item)"
                  icon="pi pi-trash"
                  class="black-icon-btn"></p-button>
      </td>
    </tr>
  </table>
  <div class="modal-actions">
    <p-button (onClick)="submit()" [disabled]="!items?.length" icon="pi pi-plus" label="Add items"></p-button>
  </div>
</div>
