import {Component, Input, OnInit} from '@angular/core';
import {EutrDocument} from "../../../shared/contracts";
import {FormService} from "../../../services/form.service";
import {EutrDocumentService} from "../../../services/eutr-document.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'rb-env-bar',
  templateUrl: './env-bar.component.html',
  styleUrls: ['./env-bar.component.scss']
})
export class EnvBarComponent implements OnInit {

  @Input() eutrDocument: EutrDocument;
  envName: string;
  isProd: boolean;

  constructor(public formService: FormService, public eutrDocumentService: EutrDocumentService) {
  }

  ngOnInit() {
    this.envName = environment.name;
    this.isProd = environment.production;
  }

}
