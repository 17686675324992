import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-repeatable-row',
  templateUrl: './repeatable-row.component.html',
  styleUrls: ['./repeatable-row.component.scss']
})
export class RepeatableRowComponent<T> {
  @Input() records: FormArray;
  @Input() newRecordSupplier: () => FormGroup = () => null;
  @ContentChild('header') headerTmpl: TemplateRef<any>;
  @ContentChild('record') recordTmpl: TemplateRef<any>;

  constructor() {
  }

  public getLengthOfRecords(): number {
    return this.records.length;
  }

  insertRow($event, index: number) {
    this.records.insert(index + 1, this.newRecordSupplier());
  }

  deleteRow($event, index: number) {
    (this.records.removeAt(index));
  }
}
