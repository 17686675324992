<div class="row-grid">
  <ng-container *ngTemplateOutlet="headerTmpl"></ng-container>
</div>
<div *ngFor="let record of this.records ? this.records['controls'] : []; let index = index;" class="row-grid">
  <div>
    <ng-container
      *ngTemplateOutlet="recordTmpl, context: { $implicit: record, index: index}"></ng-container>
  </div>
  <div style="display: flex; justify-content: flex-end;">
    <p-button type="button"
              class="black-icon-btn"
              icon="pi pi-plus-circle"
              [disabled]="record.disabled"
              (onClick)="insertRow($event, index)"></p-button>
    <p-button type="button"
              class="black-icon-btn"
              icon="pi pi-trash"
              (onClick)="deleteRow($event, index)"
              [disabled]="record.disabled || getLengthOfRecords() <= 1"></p-button>
  </div>
</div>
