import {Injectable} from '@angular/core';
import {RootResource} from "../shared/contracts";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

/**
 * https://github.com/angular/angular/issues/23279
 * https://medium.com/better-programming/how-to-handle-async-providers-in-angular-51884647366
 */
@Injectable({
  providedIn: 'root'
})
export class RootResourceService {
  rootResource: RootResource;

  constructor(private http: HttpClient) {
  }

  load(): Promise<RootResource> {
    return this.http.get<any>(environment.apiUrl)
      .toPromise()
      .then(res => this.rootResource = res);
  }
}
