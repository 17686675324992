<fieldset>
  <legend>Major species of massive wood</legend>
  <rb-repeatable-row [records]="records"
                     [newRecordSupplier]="formService.createMassiveWoodSpeciesRecord.bind(formService)">
    <ng-template #header let-header>
      <div class="species-grid">
        <span>Species</span>
        <div class="grid2x2">
          <span class="required">Scientific name</span>
          <span class="required">Common name</span>
        </div>
        <span class="required">Volume, Unit</span>
        <span class="required">Country of harvest</span>
      </div>
    </ng-template>
    <ng-template #record let-record let-index="index" let-records="records">
      <form [formGroup]="record">
        <div class="species-grid">
          <span>{{index + 1}}</span>
          <rb-wood-species class="grid2x2" formControlName="woodSpecies"></rb-wood-species>
          <rb-volume formControlName="volume"></rb-volume>
          <rb-dropdown formControlName="countryOfHarvest"
                       [options]="referenceDataService.getCountriesOfHarvest()"
                       styleClass="width-200px"
          ></rb-dropdown>
        </div>
      </form>
    </ng-template>
  </rb-repeatable-row>
</fieldset>
