import {Component, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {FormService} from "../../services/form.service";

@Component({
  selector: 'rb-error-marker',
  templateUrl: './error-marker.component.html',
  styleUrls: ['./error-marker.component.scss']
})
export class ErrorMarkerComponent {
  @Input() control: AbstractControl;

  constructor(public formService: FormService) {
  }

  public getMessages(): string {
    return this.control?.errors
      ? Object.keys(this.control.errors)
        .map(key => key === "required" ? "This field is required" : this.control.errors[key])
        .join("\n")
      : null;
  }
}
