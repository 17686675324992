import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {ContentsSectionDirective} from "../../util/contents/contents-section.directive";
import {ContentsDirective} from "../../util/contents/contents.directive";
import {FormArray, FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-repeatable-accordion-tab',
  templateUrl: './repeatable-accordion-tab.component.html',
  styleUrls: ['./repeatable-accordion-tab.component.scss'],
  viewProviders: [ContentsSectionDirective, ContentsDirective]
})
export class RepeatableAccordionTabComponent<T> {
  @Input() header: string;
  @Input() records: FormArray;
  @Input() newRecordSupplier: (obj) => FormGroup = () => null;
  @ContentChild('header') headerTmpl: TemplateRef<any>;
  @ContentChild('record') recordTmpl: TemplateRef<any>;

  constructor() {
  }

  public getLengthOfRecords(): number {
    return this.records.length;
  }

  insertRow(index: number) {
    this.records.insert(index + 1, this.newRecordSupplier(null));
  }

  deleteRowIfNotLast(index: number) {
    if ((this.records.length) > 1) {
      (this.records.removeAt(index));
    }
  }

  calculateSectionName(index) {
    return '' + this.header + (index);
  }
}
