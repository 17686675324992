import {Component, Input} from '@angular/core';
import {ContentsDirective} from "../../../util/contents/contents.directive";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-policy-agreement',
  templateUrl: './policy-agreement.component.html',
  styleUrls: ['./policy-agreement.component.scss'],
  viewProviders: [ContentsDirective]
})
export class PolicyAgreementComponent {
  @Input() form: FormGroup;

  constructor() {
  }
}
