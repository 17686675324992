import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {AuthGroup, Section, UserService} from "../services/user.service";
import {State} from "../shared/contracts";

@Directive({
  selector: '[rbHideIfUnauthorized]'
})
export class HideIfUnauthorizedDirective implements OnInit {

  @Input("rbHideIfUnauthorized") section: Section;
  @Input() state: State;

  constructor(private el: ElementRef, private userService: UserService, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    const permission = this.userService.getPermission(this.userService.getUser(), this.section, this.state);
    if (permission !== AuthGroup.READ && permission !== AuthGroup.WRITE) {
      this.renderer.addClass(this.el.nativeElement, "hide");
    }
  }
}
