import {Injectable} from '@angular/core';
import {SelectItem, SelectItemGroup} from "primeng/api";
import {Country, VolumeUnit, WoodSpecies} from "../shared/contracts";
import {HttpClient} from "@angular/common/http";
import {forkJoin, of} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {RootResourceService} from "./root-resource.service";

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {
  public initialized: boolean = false;
  private referenceData: any;
  private commonNameOptions: SelectItem[];
  private scientificNameOptions: SelectItem[];
  private certificationOptions: SelectItem[];
  private woodSpecies: WoodSpecies[];
  private countries: Country[];
  private onuCountries: Country[];
  private armedConflictCountries: Country[];
  private supplierTypes: SelectItem[];
  private componentTypes: SelectItemGroup[];
  private chainCertificationTypes: SelectItemGroup[];

  private manufacturerCertifications: SelectItem[];
  private manufacturerLegalityOfProcessing: SelectItem[];
  private manufacturerPaymentOfTaxesAndRoyalties: SelectItem[];

  private exporterLegalityOfSaleAndExport: SelectItem[];
  private exporterPaymentOfFeesAndRoyalties: SelectItem[];

  private forestCertification: SelectItem[];
  private forestLegalRightToHarvest: SelectItem[];
  private forestLegalityOfHarvest: SelectItem[];
  private forestPaymentOfTaxesAndRoyalties: SelectItem[];

  private originWoodLicenseValidityVerdictOptions: SelectItem[];
  private productCertificationCertificationValidityVerdictOptions: SelectItem[];
  private productCertificationCertificationScopeVerdictOptions: SelectItem[];
  private productCertificationInvoicesProvidedVerdictOptions: SelectItem[];
  private woodCompositionCertificationValidityVerdictOptions: SelectItem[];
  private woodCompositionCertificationScopeVerdictOptions: SelectItem[];
  private woodCompositionInvoicesVerdictOptions: SelectItem[];
  private woodCompositionConsistencyVerdictOptions: SelectItem[];
  private woodCompositionCorrectnessVerdictOptions: SelectItem[];

  constructor(private http: HttpClient, private rootResourceService: RootResourceService) {
    this.referenceData = {
      volumeTypes: [
        {label: 'mm3', value: VolumeUnit.MM3},
        {label: 'cm3', value: VolumeUnit.CM3},
        {label: 'm3', value: VolumeUnit.M3}
      ]
    };
  }

  async loadReferenceData() {
    return of(this.rootResourceService.rootResource)
      .pipe(mergeMap(root => forkJoin([
        (this.http.get<any>(root._links.certifications.href)),
        (this.http.get<any>(root._links.woodSpecies.href)),
        (this.http.get<any>(root._links.countries.href)),
        (this.http.get<any>(root._links.componentTypes.href)),
        (this.http.get<any>(root._links.manufacturerCertificationOptions.href)),
        (this.http.get<any>(root._links.manufacturerLegalityOfProcessingOptions.href)),
        (this.http.get<any>(root._links.manufacturerPaymentOfTaxesAndRoyaltiesOptions.href)),
        (this.http.get<any>(root._links.exporterLegalityOfSaleAndExportOptions.href)),
        (this.http.get<any>(root._links.exporterPaymentOfFeesAndRoyaltiesOptions.href)),
        (this.http.get<any>(root._links.forestCertificationOptions.href)),
        (this.http.get<any>(root._links.forestLegalRightToHarvestOptions.href)),
        (this.http.get<any>(root._links.forestLegalityOfHarvestOptions.href)),
        (this.http.get<any>(root._links.forestPaymentOfTaxesAndRoyaltiesOptions.href)),
        // TODO_JORIS cleanup order
        (this.http.get<any>(root._links.supplierTypes.href)),
        (this.http.get<any>(root._links.chainCertificationTypes.href)),
        (this.http.get<any>(root._links.verdictOptions.href)),
      ])))
      .toPromise()
      .then(responses => {
          this.certificationOptions = responses[0]._embedded.certifications;
          this.woodSpecies = responses[1]._embedded.woodSpecies;
          this.countries = responses[2]._embedded.countries;
          this.componentTypes = this.toSelectItemGroups(
            responses[3]._embedded.componentTypes,
            'category'
          );
          this.manufacturerCertifications = responses[4]._embedded.manufacturerCertificationOptions;
          this.manufacturerLegalityOfProcessing = responses[5]._embedded.manufacturerLegalityOfProcessingOptions;
          this.manufacturerPaymentOfTaxesAndRoyalties = responses[6]._embedded.manufacturerPaymentOfTaxesAndRoyaltiesOptions;
          this.exporterLegalityOfSaleAndExport = responses[7]._embedded.exporterLegalityOfSaleAndExportOptions;
          this.exporterPaymentOfFeesAndRoyalties = responses[8]._embedded.exporterPaymentOfFeesAndRoyaltiesOptions;
          this.forestCertification = responses[9]._embedded.forestCertificationOptions;
          this.forestLegalRightToHarvest = responses[10]._embedded.forestLegalRightToHarvestOptions;
          this.forestLegalityOfHarvest = responses[11]._embedded.forestLegalityOfHarvestOptions;
          this.forestPaymentOfTaxesAndRoyalties = responses[12]._embedded.forestPaymentOfTaxesAndRoyaltiesOptions;
          this.supplierTypes = responses[13]._embedded.supplierTypes;
          this.chainCertificationTypes = this.toSelectItemGroups(
            responses[14]._embedded.chainCertificationTypes,
            'category'
          );
          let verdicts = responses[15]._embedded;
          this.originWoodLicenseValidityVerdictOptions = verdicts.originWoodLicenseValidityVerdicts;
          this.productCertificationCertificationValidityVerdictOptions = verdicts.productCertificationCertificationValidityVerdicts;
          this.productCertificationCertificationScopeVerdictOptions = verdicts.productCertificationCertificationScopeVerdicts;
          this.productCertificationInvoicesProvidedVerdictOptions = verdicts.productCertificationInvoicesProvidedVerdicts;
          this.woodCompositionCertificationValidityVerdictOptions = verdicts.woodCompositionCertificationValidityVerdicts;
          this.woodCompositionCertificationScopeVerdictOptions = verdicts.woodCompositionCertificationScopeVerdicts;
          this.woodCompositionInvoicesVerdictOptions = verdicts.woodCompositionInvoicesVerdicts;
          this.woodCompositionConsistencyVerdictOptions = verdicts.woodCompositionConsistencyVerdicts;
          this.woodCompositionCorrectnessVerdictOptions = verdicts.woodCompositionCorrectnessVerdicts;
          this.initialized = true;
        }
      );
  }

  // js magic due to lack of decent std lib
  toSelectItemGroups(input: any[], key: string): SelectItemGroup[] {
    return Object.values(input.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || {label: x[key], items: []}).items.push(x);
      return rv;
    }, {}));
  }

  getVolumeTypes(): SelectItem[] {
    return this.referenceData.volumeTypes;
  }

  getCertificationOptions(): SelectItem[] {
    return this.certificationOptions;
  }

  getCountriesOfHarvest(): Country[] {
    return this.countries;
  }

  getOnuCountries(): Country[] {
    if (!this.onuCountries) {
      this.onuCountries = this.getCountriesOfHarvest().filter(c => c.sanctionOnu);
    }
    return this.onuCountries;
  }

  getArmedConflictCountries(): Country[] {
    if (!this.armedConflictCountries) {
      this.armedConflictCountries = this.getCountriesOfHarvest().filter(c => c.armedConflicts);
    }
    return this.armedConflictCountries;
  }

  getSupplierTypes(): SelectItem[] {
    return this.supplierTypes;
  }

  getWoodSpecies(): WoodSpecies[] {
    return this.woodSpecies;
  }

  getScientificNameOptions(): SelectItem[] {
    if (this.scientificNameOptions == null) {
      this.scientificNameOptions = this.getWoodSpecies()
        .map(x => x.scientificName)
        .sort()
        .map(scientificName => ({
          label: scientificName,
          value: scientificName
        }));
    }
    return this.scientificNameOptions;
  }

  getCommonNameOptions(): SelectItem[] {
    if (this.commonNameOptions == null) {
      this.commonNameOptions = this.removeDuplicatesByProperty(
        this.getWoodSpecies()
          .map(x => x.commonName)
          .sort()
          .map(commonName => ({
            label: commonName,
            value: commonName
          })),
        'label'
      );
    }
    return this.commonNameOptions;
  }

  removeDuplicatesByProperty(array, property) {
    return array.filter((obj, pos, arr) => arr
      .map(mapObj => mapObj[property])
      .indexOf(obj[property]) === pos);
  }

  getComponentTypes(): SelectItemGroup[] {
    return this.componentTypes;
  }

  getManufacturerCertifications(): SelectItem[] {
    return this.manufacturerCertifications;
  }

  getLegalityOfProcessing(): SelectItem[] {
    return this.manufacturerLegalityOfProcessing;
  }

  getManufacturerPaymentOfTaxesAndRoyalties(): SelectItem[] {
    return this.manufacturerPaymentOfTaxesAndRoyalties;
  }

  getExporterLegalityOfSaleAndExport(): SelectItem[] {
    return this.exporterLegalityOfSaleAndExport;
  }

  getExporterPaymentOfFeesAndRoyalties(): SelectItem[] {
    return this.exporterPaymentOfFeesAndRoyalties;
  }

  // TODO_JORIS merge all countries?
  getChainPartsCountries(): SelectItem[] {
    return this.countries;
  }

  getChainPartsCertification(): SelectItemGroup[] {
    return this.chainCertificationTypes;
  }

  getForestCertification(): SelectItem[] {
    return this.forestCertification;
  }

  getForestLegalRightToHarvest(): SelectItem[] {
    return this.forestLegalRightToHarvest;
  }

  getForestLegalityOfHarvest(): SelectItem[] {
    return this.forestLegalityOfHarvest;
  }

  getForestPaymentOfTaxesAndRoyalties(): SelectItem[] {
    return this.forestPaymentOfTaxesAndRoyalties;
  }

  getOriginWoodLicenseValidityVerdictOptions(): SelectItem[] {
    return this.originWoodLicenseValidityVerdictOptions;
  }

  getProductCertificationCertificationValidityOptions(): SelectItem[] {
    return this.productCertificationCertificationValidityVerdictOptions;
  }

  getProductCertificationCertificationScopeOptions(): SelectItem[] {
    return this.productCertificationCertificationScopeVerdictOptions;
  }

  getProductCertificationInvoicesProvidedOptions(): SelectItem[] {
    return this.productCertificationInvoicesProvidedVerdictOptions;
  }

  getWoodCompositionCertificationValidityOptions(): SelectItem[] {
    return this.woodCompositionCertificationValidityVerdictOptions;
  }

  getWoodCompositionCertificationScopeOptions(): SelectItem[] {
    return this.woodCompositionCertificationScopeVerdictOptions;
  }

  getWoodCompositionInvoicesOptions(): SelectItem[] {
    return this.woodCompositionInvoicesVerdictOptions;
  }

  getWoodCompositionConsistencyOptions(): SelectItem[] {
    return this.woodCompositionConsistencyVerdictOptions;
  }

  getWoodCompositionCorrectnessOptions(): SelectItem[] {
    return this.woodCompositionCorrectnessVerdictOptions;
  }

  resolveComponentType(id: number): string {
    return id
      ? this.getComponentTypes()
        .flatMap(x => x.items)
        .find(item => item.value === id)
        .label
      : null;
  }

  resolveWoodSpecies(id: number): WoodSpecies {
    return id
      ? this.getWoodSpecies()
        .find(species => species.id === id)
      : null;
  }

  resolveCountryOfHarvest(id: number): Country {
    return id
      ? this.getCountriesOfHarvest()
        .find(country => country.value === id)
      : null;
  }

  getHighCPIRiskCountries(): number[] {
    return this.countries.filter(c => c.cpiindex < 30).map(c => c.value);
  }
}
