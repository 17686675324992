<p-dropdown
  #dropdown
  class="stateSelector"
  [options]="stateDropdownOptions"
  [ngModel]="eutrDocument.state"
  (onChange)="dropdownValueChanged()"
  [disabled]="formService.saving || pendingUploads()"
  [title]="pendingUploads() ? 'Please wait until all files are uploaded!' : ''"
></p-dropdown>
<p-confirmDialog
  header="Error"
  key="validationError"
  acceptLabel="Ok"
  [rejectVisible]="false"
></p-confirmDialog>
