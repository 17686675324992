<p-accordionTab selected=true header="Bypass FLEGT / CITES / EU">
  <form [formGroup]="getOriginWoodEvaluationFormGroup()" class="outer-content">
    <p-accordion [multiple]="true">
      <ng-container *ngIf="getOriginWoodEvaluationFormGroup().controls.supplierLocatedInEurope">
        <p-accordionTab selected=true>
          <p-header>
            <div>
              <span>Supplier located in EU</span>
              <div (click)="$event.stopPropagation();">
                <p-button type="button"
                          class="black-icon-btn"
                          icon="pi pi-thumbs-up"
                          [disabled]="getOriginWoodEvaluationFormGroup().disabled"
                          (onClick)="makeSupplierLocatedInEuCompliant()"></p-button>
              </div>
            </div>
            <span *ngIf="isSupplierLocatedInEuCompliant()" class="compliant">Compliant</span>
            <span *ngIf="!isSupplierLocatedInEuCompliant()" class="not-compliant">Not Compliant</span>
          </p-header>
          <div class="verdicts">
            <label for="license-supplier-located-in-europe">License Validity</label>
            <p-dropdown inputId="license-supplier-located-in-europe"
                        formControlName="supplierLocatedInEurope"
                        [options]="referenceDataService.getOriginWoodLicenseValidityVerdictOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </div>
        </p-accordionTab>
      </ng-container>
      <ng-container *ngIf="getOriginWoodEvaluationFormGroup().controls.flegtLicense">
        <p-accordionTab selected=true>
          <p-header>
            <div>
              <span>FLEGT License</span>
              <div (click)="$event.stopPropagation();" style="display: flex">
                <a href="{{getFlegtLicenseDocument()?._links.download.href}}" download style="padding: 0">
                  <p-button type="button"
                            class="black-icon-btn"
                            icon="pi pi-file"></p-button>
                </a>
                <p-button type="button"
                          class="black-icon-btn"
                          icon="pi pi-thumbs-up"
                          [disabled]="getOriginWoodEvaluationFormGroup().disabled"
                          (onClick)="makeFlegtLicenseCompliant()"></p-button>
              </div>
            </div>
            <span *ngIf="isFlegtLicenseCompliant()" class="compliant">Compliant</span>
            <span *ngIf="!isFlegtLicenseCompliant()" class="not-compliant">Not Compliant</span>
          </p-header>
          <div class="verdicts">
            <label for="license-flegt-license">License Validity</label>
            <p-dropdown inputId="license-flegt-license"
                        formControlName="flegtLicense"
                        [options]="referenceDataService.getOriginWoodLicenseValidityVerdictOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </div>
        </p-accordionTab>
      </ng-container>
      <ng-container *ngIf="getOriginWoodEvaluationFormGroup().controls.citesLicense">
        <p-accordionTab selected=true>
          <p-header>
            <div>
              <span>CITES License</span>
              <div (click)="$event.stopPropagation();" style="display: flex">
                <a href="{{getCitesLicenseDocument()?._links.download.href}}" download style="padding: 0">
                  <p-button type="button"
                            class="black-icon-btn"
                            icon="pi pi-file"></p-button>
                </a>
                <p-button type="button"
                          class="black-icon-btn"
                          icon="pi pi-thumbs-up"
                          [disabled]="getOriginWoodEvaluationFormGroup().disabled"
                          (onClick)="makeCitesLicenseCompliant()"></p-button>
              </div>
            </div>
            <span *ngIf="isCitesLicenseCompliant()" class="compliant">Compliant</span>
            <span *ngIf="!isCitesLicenseCompliant()" class="not-compliant">Not Compliant</span>
          </p-header>
          <div class="verdicts">
            <label for="license-cites-license">License Validity</label>
            <p-dropdown inputId="license-cites-license"
                        formControlName="citesLicense"
                        [options]="referenceDataService.getOriginWoodLicenseValidityVerdictOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </div>
        </p-accordionTab>
      </ng-container>
      <ng-container *ngIf="getOriginWoodEvaluationFormGroup().controls.fromIndonesia">
        <p-accordionTab selected=true>
          <p-header>
            <div>
              <span>Timber from Indonesia</span>
              <div (click)="$event.stopPropagation();" style="display: flex">
                <a href="{{getSvlkDocument()?._links.download.href}}" download style="padding: 0">
                  <p-button type="button"
                            class="black-icon-btn"
                            icon="pi pi-file"></p-button>
                </a>
                <p-button type="button"
                          class="black-icon-btn"
                          icon="pi pi-thumbs-up"
                          [disabled]="getOriginWoodEvaluationFormGroup().disabled"
                          (onClick)="makeFromIndonesiaCompliant()"></p-button>
              </div>
            </div>
            <span *ngIf="isFromIndonesiaCompliant()" class="compliant">Compliant</span>
            <span *ngIf="!isFromIndonesiaCompliant()" class="not-compliant">Not Compliant</span>
          </p-header>
          <div class="verdicts">
            <label for="license-timber-from-indonesia">License Validity</label>
            <p-dropdown inputId="license-timber-from-indonesia"
                        formControlName="fromIndonesia"
                        [options]="referenceDataService.getOriginWoodLicenseValidityVerdictOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </div>
        </p-accordionTab>
      </ng-container>
    </p-accordion>
  </form>
</p-accordionTab>
