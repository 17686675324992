import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../../services/reference-data.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-conclusion',
  templateUrl: './conclusion.component.html',
  styleUrls: ['conclusion.component.scss', './../data/data.component.scss']
})
export class ConclusionComponent {
  @Input() form: FormGroup;

  constructor(public referenceDataService: ReferenceDataService) {
  }
}
