import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../../../../../services/reference-data.service";
import {UidService} from "../../../../../../services/uid.service";
import {FormGroup} from "@angular/forms";
import {FormService} from "../../../../../../services/form.service";

@Component({
  selector: 'rb-manufacturers-data',
  templateUrl: './manufacturers-data.component.html',
  styleUrls: ['./manufacturers-data.component.scss']
})
export class ManufacturersDataComponent {
  @Input() data: FormGroup;
  uid: number;

  constructor(
    public referenceDataService: ReferenceDataService,
    private uidService: UidService,
    public formService: FormService
  ) {
    this.uid = uidService.generateUid();
  }
}
