<p-accordionTab selected=true header="Documents / Evidences of Legality / Intermediates Certifications">
<!--  TODO_JORIS: hoeft wss helemaal geen form te zijn-->
  <form class="outer-content">
    <p-accordion [multiple]="true">
      <p-accordionTab selected=true
                      *ngFor="let row of getWoodCompositionDocumentEvaluationsFormArray().controls; let index = index;">
        <p-header>
          <div>
            <span>{{getDocumentByIndex(index).fileName}}</span>
            <div (click)="$event.stopPropagation();" style="display: flex">
              <a href="{{getDocumentByIndex(index)._links.download.href}}" download style="padding: 0">
                <p-button type="button"
                          class="black-icon-btn"
                          icon="pi pi-file"></p-button>
              </a>
              <p-button type="button"
                        class="black-icon-btn"
                        icon="pi pi-thumbs-up"
                        [disabled]="getWoodCompositionEvaluationFormGroup().disabled"
                        (onClick)="makeDocumentCompliant(index)"></p-button>
            </div>
          </div>
          <span *ngIf="isDocumentCompliant(index)" class="compliant">Compliant</span>
          <span *ngIf="!isDocumentCompliant(index)" class="not-compliant">Not Compliant</span>
        </p-header>
        <div class="verdicts" [formGroup]="getFormGroupForDocumentByIndex(index)">
          <ng-container *ngIf="getFormGroupForDocumentByIndex(index).controls.certificationValidity">
            <label for="wood-composition-certification-validity-verdict-{{index}}">Certification Validity</label>
            <p-dropdown inputId="wood-composition-certification-validity-verdict-{{index}}"
                        formControlName="certificationValidity"
                        [options]="referenceDataService.getWoodCompositionCertificationValidityOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </ng-container>
          <ng-container *ngIf="getFormGroupForDocumentByIndex(index).controls.certificationScope">
            <label for="wood-composition-certification-scope-verdict-{{index}}">Certification Scope</label>
            <p-dropdown inputId="wood-composition-certification-scope-verdict-{{index}}"
                        formControlName="certificationScope"
                        [options]="referenceDataService.getWoodCompositionCertificationScopeOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </ng-container>
          <ng-container *ngIf="getFormGroupForDocumentByIndex(index).controls.invoices">
            <label for="wood-composition-invoices-verdict-{{index}}">Sells Contract/Invoices</label>
            <p-dropdown inputId="wood-composition-invoices-verdict-{{index}}"
                        formControlName="invoices"
                        [options]="referenceDataService.getWoodCompositionInvoicesOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </ng-container>
          <ng-container *ngIf="getFormGroupForDocumentByIndex(index).controls.consistency">
            <label for="wood-composition-consistency-verdict-{{index}}">Consistency/Compatibility of Certifications</label>
            <p-dropdown inputId="wood-composition-consistency-verdict-{{index}}"
                        formControlName="consistency"
                        [options]="referenceDataService.getWoodCompositionConsistencyOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </ng-container>
          <ng-container *ngIf="getFormGroupForDocumentByIndex(index).controls.correctness">
            <label for="wood-composition-correctness-verdict-{{index}}">Incorrect Documents</label>
            <p-dropdown inputId="wood-composition-correctness-verdict-{{index}}"
                        formControlName="correctness"
                        [options]="referenceDataService.getWoodCompositionCorrectnessOptions()"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        styleClass="width-600px"></p-dropdown>
          </ng-container>
        </div>
      </p-accordionTab>
    </p-accordion>
  </form>
</p-accordionTab>
