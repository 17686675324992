import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {EutrPageComponent} from "./eutr-page/eutr-page.component";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<EutrPageComponent> {
  canDeactivate(component: EutrPageComponent): boolean {

    if(component.form.dirty){
      return confirm("You have unsaved changes! If you continue, your changes will be lost.");
    }
    return true;
  }
}
