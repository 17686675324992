import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Component({
  selector: 'rb-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements ControlValueAccessor {

  @Input() inputId: string;
  @Input() options;
  @Input() styleClass;
  @Input() autoDisplayFirst: boolean;
  @Input() group: boolean;
  @Input() onChangeFunction;

  constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  public valueChanged(val: string) {
    if (this.onChangeFunction) {
      this.onChangeFunction(val);
    }
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {

  }

  registerOnTouched(fn: any): void {

  }

  writeValue(obj: any): void {
  }

}
