import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ScoringService} from "../../../services/scoring.service";
import {GlobalScoringLabel, Scoring} from "../../../shared/contracts";

@Component({
  selector: 'rb-scoring',
  templateUrl: './scoring.component.html',
  styleUrls: ['./scoring.component.scss']
})
export class ScoringComponent implements OnChanges {
  @Input() scoring: Scoring;
  // expose enum to html
  GlobalScoringLabel = GlobalScoringLabel;

  constructor(public scoringService: ScoringService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.scoring = changes.scoring.currentValue as Scoring;
  }
}
