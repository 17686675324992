import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ChainService {
  assemblySiteNodeSummaries = this.http.get<any>(`${environment.apiUrl}assemblySiteNodes/search/findAssemblySiteNodesOfAllFulfilledEutrDocuments?&projection=summary`);
  joineryWorkshopNodeSummaries = this.http.get<any>(`${environment.apiUrl}joineryWorkshopNodes/search/findJoineryWorkshopNodesOfAllFulfilledEutrDocuments?&projection=summary`);
  sawmillNodeSummaries = this.http.get<any>(`${environment.apiUrl}sawmillNodes/search/findSawmillNodesOfAllFulfilledEutrDocuments?&projection=summary`);
  forestryConcessionNodeSummaries = this.http.get<any>(`${environment.apiUrl}forestryConcessionNodes/search/findForestryConcessionNodesOfAllFulfilledEutrDocuments?&projection=summary`);

  constructor(private http: HttpClient) {
  }
}
