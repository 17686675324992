import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';

@Component({
  selector: 'rb-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.scss'],
})
export class PercentageComponent implements ControlValueAccessor {
  // TODO_JORIS cleanup
  @Output() onPercentageChange: EventEmitter<any> = new EventEmitter();
  @Input() inputId;

  constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
  }
}
