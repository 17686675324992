import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../../../../services/reference-data.service";
import {FormGroup} from "@angular/forms";
import {FormService} from "../../../../../services/form.service";

@Component({
  selector: 'rb-wood-composition',
  templateUrl: './wood-composition.component.html',
  styleUrls: ['./wood-composition.component.scss']
})
export class WoodCompositionComponent {
  @Input() form: FormGroup;

  constructor(public referenceDataService: ReferenceDataService, public formService: FormService) {
  }

  computeHeader() {
    return "Component";
  }

  computeHeaderWithIndex(index) {
    return "Component" + index;
  }
}
