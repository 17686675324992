<fieldset>
  <legend>Product identification:</legend>
  <div>
    <p-checkbox label="Product sold to CH only" binary="true"
                disabled
                [(ngModel)]="eutrDocument.identification.productIdentification.swissOnly"></p-checkbox>
  </div>
  <br/>
  <div class="grid-two-cols">
    <span>Supplier Model Name:</span>
    <span>{{eutrDocument?.identification?.productIdentification?.supplierModelName}}</span>
    <span>Product Family:</span>
    <span>{{eutrDocument?.identification?.productIdentification?.productFamily}}</span>
    <span>Product Name:</span>
    <span>{{eutrDocument?.identification?.productIdentification?.productName}}</span>
  </div>
  <br/>
  <table class="simple-table">
    <tr>
      <th>Brand/Subs.</th>
      <th>Code</th>
      <th>Name</th>
      <th>Colour</th>
      <th>
        <p-button (onClick)="addItem()" icon="pi pi-plus" label="Add Item" styleClass="ui-button-secondary"></p-button>
      </th>
    </tr>
    <tr *ngFor="let item of eutrDocument?.identification?.productIdentification?.items">
      <td>{{item.brand}}</td>
      <td>{{item.code}}</td>
      <td>{{item.name}}</td>
      <td>{{item.colour}}</td>
      <td>
        <p-button (onClick)="removeItem(item)"
                  icon="pi pi-trash"
                  class="black-icon-btn"></p-button>
      </td>
    </tr>
  </table>
</fieldset>
