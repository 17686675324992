import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, ValidationErrors} from "@angular/forms";
import {DateUtil} from "./date-util";

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  constructor() {
  }

  public IFTTTValidator(group: FormGroup, a: string, b: string, msg: string) {
    if (!group.get(a) || !group.get(a).value) {
      return null;
    } else {
      if (group.get(b) && group.get(b).value !== null && group.get(b).value !== false && group.get(b).value !== "") {
        return null;
      } else {
        return {"error": msg};
      }
    }
  }

  public conditionalDateShouldBeAfterToday(group: FormGroup, a: string, b: string) {
    if (!group.get(a) || !group.get(a).value) {
      return null;
    } else {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      if (group.get(b) && group.get(b).value !== null && group.get(b).value >= today) {
        return null;
      } else {
        return {"error": "The expiration date is already expired"};
      }
    }
  }

  public distributeError(error: ValidationErrors, target: FormControl) {
    if (error) {
      target.setErrors(null);
      target.setErrors(error);
    } else {
      target.setErrors(null);
    }
  }

  public originWoodValidator(group: FormGroup): ValidationErrors | null {
    this.distributeError(this.IFTTTValidator(group, "supplierLocatedInEurope", "supplierType", "Please specify if the supplier is an operator or trader"), (group.get('supplierType') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "flegtLicense", "flegtDocument", "Please drop/upload a document for the FLEGT license"), (group.get('flegtDocument') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "flegtDocument", "flegtLicense", "Please check this checkbox"), (group.get('flegtLicense') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "citesLicense", "citesDocument", "Please drop/upload a document for the CITES license"), (group.get('citesDocument') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "citesDocument", "citesLicense", "Please check this checkbox"), (group.get('citesLicense') as FormControl));
    this.distributeError(this.IFTTTValidator(group, 'fromIndonesia', "svlkDocument", "Please enter the SVLK N°"), (group.get('svlkDocument') as FormControl));
    this.distributeError(this.IFTTTValidator(group, 'svlkDocument', "fromIndonesia", "Please check this checkbox"), (group.get('fromIndonesia') as FormControl));
    return null;
  }

  public productCertificationValidator(productCertificationFormGroup: FormGroup): ValidationErrors | null {
    if (Object.values(productCertificationFormGroup.value).filter(value => !!value).length) {
      productCertificationFormGroup.get('certification').setErrors(productCertificationFormGroup.get('certification').value ? null : {"error": "This field is required"});
      productCertificationFormGroup.get('cocCode').setErrors(productCertificationFormGroup.get('cocCode').value ? null : {"error": "This field is required"});
      productCertificationFormGroup.get('certificationTypeAndPercentage').setErrors(productCertificationFormGroup.get('certificationTypeAndPercentage').value ? null : {"error": "This field is required"});
      productCertificationFormGroup.get('certificate').setErrors(productCertificationFormGroup.get('certificate').value ? null : {"error": "This field is required"});
      productCertificationFormGroup.get('certificateDocument').setErrors(productCertificationFormGroup.get('certificateDocument').value ? null : {"error": "This field is required"});
      productCertificationFormGroup.get('certificateDate').setErrors(productCertificationFormGroup.get('certificateDate').value ? null : {"error": "This field is required"});
      productCertificationFormGroup.get('invoice').setErrors(productCertificationFormGroup.get('invoice').value ? null : {"error": "This field is required"});
      productCertificationFormGroup.get('invoiceDocument').setErrors(productCertificationFormGroup.get('invoiceDocument').value ? null : {"error": "This field is required"});
      productCertificationFormGroup.get('invoiceDate').setErrors(productCertificationFormGroup.get('invoiceDate').value ? null : {"error": "This field is required"});

      // TODO_JORIS extract
      if (productCertificationFormGroup.get('certificateDate').value && productCertificationFormGroup.get('certificateDate').value < DateUtil.getTodayAsUtcDate()) {
        productCertificationFormGroup.get('certificateDate').setErrors({"error": "Date must not be in the past"});
      }
      if (productCertificationFormGroup.get('invoiceDate').value && productCertificationFormGroup.get('invoiceDate').value < DateUtil.getTodayAsUtcDate()) {
        productCertificationFormGroup.get('invoiceDate').setErrors({"error": "Date must not be in the past"});
      }
    } else {
      Object.values(productCertificationFormGroup.controls).forEach(control => control.setErrors(null));
    }

    return null;
  }

  public manufacturerCertificationRecordValidator(group: FormGroup): ValidationErrors | null {
    this.distributeError(this.IFTTTValidator(group, "certification", "document", "Please drop/upload the document"), (group.get('document') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "certification", "date", "Please add a validity date"), (group.get('date') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "document", "certification", "Please select a certification"), (group.get('certification') as FormControl));
    this.distributeError(this.conditionalDateShouldBeAfterToday(group, "certification", "date"), (group.get('date') as FormControl));
    return null;
  }

  public manufacturerLegalityOfProcessingRecordValidator(group: FormGroup): ValidationErrors | null {
    this.distributeError(this.IFTTTValidator(group, "legalityOfProcessing", "document", "Please drop/upload the document"), (group.get('document') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "legalityOfProcessing", "date", "Please add a validity date"), (group.get('date') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "document", "legalityOfProcessing", "Please select the legality of processing"), (group.get('legalityOfProcessing') as FormControl));
    this.distributeError(this.conditionalDateShouldBeAfterToday(group, "legalityOfProcessing", "date"), (group.get('date') as FormControl));
    return null;
  }

  public manufacturerPaymentOfTaxesAndRoyaltiesRecordValidator(group: FormGroup): ValidationErrors | null {
    this.distributeError(this.IFTTTValidator(group, "paymentOfTaxesAndRoyalties", "document", "Please drop/upload the document"), (group.get('document') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "paymentOfTaxesAndRoyalties", "date", "Please add a validity date"), (group.get('date') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "document", "paymentOfTaxesAndRoyalties", "Please select the payment of taxes and royalties"), (group.get('paymentOfTaxesAndRoyalties') as FormControl));
    this.distributeError(this.conditionalDateShouldBeAfterToday(group, "paymentOfTaxesAndRoyalties", "date"), (group.get('date') as FormControl));
    return null;
  }

  public exporterPaymentOfFeesAndRoyaltiesRecordValidator(group: FormGroup): ValidationErrors | null {
    this.distributeError(this.IFTTTValidator(group, "paymentOfFeesAndRoyalties", "document", "Please drop/upload the document"), (group.get('document') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "paymentOfFeesAndRoyalties", "date", "Please add a validity date"), (group.get('date') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "document", "paymentOfFeesAndRoyalties", "Please select the payment of fees and royalties"), (group.get('paymentOfFeesAndRoyalties') as FormControl));
    this.distributeError(this.conditionalDateShouldBeAfterToday(group, "paymentOfFeesAndRoyalties", "date"), (group.get('date') as FormControl));
    return null;
  }

  public forestCertificationRecordValidator(group: FormGroup): ValidationErrors | null {
    this.distributeError(this.IFTTTValidator(group, "certification", "document", "Please drop/upload the document"), (group.get('document') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "certification", "date", "Please add a validity date"), (group.get('date') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "document", "certification", "Please select the certification"), (group.get('certification') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "document", "date", "Please add a validity date"), (group.get('date') as FormControl));
    this.distributeError(this.conditionalDateShouldBeAfterToday(group, "certification", "date"), (group.get('date') as FormControl));
    return null;
  }

  public forestLegalRightToHarvestRecordValidator(group: FormGroup): ValidationErrors | null {
    this.distributeError(this.IFTTTValidator(group, "legalRightToHarvest", "document", "Please drop/upload the document"), (group.get('document') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "legalRightToHarvest", "date", "Please add a validity date"), (group.get('date') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "document", "legalRightToHarvest", "Please select the legal right to harvest"), (group.get('legalRightToHarvest') as FormControl));
    this.distributeError(this.conditionalDateShouldBeAfterToday(group, "legalRightToHarvest", "date"), (group.get('date') as FormControl));
    return null;
  }

  public forestPaymentOfTaxesAndRoyaltiesRecordValidator(group: FormGroup): ValidationErrors | null {
    this.distributeError(this.IFTTTValidator(group, "paymentOfTaxesAndRoyalties", "document", "Please drop/upload the document"), (group.get('document') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "paymentOfTaxesAndRoyalties", "date", "Please add a validity date"), (group.get('date') as FormControl));
    this.distributeError(this.IFTTTValidator(group, "document", "paymentOfTaxesAndRoyalties", "Please select the payment of taxes and royalties"), (group.get('paymentOfTaxesAndRoyalties') as FormControl));
    this.distributeError(this.conditionalDateShouldBeAfterToday(group, "paymentOfTaxesAndRoyalties", "date"), (group.get('date') as FormControl));
    return null;
  }

  public massiveWoodSpeciesRecordValidator(woodSpeciesRecordFormGroup: FormGroup): ValidationErrors | null {
    woodSpeciesRecordFormGroup.get('woodSpecies').setErrors(
      woodSpeciesRecordFormGroup.get('woodSpecies').value != null
        ? null
        : {"error": "Please fill out the entire row!"}
    );
    woodSpeciesRecordFormGroup.get('volume').setErrors(
      woodSpeciesRecordFormGroup.get('volume').value.amount != null
        ? null
        : {"error": "Please fill out the entire row!"}
    );
    woodSpeciesRecordFormGroup.get('countryOfHarvest').setErrors(
      woodSpeciesRecordFormGroup.get('countryOfHarvest').value != null
        ? null
        : {"error": "Please fill out the entire row!"}
    );
    return null;
  }

  public woodSpeciesRecordValidator(group: FormGroup): ValidationErrors | null {
    let fields = [group.get('woodSpecies'), group.get('countryOfHarvest'), group.get('regionOfHarvest')];
    let volumeFields = [group.get('volume')];
    let counter = 0;
    fields.forEach(f => {
      if (f.value !== null) {
        counter++;
      }
    });
    volumeFields.forEach(f => {
      if (f.value !== null && f.value.amount >= 0) {
        counter++;
      }
    });

    if (counter > 0 && counter < (fields.length + volumeFields.length)) {
      fields.forEach(f => {
        if (f.value === null || f.value < 0) {
          f.setErrors({"error": "Please fill out the entire row!"});
        }
      });
      volumeFields.forEach(f => {
        if (f.value === null || f.value.amount === null || f.value.amount < 0) {
          f.setErrors({"error": "Please fill out the entire row!"});
        }
      });
    } else {
      fields.forEach(f => {
        f.setErrors(null);
      });
      volumeFields.forEach(f => {
        f.setErrors(null);
      });
    }

    return null;
  }

  public assemblySiteNodeValidator(assemblySiteNode: FormGroup): ValidationErrors | null {
    if (assemblySiteNode.get('certification').value !== null) {
      (assemblySiteNode.get('manufacturersData').get('certificationRecords') as FormArray).controls.forEach(c => {
        if (c.get('certification').value === null) {
          c.get('certification').setErrors({"error": "This field is required if a certificate is selected!"});
        } else {
          c.get('certification').setErrors(null);
        }
      });
    }
    if (assemblySiteNode.get('tradingCompanyCheckbox') && assemblySiteNode.get('tradingCompanyCheckbox').value) {
      const name = assemblySiteNode.get('tradingCompany').get('name');
      const country = assemblySiteNode.get('tradingCompany').get('country');
      if (name.value === null || name.value === "") {
        name.setErrors({"required": true});
      } else {
        name.setErrors(null);
      }
      if (country.value === null || country.value === "") {
        country.setErrors({"required": true});
      } else {
        country.setErrors(null);
      }
    }
    return null;
  }
}
