<form [formGroup]="data">
  <fieldset>
    <legend>Processors of logs or manufacturers using timber</legend>
    <div formArrayName="certificationRecords">
      <rb-repeatable-row [records]="data.get('certificationRecords')"
                         [newRecordSupplier]="formService.createManufacturerCertificationRecord.bind(formService)">
        <ng-template #record let-certificationRecord let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="certification-{{uid}}-{{index}}">Certification (if certified):</label>
            <rb-dropdown inputId="certification-{{uid}}-{{index}}"
                         formControlName="certification"
                         [options]="referenceDataService.getManufacturerCertifications()"
                         [autoDisplayFirst]="false"
                         styleClass="width-200px"></rb-dropdown>
            <rb-document-upload
              formControlName="document"
            >
            </rb-document-upload>
            <rb-calendar formControlName="date"></rb-calendar>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
    <div formArrayName="legalityOfProcessingRecords">
      <rb-repeatable-row [records]="data.get('legalityOfProcessingRecords')"
                         [newRecordSupplier]="formService.createManufacturerLegalityOfProcessingRecord.bind(formService)">
        <ng-template #record let-legalityOfProcessingRecord let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="legality-of-processing-{{uid}}-{{index}}">Legality of processing:</label>
            <rb-dropdown inputId="legality-of-processing-{{uid}}-{{index}}"
                         formControlName="legalityOfProcessing"
                         [options]="referenceDataService.getLegalityOfProcessing()"
                         styleClass="width-200px"></rb-dropdown>
            <rb-document-upload
              formControlName="document"
            ></rb-document-upload>
            <div style="display: inline-block;">
              <rb-calendar formControlName="date"></rb-calendar>
              <i class="pi pi-info-circle"
                 pTooltip="Most countries require all sawmills, Massive mills, secondary manufacturers to be licensed to process logs or timber.
                 Generally available documents in hard copy or electronic form, e.g. documentation on ownership/rights to land use/contract or concession agreements.
                 Third parties’ legal rights to use and tenure affected by timber harvesting :Environmental impact assessments; management plans; environmental audit reports; social responsibility agreements; specific reports on tenure and rights claims and conflicts.'"></i>
            </div>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
    <div formArrayName="paymentOfTaxesAndRoyaltiesRecords">
      <rb-repeatable-row [records]="data.get('paymentOfTaxesAndRoyaltiesRecords')"
                         [newRecordSupplier]="formService.createManufacturerPaymentOfTaxesAndRoyaltiesRecord.bind(formService)">
        <ng-template #record let-paymentOfTaxesAndRoyaltiesRecord let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="payment-of-taxes-and-royalties-{{uid}}-{{index}}">Payment of taxes and royalties:</label>
            <rb-dropdown inputId="payment-of-taxes-and-royalties-{{uid}}-{{index}}"
                         formControlName="paymentOfTaxesAndRoyalties"
                         [options]="referenceDataService.getManufacturerPaymentOfTaxesAndRoyalties()"
                         styleClass="width-200px"></rb-dropdown>
            <rb-document-upload
              formControlName="document"
            ></rb-document-upload>
            <div style="display: inline-block;">
              <rb-calendar formControlName="date"></rb-calendar>
              <i class="pi pi-info-circle"
                 pTooltip="Evidence that the company has paid any fees or taxes that must be paid to the state or local government in connection with timber processing.
                 Generally available documents in hard copy or electronic form, e.g. contracts, bank notes, VAT documentation, official receipts, etc.'"></i>
            </div>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
  </fieldset>
</form>
