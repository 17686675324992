<div [contentsSection]="calculateSectionName(index)"
     *ngFor="let record of this.records? this.records['controls'] : []; let index = index;">
  <p-accordionTab selected=true>
    <p-header style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
      <div style="display: flex;">
        <div style="display: flex; flex-direction: column; justify-content: center;">
          <ng-container
            *ngTemplateOutlet="headerTmpl, context: { $implicit: record, index: index}">
          </ng-container>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center; padding-left: 5px">
          <rb-error-pill
            [formGroup]="record.get('woodSpeciesRecord') ? record.get('woodSpeciesRecord') : record"></rb-error-pill>
        </div>
        <div (click)="$event.stopPropagation();"
             style="display: flex; flex-direction: column; justify-content: center;">
          <p-button type="button"
                    class="black-icon-btn"
                    icon="pi pi-plus-circle"
                    [disabled]="records.disabled"
                    (onClick)="insertRow(index)"></p-button>
        </div>
      </div>
      <div (click)="$event.stopPropagation();">
        <p-button type="button"
                  class="black-icon-btn"
                  icon="pi pi-trash"
                  (onClick)="deleteRowIfNotLast(index)"
                  [disabled]="records.disabled || getLengthOfRecords() <= 1"></p-button>
      </div>
    </p-header>
    <div>
      <ng-container
        *ngTemplateOutlet="recordTmpl, context: { $implicit: record, index: index}"></ng-container>
    </div>
  </p-accordionTab>
</div>
