import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UidService {
  uid: number = 0;

  constructor() {
  }

  generateUid(): number {
    return this.uid++;
  }
}
