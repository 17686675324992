<button
  *ngIf="formService.getEutrForm()"
  pButton
  type="button"
  [icon]="formService.saving ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
  class="rbButton ui-button-raised"
  (click)="this.formService.save(this.eutrDocument);;"
  [label]="formService.saving ? 'Saving...' : 'Save'"
  [disabled]="!formService.formIsDirty() || formService.saving || pendingUploads()"
  [title]="pendingUploads() ? 'Please wait until all files are uploaded!' : ''"
></button>
