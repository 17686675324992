import {Component, Input} from '@angular/core';
import {FormService} from "../../services/form.service";
import {EutrDocument} from "../contracts";
import {HttpRequestsService} from "../../services/http-requests.service";

@Component({
  selector: 'rb-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent {
  @Input() eutrDocument: EutrDocument;

  constructor(public formService: FormService, private httpRequestsService: HttpRequestsService) {
  }

  pendingUploads(): boolean {
    return !!this.httpRequestsService.requests.length;
  }
}
