import {Component, OnInit} from '@angular/core';
import {User, UserService} from '../services/user.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'rb-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  user$: Observable<User>;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.user$ = this.userService.user;
  }

}
