<p-panel header="Scoring">
  <div class="content-block">
    <table class="ftable">
      <tr class="no-counter">
        <th></th>
        <th>
          Country Of Origin Analysis
        </th>
        <th
          [ngClass]="scoringService.isCountryAnalysisNegligible(scoring) ?'negligible':'significant'">{{scoringService.isCountryAnalysisNegligible(scoring) ? 'Negligible' : 'Significant'}}
        </th>
        <th>Remarks</th>
      </tr>
      <tr [class.not-present]="!scoring?.countryAnalysis?.sanctionOnu">
        <td></td>
        <td>Sanction ONU</td>
        <td class="significance">{{scoring?.countryAnalysis?.sanctionOnu?.significance}}</td>
        <td>{{scoring?.countryAnalysis?.sanctionOnu?.remarks}}</td>
        <td>{{scoring?.countryAnalysis?.sanctionOnu?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.countryAnalysis?.cpiRisk">
        <td></td>
        <td>CPI risk</td>
        <td class="significance">{{scoring?.countryAnalysis?.cpiRisk?.significance}}</td>
        <td>{{scoring?.countryAnalysis?.cpiRisk?.remarks}}</td>
        <td>{{scoring?.countryAnalysis?.cpiRisk?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.countryAnalysis?.armedConflict">
        <td></td>
        <td>Armed conflict</td>
        <td class="significance">{{scoring?.countryAnalysis?.armedConflict?.significance}}</td>
        <td>{{scoring?.countryAnalysis?.armedConflict?.remarks}}</td>
        <td>{{scoring?.countryAnalysis?.armedConflict?.score}}</td>
      </tr>
      <tr class="no-counter blank"></tr>
      <tr class="no-counter">
        <th></th>
        <th>Supply Chain Analysis</th>
        <th
          [ngClass]="scoringService.isSupplyChainAnalysisNegligible(scoring) ?'negligible':'significant'">{{scoringService.isSupplyChainAnalysisNegligible(scoring) ? 'Negligible' : 'Significant'}}
        </th>
        <th>Remarks</th>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.completelyRecycled">
        <td></td>
        <td>100% recycled</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.completelyRecycled?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.completelyRecycled?.remarks}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.documentedAndApproved">
        <td></td>
        <td>FLEGT, CITES, SVLK documented and approved</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.documentedAndApproved?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.documentedAndApproved?.remarks}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.supplierLocatedInEurope">
        <td></td>
        <td>Product supplier located in EU</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.supplierLocatedInEurope?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.supplierLocatedInEurope?.remarks}}</td>
        <td>{{scoring?.supplyChainAnalysis?.supplierLocatedInEurope?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.certifications">
        <td></td>
        <td>Certifications</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.certifications?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.certifications?.remarks}}</td>
        <td>{{scoring?.supplyChainAnalysis?.certifications?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.timberSpecies">
        <td></td>
        <td>Timber species</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.timberSpecies?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.timberSpecies?.remarks}}</td>
        <td>{{scoring?.supplyChainAnalysis?.timberSpecies?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.chainComplete">
        <td></td>
        <td>Chain complete</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.chainComplete?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.chainComplete?.remarks}}</td>
        <td>{{scoring?.supplyChainAnalysis?.chainComplete?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.legalityDocuments">
        <td></td>
        <td>Legality documents</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.legalityDocuments?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.legalityDocuments?.remarks}}</td>
        <td>{{scoring?.supplyChainAnalysis?.legalityDocuments?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.complexityOfTheChain">
        <td></td>
        <td>Complexity of the chain</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.complexityOfTheChain?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.complexityOfTheChain?.remarks}}</td>
        <td>{{scoring?.supplyChainAnalysis?.complexityOfTheChain?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.brokenChainOfCustody">
        <td></td>
        <td>Broken chain of custody</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.brokenChainOfCustody?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.brokenChainOfCustody?.remarks}}</td>
        <td>{{scoring?.supplyChainAnalysis?.brokenChainOfCustody?.score}}</td>
      </tr>
      <tr [class.not-present]="!scoring?.supplyChainAnalysis?.sislEthicalPolicy">
        <td></td>
        <td>SISL Ethical Policy</td>
        <td
          class="significance">{{scoring?.supplyChainAnalysis?.sislEthicalPolicy?.significance}}</td>
        <td>{{scoring?.supplyChainAnalysis?.sislEthicalPolicy?.remarks}}</td>
        <td>{{scoring?.supplyChainAnalysis?.sislEthicalPolicy?.score}}</td>
      </tr>
      <tfoot>
      <tr class="no-counter blank"></tr>
      <tr>
        <td></td>
        <td class="bold">Global Score</td>
        <td class="significance"
            [class.good]="scoring?.globalScoreLabel === GlobalScoringLabel.GREEN"
            [class.warning]="scoring?.globalScoreLabel === GlobalScoringLabel.AMBER"
            [class.bad]="scoring?.globalScoreLabel === GlobalScoringLabel.RED"
        >{{scoring?.globalScoreLabel}}</td>
        <td></td>
        <td>{{scoring?.globalScore}}</td>
        <td>{{this.scoringService.byPassRulesApplicable ? 'Bypassed' : ''}}</td>
      </tr>
      </tfoot>
    </table>
  </div>
</p-panel>
