import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../../../../services/reference-data.service";
import {FormGroup} from "@angular/forms";
import {FormService} from "../../../../../services/form.service";

@Component({
  selector: 'rb-major-species-of-massive-wood',
  templateUrl: './major-species-of-massive-wood.component.html',
  styleUrls: ['./major-species-of-massive-wood.component.scss', '../../data.component.scss']
})
export class MajorSpeciesOfMassiveWoodComponent {
  @Input() records: FormGroup;

  constructor(public referenceDataService: ReferenceDataService, public formService: FormService) {
  }
}
