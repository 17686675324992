import {Component, Input} from '@angular/core';
import {ReferenceDataService} from "../../../../../../services/reference-data.service";
import {FormGroup} from "@angular/forms";
import {FormService} from "../../../../../../services/form.service";

@Component({
  selector: 'rb-component-info',
  templateUrl: './component-info.component.html',
  styleUrls: ['./component-info.component.scss']
})
export class ComponentInfoComponent {
  @Input() record: FormGroup;
  @Input() index: number;

  constructor(
    public referenceDataService: ReferenceDataService,
    public formService: FormService
  ) {
  }

  getTotal() {
    return ((this.record.get('massiveWoodNode').get("percentage").value || 0)
      + (this.record.get('particleBoardNode').get("percentage").value || 0)
      + (this.record.get('plywoodNode').get("percentage").value || 0)).toFixed(2);
  }
}
