<p-dialog header="Search Chain / Chain Parts"
          [(visible)]="display"
          [modal]="true"
          [style]="{width: '50vw'}"
          [maximizable]="true"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false"
>
  <p-treeTable [value]="treeNodes"
               [paginator]="true"
               [rows]="10"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr *ngIf="rowData.topLevel; else elseBlock"
          [ttRow]="rowNode"
          (click)="clicked(rowData)"
          [class.active]="selectedNodeSummary === rowData"
          class="clickable">
        <td>
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          {{rowData.name}}
        </td>
      </tr>
      <ng-template #elseBlock>
        <tr [ttRow]="rowNode">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            {{rowData.name}}
          </td>
        </tr>
      </ng-template>
    </ng-template>
  </p-treeTable>
  <p-footer>
    <button type="button" pButton icon="pi pi-check"
            [disabled]="!selectedNodeSummary"
            (click)="confirm()"
            label="Confirm"
    ></button>
  </p-footer>
</p-dialog>
<p-button type="button"
          class="black-icon-btn"
          icon="pi pi-search"
          [disabled]="disabled"
          (onClick)="showDialog()"></p-button>
<hr>
