<p-accordion [multiple]="true">
  <rb-repeatable-accordion-tab [records]="records"
                               [header]="computeJoineryWorkshopHeader()"
                               [newRecordSupplier]="formService.createJoineryWorkshopNode.bind(formService)">
    <ng-template #header let-joineryWorkshopRecord let-jwIndex="index">
      <span>{{computeTitle('Joinery workshop', jwIndex, joineryWorkshopRecord.get('name')?.value)}}</span>
    </ng-template>
    <ng-template #record let-joineryWorkshopRecord let-jwIndex="index">
      <rb-chain-search [nodeSummaries]="chainService.joineryWorkshopNodeSummaries"
                       (selectedNode)="onConfirmedNode($event, jwIndex)"
                       [disabled]="joineryWorkshopRecord.disabled"
      ></rb-chain-search>
      <div [formGroup]="joineryWorkshopRecord">
        <div class="info-grid">
          <label for="joinery-name-{{uid}}-{{jwIndex}}" class="required">Name:</label>
          <rb-input id="joinery-name-{{uid}}-{{jwIndex}}" formControlName="name" classToApply="width-200px"></rb-input>
          <label for="joinery-country-{{uid}}-{{jwIndex}}" class="required">Country:</label>
          <rb-dropdown inputId="joinery-country-{{uid}}-{{jwIndex}}"
                       formControlName="country"
                       [options]="referenceDataService.getChainPartsCountries()"
                       styleClass="width-200px"></rb-dropdown>
          <label for="joinery-certification-{{uid}}-{{jwIndex}}">Certification:</label>
          <rb-dropdown inputId="joinery-certification-{{uid}}-{{jwIndex}}"
                       formControlName="certification"
                       [options]="referenceDataService.getChainPartsCertification()"
                       [group]="true"
                       styleClass="width-200px"></rb-dropdown>
          <label for="joinery-coc-code-{{uid}}-{{jwIndex}}">CoC code:</label>
          <rb-input id="joinery-coc-code-{{uid}}-{{jwIndex}}" formControlName="cocCode"
                    classToApply="width-200px"></rb-input>
          <div></div>
          <div></div>
          <label for="joinery-certification-type-{{uid}}-{{jwIndex}}">Certification Type and %<i
            class="pi pi-info-circle"
            pTooltip="(100%, Mix x%, Mix credit ...)"></i>
          </label>
          <rb-input id="joinery-certification-type-{{uid}}-{{jwIndex}}"
                    formControlName="certificationTypeAndPercentage" classToApply="width-200px"></rb-input>
        </div>
      </div>
      <hr/>
      <rb-manufacturers-data
        [data]="joineryWorkshopRecord.get('manufacturersData')"></rb-manufacturers-data>
      <rb-exporters-data
        [data]="joineryWorkshopRecord.get('exportersData')"></rb-exporters-data>
      <rb-forest-managers-data
        [data]="joineryWorkshopRecord.get('forestManagersData')"></rb-forest-managers-data>
      <rb-sawmill-nodes
        [records]="joineryWorkshopRecord.get('sawmillNodes')"
        [hdr]="computeJoineryWorkshopHeaderWithIndex(jwIndex)"
      ></rb-sawmill-nodes>
    </ng-template>
  </rb-repeatable-accordion-tab>
</p-accordion>
