<button
  pButton
  type="button"
  icon="pi pi-undo"
  class="rbButton ui-button-raised"
  (click)="discardChanges()"
  label="Discard Changes"
  [disabled]="!formService.formIsDirty()"
></button>
<p-confirmDialog key="discard" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
