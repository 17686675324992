<div class="actionBar">
  <div class="actionBarSectionStatusManagement">
    <div class="actionBarSubSectionStatusManagement">
      <div *ngIf="eutrDocument && userService.getUser()" style="display: flex; flex-direction: row;">
        <rb-state-selector
          [eutrDocument]="eutrDocument"
        ></rb-state-selector>
        <rb-save-button [eutrDocument]="this.eutrDocument"></rb-save-button>
        <rb-discard-button [eutrDocument]="this.eutrDocument"></rb-discard-button>
        <rb-print-button [eutrDocument]="this.eutrDocument"></rb-print-button>
      </div>
    </div>
  </div>
  <div *ngIf="eutrDocument && userService.getUser()" class="actionBarSectionMetaData"
       style="padding: 0 6px;">
    <div class="actionBarSubSectionMetaData">
      <div style="display: table;">
        <div style="display: table-row;">
          <div style="display: table-cell;">
            Created By:
          </div>
          <div style="display: table-cell; padding-left: 10px;">
            <strong>{{eutrDocument?.createdBy}}</strong>
          </div>
        </div>
        <div style="display: table-row;">
          <div style="display: table-cell;">
            Created on:
          </div>
          <div style="display: table-cell; padding-left: 10px;">
            <strong>{{eutrDocument?.creationTime.toLocaleDateString()}}</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="actionBarSubSectionMetaData">
      <div style="display: table;">
        <div style="display: table-row;">
          <div style="display: table-cell;">
            Modified By:
          </div>
          <div style="display: table-cell; padding-left: 10px;">
            <strong>{{eutrDocument?.modifiedBy}}</strong>
          </div>
        </div>
        <div style="display: table-row;">
          <div style="display: table-cell;">
            Modified On:
          </div>
          <div style="display: table-cell; padding-left: 10px;">
            <strong>{{eutrDocument?.modificationTime.toLocaleDateString()}}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
