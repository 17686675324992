import {Component} from '@angular/core';
import {ReferenceDataService} from "../../services/reference-data.service";
import {ControlValueAccessor, NgControl} from '@angular/forms';

@Component({
  selector: 'rb-wood-species',
  templateUrl: './wood-species.component.html',
  styleUrls: ['./wood-species.component.scss'],
})
export class WoodSpeciesComponent implements ControlValueAccessor {
  constructor(public referenceDataService: ReferenceDataService, public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };
  val;
  disabled: boolean;

  get value() {
    return this.val;
  }

  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get scientificNameSelection(): string {
    return this.val
      ? this.referenceDataService.getWoodSpecies().find(obj => obj.id === this.val).scientificName
      : null;
  }

  set scientificNameSelection(scientificName: string) {
    let selectedWoodSpecies = this.referenceDataService.getWoodSpecies().find(x => x.scientificName === scientificName);

    this.value = selectedWoodSpecies ? selectedWoodSpecies.id : null;
  }

  get commonNameSelection(): string {
    return this.val
      ? this.referenceDataService.getWoodSpecies().find(obj => obj.id === this.val).commonName
      : null;
  }

  set commonNameSelection(commonName: string) {
    this.value = commonName
      ? this.referenceDataService.getWoodSpecies().find(x => x.commonName === commonName).id
      : null;
  }
}
