import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ReferenceDataService} from "../../../../services/reference-data.service";
import {
  Document,
  ProductCertificationCertificationScopeVerdict,
  ProductCertificationCertificationValidityVerdict,
  ProductCertificationEvaluation,
  ProductCertificationInvoicesProvidedVerdict
} from "../../../../shared/contracts";
import {DocumentService} from "../../../../services/document.service";

@Component({
  selector: 'rb-product-certification-evaluation',
  templateUrl: './product-certification-evaluation.component.html',
  styleUrls: ['./product-certification-evaluation.component.scss']
})
export class ProductCertificationEvaluationComponent {
  @Input() form: FormGroup;

  constructor(public referenceDataService: ReferenceDataService, private documentService: DocumentService) {
  }

  getCertificationDocument(): Document {
    return this.documentService.getDocument(this.form.get('data').get('productCertification').get('certificateDocument').value);
  }

  isCertificationCompliant(): boolean {
    let productCertificationEvaluation: ProductCertificationEvaluation = this.getProductCertificationEvaluationFormGroup().value;

    return productCertificationEvaluation.certificationValidity === ProductCertificationCertificationValidityVerdict.COMPLIANT
      && productCertificationEvaluation.certificationScope === ProductCertificationCertificationScopeVerdict.COMPLIANT;
  }

  makeCertificationCompliant() {
    if (!this.isCertificationCompliant()) {
      let productCertificationEvaluation: any = this.getProductCertificationEvaluationFormGroup().controls;
      productCertificationEvaluation.certificationValidity.setValue(ProductCertificationCertificationValidityVerdict.COMPLIANT);
      productCertificationEvaluation.certificationScope.setValue(ProductCertificationCertificationScopeVerdict.COMPLIANT);
      this.getProductCertificationEvaluationFormGroup().markAsDirty();
    }
  }

  getInvoicesDocument(): Document {
    return this.documentService.getDocument(this.form.get('data').get('productCertification').get('invoiceDocument').value);
  }

  isInvoicesCompliant(): boolean {
    let productCertificationEvaluation: ProductCertificationEvaluation = this.getProductCertificationEvaluationFormGroup().value;

    return productCertificationEvaluation.invoicesProvided === ProductCertificationInvoicesProvidedVerdict.COMPLIANT;
  }

  makeInvoicesCompliant() {
    if (!this.isInvoicesCompliant()) {
      let productCertificationEvaluation: any = this.getProductCertificationEvaluationFormGroup().controls;
      productCertificationEvaluation.invoicesProvided.setValue(ProductCertificationInvoicesProvidedVerdict.COMPLIANT);
      this.getProductCertificationEvaluationFormGroup().markAsDirty();
    }
  }

  getProductCertificationEvaluationFormGroup(): FormGroup {
    return this.form.get('conclusion').get('productCertificationEvaluation') as FormGroup;
  }
}
