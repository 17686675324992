import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Document, EutrDocument} from "../shared/contracts";
import {RootResourceService} from "./root-resource.service";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private documents: Document[];

  constructor(private http: HttpClient, private rootResourceService: RootResourceService) {
  }

  getDocument(documentId: number): Document {
    return this.documents
      .find(doc => doc.id === documentId);
  }

  get uploadUrl(): string {
    return this.rootResourceService.rootResource._links.documentUpload.href;
  }

  addDocument(document: Document) {
    this.documents.push(document);
  }

  async loadDocumentsForEutrDocument(eutrDocument: EutrDocument) {
    return this.http.get<any>(eutrDocument._links.findAllDocumentsForEutrDocument.href)
      .toPromise()
      .then(resp => {
          this.documents = resp._embedded.documents;
          console.log(this.documents, "this.documents");
        }
      );
  }
}
