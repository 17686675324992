import {Component, Input} from '@angular/core';
import {FormArray} from "@angular/forms";
import {FormService} from "../../../../../../../services/form.service";
import {ReferenceDataService} from "../../../../../../../services/reference-data.service";
import {UidService} from "../../../../../../../services/uid.service";
import {ChainService} from "../../../../../../../services/chain.service";
import {AssemblySiteNode} from "../../../../../../../shared/contracts";

@Component({
  selector: 'rb-assembly-site-nodes',
  templateUrl: './assembly-site-nodes.component.html',
  styleUrls: ['./assembly-site-nodes.component.scss']
})
export class AssemblySiteNodesComponent {
  @Input() records: FormArray;
  @Input() hdr: string;
  uid: number;

  constructor(
    public formService: FormService,
    public referenceDataService: ReferenceDataService,
    private uidService: UidService,
    public chainService: ChainService,
  ) {
    this.uid = uidService.generateUid();
  }

  computeTitle(base: string, index: number, data: string) {
    return base + " " + (index + 1) + (data ? (" - " + data) : "");
  }

  computeAssemblySiteHeader(): string {
    return this.hdr + "AssemblySite";
  }

  computeAssemblySiteHeaderWithIndex(asIndex: number): string {
    return this.computeAssemblySiteHeader() + asIndex;
  }

  onConfirmedNode(node: AssemblySiteNode, index: number) {
    let formGroup = this.formService.createAssemblySiteNode(node);
    this.records.setControl(index, formGroup);
    this.formService.triggerTreeCuttingFromAssemblySite(formGroup);
    this.formService.recomputeWoodCompositionEvaluation();
  }
}
