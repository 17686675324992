<button
  *ngIf="eutrDocument._links.download?.href"
  pButton
  type="button"
  icon="pi pi-print"
  class="rbButton ui-button-raised"
  (click)="download()"
  label="Print"
  [disabled]="formService.formIsDirty()"
  [title]="formService.formIsDirty() ? 'Please save the form before printing!' : 'Export a pdf version'"
>
  <a #downloadLink download href="{{eutrDocument._links.download.href}}"></a>
</button>
