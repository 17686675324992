import {AfterViewInit, Directive, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {TocService} from "./toc.service";
import {distinctUntilChanged} from "rxjs/operators";

@Directive({
  selector: '[contentsLink]',
  exportAs: 'contentsLink',
})
export class ContentsLinkDirective implements AfterViewInit, OnDestroy, OnInit {

  @Input() fragment;
  @HostBinding('class.active') active = false;

  private _subscription: Subscription;

  constructor(
    private tocService: TocService
  ) {
  }

  ngAfterViewInit() {
    this._subscription = this.tocService.activeSectionsSubject$.pipe(distinctUntilChanged())
      .subscribe((activeFragments) => {
        this.active = activeFragments.includes(this.fragment);
      });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
  }
}
