<div class="grid2x2">
  <input pInputText
         [ngModel]="value.amount"
         [disabled]="disabled"
         (ngModelChange)="this.changedAmount($event)"
         type="number"
         min="0"
         max="100"/>
  <p-dropdown [ngModel]="value.unit"
              (ngModelChange)="this.changedUnit($event)"
              [disabled]="disabled"
              [options]="referenceDataService.getVolumeTypes()"
              [autoDisplayFirst]="false"></p-dropdown>
  <rb-error-marker [control]="ngControl.control"></rb-error-marker>
</div>
