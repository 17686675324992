<form [formGroup]="data">
  <fieldset>
    <legend>Exporters of forest product</legend>
    <div formArrayName="legalityOfSaleAndExportRecords">
      <rb-repeatable-row [records]="data.get('legalityOfSaleAndExportRecords')"
                         [newRecordSupplier]="formService.createExporterLegalityOfSaleAndExportRecord.bind(formService)">
        <ng-template #record let-legalityOfSaleAndExportRecord let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="legality-of-sale-and-export-{{uid}}-{{index}}" class="required">Legality of sale and
              export:</label>
            <div>
              <rb-dropdown inputId="legality-of-sale-and-export-{{uid}}-{{index}}"
                           formControlName="legalityOfSaleAndExport"
                           [options]="referenceDataService.getExporterLegalityOfSaleAndExport()"
                           styleClass="width-200px"></rb-dropdown>
            </div>
            <div>
              <rb-document-upload
                formControlName="document"
              ></rb-document-upload>
            </div>
            <div style="display: inline-block;">
              <rb-calendar formControlName="date"></rb-calendar>
              <i class="pi pi-info-circle"
                 pTooltip="Export companies are required to be licensed to have the permission to export forest products.
                 They should be able to provide documentation to allow the state authorities in the country and the country of import to monitor the transaction.
                 This documentation must comply with all the requirements for legal export of the product.
                 Generally available documents in hard copy or electronic form, e.g. contracts, bank notes, trade notes, import licenses, export licenses, export ban lists, export quota awards, etc."></i>
            </div>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
    <div formArrayName="paymentOfFeesAndRoyaltiesRecords">
      <rb-repeatable-row [records]="data.get('paymentOfFeesAndRoyaltiesRecords')"
                         [newRecordSupplier]="formService.createExporterPaymentOfFeesAndRoyaltiesRecord.bind(formService)">
        <ng-template #record let-paymentOfFeesAndRoyaltiesRecords let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="payment-of-fees-and-royalties-{{uid}}-{{index}}">Payment of fees and royalties:</label>
            <rb-dropdown inputId="payment-of-fees-and-royalties-{{uid}}-{{index}}"
                         formControlName="paymentOfFeesAndRoyalties"
                         [options]="referenceDataService.getExporterPaymentOfFeesAndRoyalties()"
                         styleClass="width-200px"></rb-dropdown>
            <rb-document-upload
              formControlName="document"
            ></rb-document-upload>
            <div style="display: inline-block;">
              <rb-calendar formControlName="date"></rb-calendar>
              <i class="pi pi-info-circle"
                 pTooltip="Evidence that the company has paid the appropriate taxes and duties associated with the forests product being exported.
                 E.G : official receipts for export duties."></i>
            </div>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
  </fieldset>
</form>
