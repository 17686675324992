import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpRequestsService {
  public isLoading = new BehaviorSubject(false);
  public requests: any[] = [];
  public failedUploads: any[] = [];
  public successfulUploads: any[] = [];

  constructor() {
  }
}
