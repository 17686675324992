import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {TreeNode} from "primeng/api";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {NodeSummary} from "../../../../../../shared/contracts";

@Component({
  selector: 'rb-chain-search',
  templateUrl: './chain-search.component.html',
  styleUrls: ['./chain-search.component.scss']
})
export class ChainSearchComponent {
  selectedNodeSummary: any;
  treeNodes: TreeNode[];
  display: boolean = false;
  @Input() nodeSummaries: Observable<any>;
  @Input() disabled: boolean = false;
  @Output() selectedNode: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private changeDetectorRef: ChangeDetectorRef) {
  }

  showDialog() {
    this.nodeSummaries
      .subscribe(resp => {
          this.treeNodes = this.createTreeNodes(resp._embedded.nodes, true);
          this.display = true;
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  private createTreeNodes(nodes: NodeSummary[], topLevel: boolean) {
    return nodes.map(node => {
      let data: any = {...node};
      data.topLevel = topLevel;
      return ({
        data: data,
        children: this.createTreeNodes(node.children, false),
        leaf: true,
        expanded: false
      });
    });
  }

  clicked(rowData) {
    this.selectedNodeSummary = rowData;
  }

  confirm() {
    this.http.get(this.selectedNodeSummary._links.self.href)
      .subscribe(resp => {
        this.selectedNode.emit(resp);
        this.display = false;
        this.changeDetectorRef.detectChanges();
      });
  }
}
