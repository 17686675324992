import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'rb-product-composition',
  templateUrl: './product-composition.component.html',
  styleUrls: ['product-composition.component.scss', './../../data.component.scss']
})
export class ProductCompositionComponent {
  @Input() form: FormGroup;

  constructor() {
  }

  getTotal() {
    let productComposition = this.form.get('data').get('productComposition');

    return (productComposition.get('wood').value
      + productComposition.get('plastic').value
      + productComposition.get('metal').value
      + productComposition.get('glass').value
      + productComposition.get('otherMaterials').value).toFixed(2);
  }
}
