<fieldset>
  <legend>Supplier Identification:</legend>
  <div class="grid-two-cols">
    <span>Supplier name:</span>
    <span>{{eutrDocument?.identification?.supplierIdentification?.supplierName}}</span>
    <span>Supplier Sourcing Code:</span>
    <span>{{eutrDocument?.identification?.supplierIdentification?.supplierSourcingCode}}</span>
    <span>SISPRO Code</span>
    <span>{{eutrDocument?.identification?.supplierIdentification?.sisProCode}}</span>
    <span>Supplier Mail:</span>
    <span>{{eutrDocument?.identification?.supplierIdentification?.supplierMail}}</span>
    <span>CT, BD, PK ... Code:</span>
    <span>{{eutrDocument?.identification?.supplierIdentification?.code}}</span>
  </div>
</fieldset>
