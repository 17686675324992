import {AfterViewInit, Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {ContentsDirective} from "./contents.directive";

import {documentOffset, getAbsoluteHeight} from './html-utils';
import {TocService} from "./toc.service";

@Directive({
  selector: '[contentsSection]',
  exportAs: 'contentsSection',
})
export class ContentsSectionDirective implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('id') @Input() contentsSection: string;

  constructor(
    private elementRef: ElementRef,
    private tocService: TocService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.tocService.addSection(this);
  }

  ngOnDestroy() {
    this.tocService.removeSection(this);
  }


  isInRange(contents: ContentsDirective): boolean {
    if (!contents) {
      return false;
    }
    const pageOffset: number = contents.scrollingView ? contents.scrollingView.scrollTop + 1 : documentOffset();
    const element: HTMLElement = this.elementRef.nativeElement;
    const offset: number = element.offsetTop;
    const elementHeight: number = getAbsoluteHeight(element);

    return pageOffset >= offset && pageOffset <= offset + elementHeight;
  }
}
