import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {FormGroup} from "@angular/forms";
import {EutrDocument} from "../../../shared/contracts";

@Component({
  selector: 'rb-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() eutrDocument: EutrDocument;
  items: MenuItem[];
  activeItem: MenuItem;

  constructor() {
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Navigation', icon: 'pi pi-bars', command: (event) => {
          this.activeItem = event.item;
        }
      },
      {
        label: 'Uploads', icon: 'pi pi-file-o', command: (event) => {
          this.activeItem = event.item;
        }
      },
    ];

    this.activeItem = this.items[0];
  }

}
