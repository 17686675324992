import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {State} from "../shared/contracts";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user: User;
  private readonly authMatrix: Map<Section, Map<Role, Map<State, AuthGroup>>>;
  private readonly defaultRoleAuth: Map<Role, Map<State, AuthGroup>>;


  // TODO_JORIS cleanup
  constructor(private httpService: HttpClient) {
    this.fetchUser().subscribe((u) => {
      this._user = u;
    });
    this.defaultRoleAuth = new Map<Role, Map<State, AuthGroup>>([
      [
        Role.QE,
        new Map<State, AuthGroup>([
          [State.NOT_FULFILLED, AuthGroup.WRITE],
          [State.IN_PROGRESS, AuthGroup.WRITE],
          [State.PENDING_QE_VALIDATION, AuthGroup.WRITE],
          [State.FULFILLED, AuthGroup.READ],
          [State.REJECTED, AuthGroup.READ],
        ])
      ],
      [
        Role.VENDOR,
        new Map<State, AuthGroup>([
          [State.NOT_FULFILLED, AuthGroup.WRITE],
          [State.IN_PROGRESS, AuthGroup.WRITE],
          [State.PENDING_QE_VALIDATION, AuthGroup.READ],
          [State.REJECTED, AuthGroup.READ],
          [State.FULFILLED, AuthGroup.READ],
        ])
      ]
    ]);
    const conclusionRoleAuth = new Map<Role, Map<State, AuthGroup>>([
      [
        Role.QE,
        new Map<State, AuthGroup>([
          [State.NOT_FULFILLED, AuthGroup.WRITE],
          [State.IN_PROGRESS, AuthGroup.WRITE],
          [State.PENDING_QE_VALIDATION, AuthGroup.WRITE],
          [State.FULFILLED, AuthGroup.READ],
          [State.REJECTED, AuthGroup.READ],
        ])
      ],
      [
        Role.VENDOR,
        new Map<State, AuthGroup>([
          [State.NOT_FULFILLED, AuthGroup.READ],
          [State.IN_PROGRESS, AuthGroup.READ],
          [State.PENDING_QE_VALIDATION, AuthGroup.READ],
          [State.FULFILLED, AuthGroup.READ],
          [State.REJECTED, AuthGroup.READ],
        ])
      ]
    ]);
    const scoringRoleAuth = new Map<Role, Map<State, AuthGroup>>([
      [
        Role.QE,
        new Map<State, AuthGroup>([
          [State.NOT_FULFILLED, AuthGroup.WRITE],
          [State.IN_PROGRESS, AuthGroup.WRITE],
          [State.PENDING_QE_VALIDATION, AuthGroup.WRITE],
          [State.FULFILLED, AuthGroup.READ],
          [State.REJECTED, AuthGroup.READ],
        ])
      ],
      [
        Role.VENDOR,
        new Map<State, AuthGroup>([])
      ]
    ]);

    this.authMatrix = new Map<Section, Map<Role, Map<State, AuthGroup>>>([
      [Section.POLICYAGREEMENT, this.defaultRoleAuth],
      [Section.IDENTIFICATION, this.defaultRoleAuth],
      [Section.DATA, this.defaultRoleAuth],
      [Section.CONCLUSION, conclusionRoleAuth],
      [Section.SCORING, scoringRoleAuth]
    ]);
  }

  get user(): Observable<User> {
    if (this._user) {
      return of(this._user);
    }
    const userObservable = this.fetchUser();
    userObservable.subscribe((u) => {
      this._user = u;
    });
    return userObservable;
  }

  public getUser(): User {
    return this._user;
  }

  private fetchUser(): Observable<User> {
    return this.httpService.get<User>(environment.apiUrl + 'user');
  }

  public getPermission(user: User, section: Section, state: State): AuthGroup {
    const role: Role = user.role;
    try {
      return this.authMatrix.get(section).get(role).get(state);
    } catch (e) {
      return undefined;
    }
  }
}

export interface User {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
}

export enum Role {
  VENDOR = "VENDOR",
  QE = "QE"
}

export enum AuthGroup {
  READ = "READ",
  WRITE = "WRITE"
}

export enum Section {
  POLICYAGREEMENT = "POLICYAGRGEEMENT",
  IDENTIFICATION = "IDENTIFICATION",
  DATA = "DATA",
  CONCLUSION = "CONCLUSION",
  SCORING = "SCORING"
}
