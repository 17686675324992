import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AuthGroup, Section, UserService} from "../services/user.service";
import {State} from "../shared/contracts";
import {AbstractControl} from "@angular/forms";

@Directive({
  selector: '[rbDisableIfUnauthorized]'
})
export class DisableIfUnauthorizedDirective implements OnChanges {

  @Input("rbDisableIfUnauthorized") section: Section;
  @Input() state: State;
  @Input() form: AbstractControl;

  constructor(private userService: UserService) {
  }

  // { emitEvent: false } is required because we don't want disable() to trigger valueChanges on formGroups that affect the conclusion.
  // If this approach introduces other problems, formService should be modified such that valueChanges subscriptions
  // check to see if the value was indeed changed.
  ngOnChanges(changes: SimpleChanges): void {
    if (this.userService.getPermission(this.userService.getUser(), this.section, this.state) !== AuthGroup.WRITE && this.form) {
      this.form.disable({emitEvent: false});
    } else {
      this.form.enable({emitEvent: false});
    }
  }

}
