import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ReferenceDataService} from "../../../../services/reference-data.service";
import {Document, OriginWoodEvaluation, OriginWoodLicenseValidityVerdict} from "../../../../shared/contracts";
import {DocumentService} from "../../../../services/document.service";

@Component({
  selector: 'rb-origin-wood-evaluation',
  templateUrl: './origin-wood-evaluation.component.html',
  styleUrls: ['./origin-wood-evaluation.component.scss']
})
export class OriginWoodEvaluationComponent {
  @Input() form: FormGroup;

  constructor(public referenceDataService: ReferenceDataService, private documentService: DocumentService) {
  }

  isSupplierLocatedInEuCompliant(): boolean {
    let originWoodEvaluation: OriginWoodEvaluation = this.getOriginWoodEvaluationFormGroup().value;

    return originWoodEvaluation.supplierLocatedInEurope === OriginWoodLicenseValidityVerdict.COMPLIANT;
  }

  makeSupplierLocatedInEuCompliant() {
    if (!this.isSupplierLocatedInEuCompliant()) {
      let originWoodEvaluation: any = this.getOriginWoodEvaluationFormGroup().controls;
      originWoodEvaluation.supplierLocatedInEurope.setValue(OriginWoodLicenseValidityVerdict.COMPLIANT);
      this.getOriginWoodEvaluationFormGroup().markAsDirty();
    }
  }

  getFlegtLicenseDocument(): Document {
    return this.documentService.getDocument(this.form.get('data').get('originWood').get('flegtDocument').value);
  }

  isFlegtLicenseCompliant(): boolean {
    let originWoodEvaluation: OriginWoodEvaluation = this.getOriginWoodEvaluationFormGroup().value;

    return originWoodEvaluation.flegtLicense === OriginWoodLicenseValidityVerdict.COMPLIANT;
  }

  makeFlegtLicenseCompliant() {
    if (!this.isFlegtLicenseCompliant()) {
      let originWoodEvaluation: any = this.getOriginWoodEvaluationFormGroup().controls;
      originWoodEvaluation.flegtLicense.setValue(OriginWoodLicenseValidityVerdict.COMPLIANT);
      this.getOriginWoodEvaluationFormGroup().markAsDirty();
    }
  }

  getCitesLicenseDocument(): Document {
    return this.documentService.getDocument(this.form.get('data').get('originWood').get('citesDocument').value);
  }

  isCitesLicenseCompliant(): boolean {
    let originWoodEvaluation: OriginWoodEvaluation = this.getOriginWoodEvaluationFormGroup().value;

    return originWoodEvaluation.citesLicense === OriginWoodLicenseValidityVerdict.COMPLIANT;
  }

  makeCitesLicenseCompliant() {
    if (!this.isCitesLicenseCompliant()) {
      let originWoodEvaluation: any = this.getOriginWoodEvaluationFormGroup().controls;
      originWoodEvaluation.citesLicense.setValue(OriginWoodLicenseValidityVerdict.COMPLIANT);
      this.getOriginWoodEvaluationFormGroup().markAsDirty();
    }
  }

  getSvlkDocument(): Document {
    return this.documentService.getDocument(this.form.get('data').get('originWood').get('svlkDocument').value);
  }

  isFromIndonesiaCompliant(): boolean {
    let originWoodEvaluation: OriginWoodEvaluation = this.getOriginWoodEvaluationFormGroup().value;

    return originWoodEvaluation.fromIndonesia === OriginWoodLicenseValidityVerdict.COMPLIANT;
  }

  makeFromIndonesiaCompliant() {
    if (!this.isFromIndonesiaCompliant()) {
      let originWoodEvaluation: any = this.getOriginWoodEvaluationFormGroup().controls;
      originWoodEvaluation.fromIndonesia.setValue(OriginWoodLicenseValidityVerdict.COMPLIANT);
      this.getOriginWoodEvaluationFormGroup().markAsDirty();
    }
  }

  getOriginWoodEvaluationFormGroup(): FormGroup {
    return this.form.get('conclusion').get('originWoodEvaluation') as FormGroup;
  }
}
