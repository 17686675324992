import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {environment} from "../../environments/environment";
import * as UriTemplateParser from 'url-template/lib/url-template';

@Injectable()
export class Http401Interceptor implements HttpInterceptor {
  private fragment: string = null;

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(tap(
        () => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              if (!this.fragment) {
                this.fragment = window.location.hash;
              }
              window.location.href = UriTemplateParser
                .parse(environment.backendLogin)
                .expand({fragment: this.fragment});
            }
          }
        }));
  }
}
