import {Component, Input} from '@angular/core';

@Component({
  selector: 'rb-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() text: string;
  @Input() size: string;

  constructor() {
  }
}
