import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ItemService} from "../../../../../services/item.service";
import {Observable} from "rxjs";
import {AdvancedFilter, AdvancedOptionsComponent} from "./advanced-options/advanced-options.component";
import {ProductIdentificationItem} from "../../../../../shared/contracts";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'rb-add-item-modal',
  templateUrl: './add-item-modal.component.html',
  styleUrls: ['./add-item-modal.component.scss']
})
export class AddItemModalComponent implements OnInit, AfterViewInit {

  // tslint:disable-next-line:ban-ts-ignore
  // @ts-ignore
  @ViewChild(AdvancedOptionsComponent) child;
  searchTerm: string = '';
  isSearching = false;
  advancedSearching = false;
  advancedFilter: string;

  errors: string[] = [];
  items: ProductIdentificationItem[] = [];

  constructor(private itemService: ItemService, public ref: DynamicDialogRef) {
  }

  ngOnInit() {
    // TODO: should the items already loaded also be available in the modal?
  }

  ngAfterViewInit() {
    this.advancedFilter = this.child.advancedFilter;
  }


  search() {
    this.isSearching = true;
    if (!this.searchTerm) {
      return;
    }

    this.itemService.findItem(this.searchTerm)
      .subscribe(
        this.addItem,
        this.handleSearchError,
        this.handleFinishSearch
      )
    ;
  }

  addItem(item: ProductIdentificationItem) {
    if (this.items.find((i) => i.code === item.code)) {
      this.errors.push("Item is already added");
      return;
    }
    this.items.push(item);
  }

  removeItem(item: ProductIdentificationItem) {
    this.items = this.items.filter((i) => i.code !== item.code);
  }

  submit() {
    this.ref.close(this.items);
  }

  toggleAdvancedSearching() {
    this.advancedSearching = !this.advancedSearching;
  }

  applyAdvancedFilter(advancedFilter: AdvancedFilter) {
    this.isSearching = true;
    this.itemService.findItemWithAdvancedOptions(this.searchTerm, advancedFilter.supplierItemReference, advancedFilter.customer, advancedFilter.customerItemNumber)
      .subscribe(
        this.addItem,
        this.handleSearchError(),
        this.handleFinishSearch()
      );

  }

  private handleFinishSearch() {
    return () => {
      this.searchTerm = '';
      this.isSearching = false;
    };
  }

  private handleSearchError() {
    return (e) => {
      this.errors.push(e.statusText + ` (${this.searchTerm})`);

      this.searchTerm = '';
      this.isSearching = false;
    };
  }
}

export interface AddItemDialogData {
  items: ProductIdentificationItem[];
}

export interface AddItemDialog {
  onClose: Observable<ProductIdentificationItem[]>;
}


