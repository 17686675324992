import {Component, ViewChild} from '@angular/core';
import {FileUpload} from "primeng/fileupload";
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {DocumentService} from "../../services/document.service";
import {Document} from "../contracts";

@Component({
  selector: 'rb-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements ControlValueAccessor {
  @ViewChild('fileUpload') fileUpload: FileUpload;
  files: File[] = [];

  constructor(public documentService: DocumentService, public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  document: Document;
  disabled: boolean;
  onProgress: boolean = false;

  get value() {
    return this.document ? this.document.id : this.document as any;
  }

  set value(val: number) {
    if (val !== undefined) {
      if (this.document ? this.document.id != val : val != null) {
        this.document = this.documentService.getDocument(val);
        this.onChange(val);
        this.onTouch(val);
      }
    }
  }

  writeValue(value: number) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  click(): void {
    this.fileUpload.advancedFileInput.nativeElement.click();
  }

  delete() {
    this.files = [];
    this.value = null;
  }

  finishedUpload(event: any) {
    this.documentService.addDocument(event.originalEvent.body);
    this.value = event.originalEvent.body.id;
    this.onProgress = false;
  }

  onError(event: any) {
    this.fileUpload.progress = 0;
    this.fileUpload.msgs.push({
      severity: 'error',
      detail: 'Upload failed!'
    });
    this.onProgress = false;
  }

  onBeforeUpload() {
    this.onProgress = true;
  }
}
