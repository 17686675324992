import {Injectable} from '@angular/core';
import {ContentsDirective} from "./contents.directive";
import {ContentsSectionDirective} from "./contents-section.directive";
import {ReplaySubject, Subscription} from "rxjs";
import {distinctUntilChanged, map, throttle} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TocService {

  private _sections: ContentsSectionDirective[];
  private _contentsDirective?: ContentsDirective;
  private _subscription: Subscription;

  public activeSectionsSubject$: ReplaySubject<string[]> = new ReplaySubject<string[]>();

  constructor() {
    this._sections = [];
    this.activeSectionsSubject$.next([]);
  }

  registerContentsDirective(cd: ContentsDirective) {
    this._contentsDirective = cd;

    this._subscription = this._contentsDirective._onScroll$
      .pipe(map(() => this.getActiveFragments()))
      .pipe(distinctUntilChanged())
      .subscribe(this.activeSectionsSubject$);

  }

  private getActiveFragments(): string[] {
    return this._sections.filter(x => x.isInRange(this._contentsDirective)).map(x => x.contentsSection);
  }

  unregisterContentsDirective(cd: ContentsDirective) {
    if (this._contentsDirective !== cd) {
      throw new Error("Cannot unregister ContentsDirective that was not registered");
    }
    this._subscription.unsubscribe();
    delete this._contentsDirective;
  }

  addSection(section: ContentsSectionDirective) {
    this._sections.push(section);
    this.forceUpdateToc();
  }

  private forceUpdateToc() {
    this.activeSectionsSubject$.next(this.getActiveFragments());
  }

  removeSection(section: ContentsSectionDirective) {
    this._sections = this._sections.filter((s) => s !== section);
    this.forceUpdateToc();
  }
}
