import {NgModule} from '@angular/core';
import {ContentsSectionDirective} from './contents-section.directive';
import {ContentsDirective} from './contents.directive';
import {ContentsLinkDirective} from './contents-link.directive';


@NgModule({
  exports: [
    ContentsLinkDirective,
    ContentsSectionDirective,
    ContentsDirective,
  ],
  declarations: [
    ContentsLinkDirective,
    ContentsSectionDirective,
    ContentsDirective,
  ],
  imports: []
})
export class ContentsModule {
}
