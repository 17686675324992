import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'rb-advanced-options',
  templateUrl: './advanced-options.component.html',
  styleUrls: ['./advanced-options.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '140px',
        opacity: 1
      })),
      state('closed', style({
        height: '0',
        opacity: 0
      })),
      transition('open => closed', [
        animate('0.4s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
})
export class AdvancedOptionsComponent {
  @Input() advancedSearching: boolean;
  @Output() applyFilterEvent: EventEmitter<AdvancedFilter> = new EventEmitter();
  advancedFilter: AdvancedFilter;
  supplierItemReference: string;
  customer: string;
  customerItemNumber: string;
  enableSIR: boolean;
  enableCustomer: boolean;
  enableCIN: boolean;

  constructor() {
    this.enableSIR = false;
    this.enableCIN = false;
    this.enableCustomer = false;
    this.advancedFilter = {customer: '', supplierItemReference: '', customerItemNumber: ''};
  }

  enable(filter: string) {
    if (filter === 'CIN' || filter === 'Customer') {
      this.enableSIR = false;
    }
    if (filter === 'SIR') {
      this.enableCIN = false;
      this.enableCustomer = false;
    }
  }

  applyFilter() {
    if (this.enableSIR) {
      this.advancedFilter.supplierItemReference = this.supplierItemReference;
    } else {
      this.advancedFilter.supplierItemReference = '';
    }

    if (this.enableCIN) {
      this.advancedFilter.customerItemNumber = this.customerItemNumber;
    } else {
      this.advancedFilter.customerItemNumber = '';
    }
    if (this.enableCustomer) {
      this.advancedFilter.customer = this.customer;
    } else {
      this.advancedFilter.customer = '';
    }
    this.applyFilterEvent.emit(this.advancedFilter);

  }
}

export interface AdvancedFilter {
  supplierItemReference: string;
  customer: string;
  customerItemNumber: string;
}
