import {Component, forwardRef, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FileUpload} from "primeng/fileupload";
import {Picture} from "../contracts";
import {PictureService} from "../../services/picture.service";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'rb-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GalleryComponent),
      multi: true
    }
  ]
})
export class GalleryComponent implements ControlValueAccessor {
  @ViewChild('fileUpload') fileUpload: FileUpload;
  files: File[] = [];
  picturesForGallery: { source: string }[] = [];
  galleriaVisible = true;

  constructor(
    public pictureService: PictureService,
    private confirmationService: ConfirmationService
  ) {
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  val: Picture[];
  disabled: boolean;
  onProgress: boolean = false;
  activeIndex: number = 0;

  get value() {
    return this.val;
  }

  set value(val: Picture[]) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.picturesForGallery = this.val
        ? this.picturesForGallery = this.val.map(picture => this.createObjectForGallery(picture))
        : [];
      // TODO_JORIS: does this make sense?
      this.onChange(this.val);
      this.onTouch(this.val);
    }
  }

  writeValue(value: Picture[]) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clickOnFileUpload(): void {
    this.fileUpload.advancedFileInput.nativeElement.click();
  }

  deleteActivePicture() {
    this.val.splice(this.activeIndex, 1);
    this.picturesForGallery.splice(this.activeIndex, 1);
    if (this.activeIndex === this.val.length) {
      this.activeIndex--;
      this.reloadGalleria();
    }
    // TODO_JORIS
    this.onChange(this.val);
    this.onTouch(this.val);
  }

  // TODO_JORIS: does not seem to work properly when 2 modals exist ...
  showConfirmDeleteModal(): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to remove this picture?',
      accept: () => {
        this.deleteActivePicture();
      }
    });
  }

  /**
   * p-galleria does not properly handle all changes related to it's images.
   * This workaround ensures a rerender to mitigate these issues.
   * https://stackoverflow.com/a/56540964/1939921
   */
  private reloadGalleria() {
    setTimeout(() => this.galleriaVisible = false);
    setTimeout(() => this.galleriaVisible = true);
  }

  finishedUpload(event: any) {
    let pics = (this.value || []);
    let newPicture = event.originalEvent.body;
    pics.push(newPicture);
    this.value = pics;
    // tODO_JORIS cleanup
    this.picturesForGallery.push(this.createObjectForGallery(newPicture));
    this.reloadGalleria();
    this.activeIndex = pics.length - 1;
    this.onProgress = false;
    this.onChange(this.val);
    this.onTouch(this.val);
  }

  onError(event: any) {
    this.fileUpload.progress = 0;
    this.fileUpload.msgs.push({
      severity: 'error',
      detail: 'Upload failed!'
    });
    this.onProgress = false;

  }

  public onBeforeUpload() {
    this.onProgress = true;
  }

  activePictureChanged(ev) {
    this.activeIndex = ev.index;
  }

  createObjectForGallery(picture: Picture): { source: string } {
    return {
      source: picture._links.download.href
    };
  }
}
