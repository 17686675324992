import {
  AssemblySiteNode,
  ComponentNode,
  Country,
  EutrDocument,
  ExportersData,
  ForestManagersData,
  JoineryWorkshopNode,
  ManufacturersData,
  SawmillNode,
  WoodCompositionCertificationScopeVerdict,
  WoodCompositionCertificationValidityVerdict,
  WoodCompositionConsistencyVerdict,
  WoodCompositionCorrectnessVerdict,
  WoodCompositionInvoicesVerdict,
  WoodCompositionNode,
  WoodSpeciesNode,
  WoodSpeciesRecord
} from "../../shared/contracts";
import {ScoringUtil} from "./ScoringUtil";


export class ScoringWoodChainHelper {
  public static readonly LOW_CPI_THRESHOLD = 30;

  static notEligibleForCountingFields(doc: EutrDocument) {
    return !doc?.data?.woodComposition
      || !doc.data.woodComposition.componentNodes.length
      || !!doc.data.originWood.supplierLocatedInEurope
      || !!doc.data.originWood.flegtLicense
      || !!doc.data.originWood.citesLicense
      || !!doc.data.originWood.fromIndonesia
      || !!doc.data.productCertification.certification
      || !!doc.identification.productIdentification.swissOnly;
  }

  static calcFilledInRatioForLegalityDocument(doc: EutrDocument) {
    let valuesOfRelevantFields = doc.data.woodComposition.componentNodes
      .flatMap(cn => ScoringUtil.getWoodCompositionNodes(cn))
      .flatMap(wcn => wcn.woodSpeciesNodes)
      .flatMap(wsn => wsn.assemblySiteNodes)
      .flatMap(asn =>
        this.getManufacturersDataFields(asn.manufacturersData)
          .concat(this.getExportersDataFields(asn.exportersData))
          .concat(asn.joineryWorkshopNodes.flatMap(jwn =>
            this.getManufacturersDataFields(jwn.manufacturersData)
              .concat(this.getExportersDataFields(jwn.exportersData))
              .concat(jwn.sawmillNodes.flatMap(sn =>
                this.getManufacturersDataFields(sn.manufacturersData)
                  .concat(this.getExportersDataFields(sn.exportersData))
                  .concat(sn.forestryConcessionNodes.flatMap(fcn =>
                    this.getForestManagersDataFields(fcn.forestManagersData)
                      .concat(this.getExportersDataFields(fcn.exportersData))
                  ))
              ))
          ))
      );

    return this.getFilledInRatio(valuesOfRelevantFields);
  }

  static allDocumentsOfLegalityCertificationsAreCompliant(doc: EutrDocument): boolean {
    return !doc.conclusion.woodCompositionEvaluation
      || doc.conclusion.woodCompositionEvaluation.woodCompositionDocumentEvaluations
        .every(woodCompositionDocumentEvaluation =>
          (woodCompositionDocumentEvaluation.invoices === WoodCompositionInvoicesVerdict.COMPLIANT
            && woodCompositionDocumentEvaluation.certificationScope === WoodCompositionCertificationScopeVerdict.COMPLIANT
            && woodCompositionDocumentEvaluation.certificationValidity === WoodCompositionCertificationValidityVerdict.COMPLIANT
            && woodCompositionDocumentEvaluation.consistency === WoodCompositionConsistencyVerdict.COMPLIANT)
          || woodCompositionDocumentEvaluation.correctness === WoodCompositionCorrectnessVerdict.COMPLIANT);
  }

  static allCertificationDocumentsOfLegalityCertificationsAreCompliant(doc: EutrDocument): boolean {
    return !doc.conclusion.woodCompositionEvaluation
      || doc.conclusion.woodCompositionEvaluation.woodCompositionDocumentEvaluations
        .filter(doc => Object.keys(doc).length === 5)
        .every(woodCompositionDocumentEvaluation =>
          woodCompositionDocumentEvaluation.invoices === WoodCompositionInvoicesVerdict.COMPLIANT
          && woodCompositionDocumentEvaluation.certificationScope === WoodCompositionCertificationScopeVerdict.COMPLIANT
          && woodCompositionDocumentEvaluation.certificationValidity === WoodCompositionCertificationValidityVerdict.COMPLIANT
          && woodCompositionDocumentEvaluation.consistency === WoodCompositionConsistencyVerdict.COMPLIANT);
  }

  static getMajorCalculationScore(doc: EutrDocument): number {
    for (const componentNode of doc.data.woodComposition.componentNodes) {
      const massiveWoodPctg = componentNode.massiveWoodNode ? componentNode.massiveWoodNode.percentage : 0;
      const plyWoodPctg = componentNode.plywoodNode ? componentNode.plywoodNode.percentage : 0;
      const particleBoardPctg = componentNode.particleBoardNode ? componentNode.particleBoardNode.percentage : 0;
      if (massiveWoodPctg >= particleBoardPctg || plyWoodPctg >= particleBoardPctg) {
        return 500;
      }
    }
    return 0;
  }

  static getNumberOfChainsScore(doc: EutrDocument): number {
    return ScoringWoodChainHelper.numberOfLeavesInChainTree(doc.data.woodComposition.componentNodes) > 3 ? 500 : 0;
  }

  static numberOfLeavesInChainTree(componentNodes: ComponentNode[]): number {
    return componentNodes
      .flatMap(cn => ScoringUtil.getWoodCompositionNodes(cn))
      .map(wcn => ScoringWoodChainHelper.countLeavesInWoodCompositionNode(wcn))
      .reduce((a, b) => a + b, 0);
  }

  private static countLeavesInWoodCompositionNode(particleBoardNode: WoodCompositionNode): number {
    return ScoringWoodChainHelper.countLeavesInWoodSpeciesNodes(particleBoardNode.woodSpeciesNodes);
  }

  private static countLeavesInWoodSpeciesNodes(woodSpeciesNodes: WoodSpeciesNode[]): number {
    return woodSpeciesNodes
      .map(wsn => wsn.assemblySiteNodes.length
        ? ScoringWoodChainHelper.countLeavesInAssemblySiteNodes(wsn.assemblySiteNodes)
        : 1)
      .reduce((a, b) => a + b, 0);
  }

  private static countLeavesInAssemblySiteNodes(asns: AssemblySiteNode[]): number {
    return asns
      .map(asn => asn.joineryWorkshopNodes.length
        ? ScoringWoodChainHelper.countLeavesInJoineryWorkshopNodes(asn.joineryWorkshopNodes)
        : 1)
      .reduce((a, b) => a + b, 0);
  }

  static anyChainHasCertificateAndHighCpiCountry(doc: EutrDocument, countriesOfHarvest: Country[]): boolean {
    return doc.data.woodComposition.componentNodes
      .flatMap(cn => ScoringUtil.getWoodCompositionNodes(cn))
      .flatMap(wcn => wcn.woodSpeciesNodes)
      .filter(wsn => ScoringWoodChainHelper.woodSpeciesRecordHasCountryOfHarvestWithHighCpi(wsn.woodSpeciesRecord, countriesOfHarvest))
      .flatMap(wsn => wsn.assemblySiteNodes)
      .some(asn =>
        asn.certification
        || asn.joineryWorkshopNodes
          .some(jwn =>
            jwn.certification
            || jwn.sawmillNodes
              .some(sn =>
                sn.certification
                || sn.forestryConcessionNodes
                  .some(fcn => fcn.certification))));
  }

  private static countLeavesInSawmillNodes(smns: SawmillNode[]) {
    return smns
      .map(smn => smn.forestryConcessionNodes.length
        ? smn.forestryConcessionNodes.length
        : 1)
      .reduce((a, b) => a + b, 0);
  }

  static calcNumberOfCertifiedChainsOfCustodies(doc: EutrDocument, countriesOfHarvest: Country[]): number {
    return doc.data.woodComposition
      ? doc.data.woodComposition.componentNodes
        .flatMap(cn => ScoringUtil.getWoodCompositionNodes(cn))
        .flatMap(wcn => wcn.woodSpeciesNodes)
        .filter(wsn => ScoringWoodChainHelper.woodSpeciesRecordHasCountryOfHarvestWithHighCpi(wsn.woodSpeciesRecord, countriesOfHarvest))
        .flatMap(wsn => wsn.assemblySiteNodes)
        .map(asn => asn.certification
          ? Math.max(1, ScoringWoodChainHelper.countLeavesInJoineryWorkshopNodes(asn.joineryWorkshopNodes))
          : asn.joineryWorkshopNodes
            .map(jwn => jwn.certification
              ? Math.max(1, ScoringWoodChainHelper.countLeavesInSawmillNodes(jwn.sawmillNodes))
              // TODO_JORIS: dit klopt wss niet!
              : jwn.sawmillNodes
                .filter(sn => sn.certification)
                .length)
            .reduce((a, b) => a + b, 0))
        .reduce((a, b) => a + b, 0)
      : 0;
  }

  static documentedChainOfCustodyScore(doc: EutrDocument, countriesOfHarvest: Country[]) {
    let valuesOfRelevantFields = doc.data.woodComposition.componentNodes
      .flatMap(cn => ScoringUtil.getWoodCompositionNodes(cn))
      .flatMap(wcn => wcn.woodSpeciesNodes)
      .filter(wsn => ScoringWoodChainHelper.woodSpeciesRecordHasCountryOfHarvestWithHighCpi(wsn.woodSpeciesRecord, countriesOfHarvest))
      .flatMap(wsn => wsn.assemblySiteNodes)
      .flatMap(asn => asn.certification
        ? [asn.certification, asn.certificationTypeAndPercentage, asn.cocCode, asn.country, asn.name]
          .concat(ScoringWoodChainHelper.getManufacturersDataFields(asn.manufacturersData))
          .concat(ScoringWoodChainHelper.getExportersDataFields(asn.exportersData))
        : asn.joineryWorkshopNodes
          .flatMap(jwn => jwn.certification
            ? [jwn.certification, jwn.certificationTypeAndPercentage, jwn.cocCode, jwn.country, jwn.name]
              .concat(ScoringWoodChainHelper.getManufacturersDataFields(jwn.manufacturersData))
              .concat(ScoringWoodChainHelper.getExportersDataFields(jwn.exportersData))
            : jwn.sawmillNodes.flatMap(sn => sn.certification
              ? [sn.certification, sn.certificationTypeAndPercentage, sn.cocCode, sn.country, sn.name]
                .concat(ScoringWoodChainHelper.getManufacturersDataFields(sn.manufacturersData))
                .concat(ScoringWoodChainHelper.getExportersDataFields(sn.exportersData))
              : sn.forestryConcessionNodes.flatMap(fcn => fcn.certification
                ? [fcn.certification, fcn.certificationTypeAndPercentage, fcn.cocCode, fcn.country, fcn.name, fcn.region]
                  .concat(ScoringWoodChainHelper.getExportersDataFields(fcn.exportersData))
                  .concat(ScoringWoodChainHelper.getForestManagersDataFields(fcn.forestManagersData))
                : []))));

    return this.getFilledInRatio(valuesOfRelevantFields) > 0.8 ? 1000 : 0;
  }

  private static countLeavesInJoineryWorkshopNodes(jwns: JoineryWorkshopNode[]): number {
    return jwns
      .map(jwn => jwn.sawmillNodes.length
        ? ScoringWoodChainHelper.countLeavesInSawmillNodes(jwn.sawmillNodes)
        : 1)
      .reduce((a, b) => a + b, 0);
  }

  private static getFilledInRatio(el: any[]): number {
    return el.filter(val => val).length / el.length;
  }

  private static woodSpeciesRecordHasCountryOfHarvestWithHighCpi(woodSpeciesRecord: WoodSpeciesRecord, countriesOfHarvest: Country[]): boolean {
    return woodSpeciesRecord.countryOfHarvest
      && countriesOfHarvest.find(coh => coh.value === woodSpeciesRecord.countryOfHarvest).cpiindex > ScoringWoodChainHelper.LOW_CPI_THRESHOLD;
  }

  private static getManufacturersDataFields(manufacturersData: ManufacturersData): any[] {
    return manufacturersData.paymentOfTaxesAndRoyaltiesRecords.flatMap(record => [record.paymentOfTaxesAndRoyalties, record.document, record.date])
      .concat(manufacturersData.legalityOfProcessingRecords.flatMap(record => [record.legalityOfProcessing, record.document, record.date]))
      .concat(manufacturersData.certificationRecords.flatMap(record => [record.certification, record.document, record.date]));
  }

  private static getExportersDataFields(exportersData: ExportersData): any[] {
    return exportersData.paymentOfFeesAndRoyaltiesRecords.flatMap(record => [record.paymentOfFeesAndRoyalties, record.date, record.document])
      .concat(exportersData.legalityOfSaleAndExportRecords.flatMap(record => [record.legalityOfSaleAndExport, record.date, record.document]));
  }

  private static getForestManagersDataFields(forestManagersData: ForestManagersData): any[] {
    return forestManagersData.paymentOfTaxesAndRoyaltiesRecords.flatMap(r => [r.paymentOfTaxesAndRoyalties, r.date, r.document])
      .concat(forestManagersData.legalRightToHarvestRecords.flatMap(r => [r.legalRightToHarvest, r.date, r.document]))
      .concat(forestManagersData.legalityOfHarvestRecords.flatMap(r => [r.document, r.date, r.legalityOfHarvest]))
      .concat(forestManagersData.certificationRecords.flatMap(r => [r.document, r.date, r.certification]));
  }
}
