<p-dropdown [(ngModel)]="scientificNameSelection"
            [options]="referenceDataService.getScientificNameOptions()"
            [disabled]="disabled"
            [autoDisplayFirst]="false"
            [showClear]="true"
            styleClass="width-200px"></p-dropdown>
<div>
  <p-dropdown [(ngModel)]="commonNameSelection"
              [options]="referenceDataService.getCommonNameOptions()"
              [disabled]="disabled"
              [autoDisplayFirst]="false"
              [showClear]="true"
              styleClass="width-200px"></p-dropdown>
  <rb-error-marker [control]="ngControl.control"></rb-error-marker>
</div>

