import {Component, Input, OnInit} from '@angular/core';
import {EutrDocument} from "../../../shared/contracts";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'rb-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit {

  @Input() eutrDocument: EutrDocument;

  constructor(public userService: UserService) {
  }

  ngOnInit() {
  }

}
