import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {EutrPageComponent} from './eutr-page/eutr-page.component';
import {UserComponent} from './user/user.component';
import {AvatarComponent} from './avatar/avatar.component';
import {HeaderComponent} from './eutr-page/header/header.component';
import {PolicyAgreementComponent} from './eutr-page/pages/policy-agreement/policy-agreement.component';
import {IdentificationComponent} from './eutr-page/pages/identification/identification.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ProductIdentificationComponent} from './eutr-page/pages/identification/product-identification/product-identification.component';
import {AddItemModalComponent} from './eutr-page/pages/identification/product-identification/add-item-modal/add-item-modal.component';
import {DialogService, DynamicDialogModule} from "primeng/dynamicdialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DataComponent} from './eutr-page/pages/data/data.component';
import {ProductCompositionComponent} from './eutr-page/pages/data/general-information/product-composition/product-composition.component';
import {OriginWoodComponent} from './eutr-page/pages/data/general-information/origin-wood/origin-wood.component';
import {ProductCertificationComponent} from './eutr-page/pages/data/general-information/product-certification/product-certification.component';
import {NatureSpeciesOriginWoodComponent} from './eutr-page/pages/data/specific-information/nature-species-origin-wood/nature-species-origin-wood.component';
import {ProductCompositionSwissOnlyComponent} from './eutr-page/pages/data/general-information/product-composition-swiss-only/product-composition-swiss-only.component';
import {VolumeComponent} from './shared/volume/volume.component';
import {AdvancedOptionsComponent} from './eutr-page/pages/identification/product-identification/add-item-modal/advanced-options/advanced-options.component';
import {MajorSpeciesOfMassiveWoodComponent} from './eutr-page/pages/data/specific-information/major-species-of-massive-wood/major-species-of-massive-wood.component';
import {ConclusionComponent} from './eutr-page/pages/conclusion/conclusion.component';
import {WoodSpeciesComponent} from './shared/wood-species/wood-species.component';
import {RepeatableRowComponent} from './shared/repeatable-row/repeatable-row.component';
import {WoodCompositionComponent} from './eutr-page/pages/data/specific-information/wood-composition/wood-composition.component';
import {PercentageComponent} from './shared/percentage/percentage.component';
import {WoodWoodSpeciesRecordComponent} from './shared/wood-species-record/wood-species-record.component';
import {ManufacturersDataComponent} from './eutr-page/pages/data/specific-information/wood-composition/manufacturers-data/manufacturers-data.component';
import {RepeatableAccordionTabComponent} from './shared/repeatable-accordion-tab/repeatable-accordion-tab.component';
import {ComponentInfoComponent} from './eutr-page/pages/data/specific-information/wood-composition/component-info/component-info.component';
import {ForestManagersDataComponent} from './eutr-page/pages/data/specific-information/wood-composition/forest-managers-data/forest-managers-data.component';
import {RepeatableWoodSpeciesRecordComponent} from './eutr-page/pages/data/specific-information/nature-species-origin-wood/repeatable-species-record/repeatable-species-record.component';
import {SourcingComponent} from './eutr-page/pages/identification/sourcing/sourcing.component';
import {SupplierIdentificationComponent} from './eutr-page/pages/identification/supplier-identification/supplier-identification.component';
import {DocumentUploadComponent} from './shared/document-upload/document-upload.component';
import {ContentsModule} from "./util/contents/contents.module";
import {PageNotFoundComponent} from './error-pages/page-not-found/page-not-found.component';
import {AngularDateHttpInterceptor} from "./interceptors/AngularDateHttpInterceptor";
import {AddXRequestedWithHeaderHttpInterceptor} from "./services/add-x-requested-with-header-http-interceptor.service";
import {ExportersDataComponent} from './eutr-page/pages/data/specific-information/wood-composition/exporters-data/exporters-data.component';
import {CalendarComponent} from './shared/calendar/calendar.component';
import {Http401Interceptor} from "./interceptors/Http401Interceptor";
import {LoaderInterceptor} from "./interceptors/LoaderInterceptor";
import {StateSelectorComponent} from './shared/state-selector/state-selector.component';
import {DisableIfUnauthorizedDirective} from './directives/disable-if-unauthorized.directive';
import {HideIfUnauthorizedDirective} from './directives/hide-if-unauthorized.directive';
import {
  HttpXsrfCookieExtractor,
  HttpXsrfInterceptor,
  HttpXsrfTokenExtractor,
  XSRF_COOKIE_NAME,
  XSRF_HEADER_NAME
} from "./interceptors/XsrfHttpInterceptor";
import {OriginWoodEvaluationComponent} from './eutr-page/pages/conclusion/origin-wood-evaluation/origin-wood-evaluation.component';
import {ProductCertificationEvaluationComponent} from './eutr-page/pages/conclusion/product-certification-evaluation/product-certification-evaluation.component';
import {WoodCompositionEvaluationComponent} from './eutr-page/pages/conclusion/wood-composition-evaluation/wood-composition-evaluation.component';
import {ToastModule} from "primeng/toast";
import {ScoringComponent} from './eutr-page/pages/scoring/scoring.component';
import {SaveButtonComponent} from './shared/save-button/save-button.component';
import {GalleryComponent} from './shared/gallery/gallery.component';
import {ActionBarComponent} from './eutr-page/header/action-bar/action-bar.component';
import {EnvBarComponent} from './eutr-page/header/env-bar/env-bar.component';
import {HeaderBarComponent} from './eutr-page/header/header-bar/header-bar.component';
import {CanDeactivateGuard} from "./can-deactivate-guard.guard";
import {CanActivateGuard} from "./can-activate-guard.guard";
import {ForestryConcessionNodesComponent} from './eutr-page/pages/data/specific-information/wood-composition/nodes/forestry-concession-nodes/forestry-concession-nodes.component';
import {SawmillNodesComponent} from './eutr-page/pages/data/specific-information/wood-composition/nodes/sawmill-nodes/sawmill-nodes.component';
import {JoineryWorkshopNodesComponent} from './eutr-page/pages/data/specific-information/wood-composition/nodes/joinery-workshop-nodes/joinery-workshop-nodes.component';
import {AssemblySiteNodesComponent} from './eutr-page/pages/data/specific-information/wood-composition/nodes/assembly-site-nodes/assembly-site-nodes.component';
import {WoodSpeciesNodesComponent} from './eutr-page/pages/data/specific-information/wood-composition/nodes/wood-species-nodes/wood-species-nodes.component';
import {ErrorPillComponent} from './shared/error-pill/error-pill.component';
import {ChainSearchComponent} from './eutr-page/pages/data/specific-information/wood-composition/chain-search/chain-search.component';
import {FileUploadPaneComponent} from './eutr-page/sidebar-components/file-upload-pane/file-upload-pane.component';
import {NavigationComponent} from './eutr-page/sidebar-components/navigation/navigation.component';
import {SidebarComponent} from './eutr-page/sidebar-components/sidebar/sidebar.component';
import {AccordionModule} from 'primeng/accordion';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PanelModule} from 'primeng/panel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SidebarModule} from 'primeng/sidebar';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TooltipModule} from 'primeng/tooltip';
import {TreeTableModule} from 'primeng/treetable';
import {CheckboxComponent} from './shared/checkbox/checkbox.component';
import {ErrorMarkerComponent} from './shared/error-marker/error-marker.component';
import {InputComponent} from './shared/input/input.component';
import {DropdownComponent} from './shared/dropdown/dropdown.component';
import {PrintButtonComponent} from './shared/print-button/print-button.component';
import {RootResourceService} from "./services/root-resource.service";
import { DiscardButtonComponent } from './shared/discard-button/discard-button.component';
import { WoodCompositionPercentagesComponent } from './eutr-page/pages/data/specific-information/wood-composition/wood-composition-percentages/wood-composition-percentages.component';

@NgModule({
  declarations: [
    AppComponent,
    EutrPageComponent,
    UserComponent,
    AvatarComponent,
    HeaderComponent,
    PolicyAgreementComponent,
    IdentificationComponent,
    ProductIdentificationComponent,
    AddItemModalComponent,
    DataComponent,
    ProductCompositionComponent,
    OriginWoodComponent,
    ProductCertificationComponent,
    NatureSpeciesOriginWoodComponent,
    ProductCompositionSwissOnlyComponent,
    VolumeComponent,
    PercentageComponent,
    AdvancedOptionsComponent,
    MajorSpeciesOfMassiveWoodComponent,
    ConclusionComponent,
    WoodSpeciesComponent,
    RepeatableRowComponent,
    WoodCompositionComponent,
    WoodWoodSpeciesRecordComponent,
    ManufacturersDataComponent,
    RepeatableAccordionTabComponent,
    ComponentInfoComponent,
    ForestManagersDataComponent,
    RepeatableWoodSpeciesRecordComponent,
    SourcingComponent,
    SupplierIdentificationComponent,
    DocumentUploadComponent,
    PageNotFoundComponent,
    VolumeComponent,
    ExportersDataComponent,
    CalendarComponent,
    StateSelectorComponent,
    DisableIfUnauthorizedDirective,
    HideIfUnauthorizedDirective,
    OriginWoodEvaluationComponent,
    ProductCertificationEvaluationComponent,
    WoodCompositionEvaluationComponent,
    ScoringComponent,
    SaveButtonComponent,
    GalleryComponent,
    ActionBarComponent,
    EnvBarComponent,
    HeaderBarComponent,
    ForestryConcessionNodesComponent,
    SawmillNodesComponent,
    JoineryWorkshopNodesComponent,
    AssemblySiteNodesComponent,
    WoodSpeciesNodesComponent,
    ErrorPillComponent,
    ChainSearchComponent,
    FileUploadPaneComponent,
    NavigationComponent,
    SidebarComponent,
    CheckboxComponent,
    ErrorMarkerComponent,
    InputComponent,
    DropdownComponent,
    PrintButtonComponent,
    DiscardButtonComponent,
    WoodCompositionPercentagesComponent
  ],
  imports: [
    BrowserModule,
    ConfirmDialogModule,
    DialogModule,
    BrowserAnimationsModule,
    TooltipModule,
    AccordionModule,
    AppRoutingModule,
    OverlayPanelModule,
    PanelModule,
    CheckboxModule,
    ToggleButtonModule,
    HttpClientModule,
    CarouselModule,
    CardModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    DynamicDialogModule,
    InputTextModule,
    FormsModule,
    RadioButtonModule,
    DropdownModule,
    CalendarModule,
    GalleriaModule,
    FileUploadModule,
    ContentsModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    TabMenuModule,
    TabViewModule,
    ToastModule,
    TreeTableModule
  ],
  providers: [
    ConfirmationService,
    DialogService,
    MessageService,
    {provide: HTTP_INTERCEPTORS, useClass: AngularDateHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AddXRequestedWithHeaderHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: Http401Interceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true},
    {provide: HttpXsrfTokenExtractor, useClass: HttpXsrfCookieExtractor},
    {provide: XSRF_COOKIE_NAME, useValue: 'XSRF-TOKEN'},
    {provide: XSRF_HEADER_NAME, useValue: 'X-XSRF-TOKEN'},
    CanActivateGuard,
    CanDeactivateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: (rootResourceService: RootResourceService) => (): Promise<any> => rootResourceService.load(),
      deps: [RootResourceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [AddItemModalComponent]
})
export class AppModule {
}
