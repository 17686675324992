import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UidService} from "../../../../../../services/uid.service";

@Component({
  selector: 'rb-wood-composition-percentages',
  templateUrl: './wood-composition-percentages.component.html',
  styleUrls: ['./wood-composition-percentages.component.scss']
})
export class WoodCompositionPercentagesComponent {
  @Input() formGroup: FormGroup;
  @Input() title: string;
  uid: number;

  constructor(
    private uidService: UidService,
  ) {
    this.uid = uidService.generateUid();
  }

  unitChanged(volume) {
    const unit = volume.unit;
    this.formGroup.get('volume').setValue({
      amount: this.formGroup.get('volume').value.amount,
      unit: unit
    });
    this.formGroup.get('recycledVolume').setValue({
      amount: this.formGroup.get('volume').value.amount,
      unit: unit
    });
    this.formGroup.get('nonRecycledVolume').setValue({
      amount: this.formGroup.get('volume').value.amount,
      unit: unit
    });
  }

  volumeAmountChanged(volume) {
    const amount = volume.amount;
    const recycledPercentage = this.formGroup.get('recycledPercentage').value;
    this.formGroup.get('recycledVolume').setValue({
      amount: amount !== null && recycledPercentage !== null
        ? amount * recycledPercentage / 100
        : null,
      unit: this.formGroup.get('recycledVolume').value.unit
    });
    this.formGroup.get('nonRecycledVolume').setValue({
      amount: amount !== null && recycledPercentage !== null
        ? amount * (100 - recycledPercentage) / 100
        : null,
      unit: this.formGroup.get('nonRecycledVolume').value.unit
    });
  }

  recycledPercentageChanged(recycledPercentage) {
    if (recycledPercentage) {
      const nonRecycledPercentage = 100 - recycledPercentage;
      this.formGroup.get('nonRecycledPercentage').setValue(nonRecycledPercentage);
      const totalVolume = this.formGroup.get('volume').value.amount;
      if (totalVolume) {
        this.formGroup.get('recycledVolume').setValue({
          amount: totalVolume * recycledPercentage / 100,
          unit: this.formGroup.get('recycledVolume').value.unit
        });
        this.formGroup.get('nonRecycledVolume').setValue({
          amount: totalVolume * nonRecycledPercentage / 100,
          unit: this.formGroup.get('nonRecycledVolume').value.unit
        });
      }
    } else {
      this.formGroup.get('nonRecycledPercentage').setValue(null);
      this.formGroup.get('recycledVolume').setValue({
        amount: null,
        unit: this.formGroup.get('recycledVolume').value.unit
      });
      this.formGroup.get('nonRecycledVolume').setValue({
        amount: null,
        unit: this.formGroup.get('nonRecycledVolume').value.unit
      });
    }
  }

  recycledVolumeAmountChanged(volume) {
    const amount = volume.amount;
    const totalVolume = this.formGroup.get('volume').value.amount;

    this.formGroup.get('recycledPercentage').setValue(
      amount !== null
        ? (amount / totalVolume) * 100
        : null
    );
    this.formGroup.get('nonRecycledVolume').setValue({
      amount: amount !== null
        ? totalVolume - amount
        : null,
      unit: this.formGroup.get('nonRecycledVolume').value.unit
    });
    this.formGroup.get('nonRecycledPercentage').setValue(
      amount !== null
        ? 100 - this.formGroup.get('recycledPercentage').value
        : null
    );
  }

  nonRecycledPercentageChanged(nonRecycledPercentage) {
    if (nonRecycledPercentage) {
      const recycledPercentage = 100 - nonRecycledPercentage;
      this.formGroup.get('recycledPercentage').setValue(recycledPercentage);
      const totalVolume = this.formGroup.get('volume').value.amount;
      if (totalVolume) {
        this.formGroup.get('recycledVolume').setValue({
          amount: totalVolume * recycledPercentage / 100,
          unit: this.formGroup.get('recycledVolume').value.unit
        });
        this.formGroup.get('nonRecycledVolume').setValue({
          amount: totalVolume * nonRecycledPercentage / 100,
          unit: this.formGroup.get('nonRecycledVolume').value.unit
        });
      }
    } else {
      this.formGroup.get('recycledPercentage').setValue(null);
      this.formGroup.get('nonRecycledVolume').setValue({
        amount: null,
        unit: this.formGroup.get('nonRecycledVolume').value.unit
      });
      this.formGroup.get('recycledVolume').setValue({
        amount: null,
        unit: this.formGroup.get('recycledVolume').value.unit
      });
    }
  }

  nonRecycledVolumeAmountChanged(volume) {
    const amount = volume.amount;
    const totalVolume = this.formGroup.get('volume').value.amount;

    this.formGroup.get('nonRecycledPercentage').setValue(
      amount !== null
        ? (amount / totalVolume) * 100
        : null
    );
    this.formGroup.get('recycledPercentage').setValue(
      amount !== null
        ? 100 - this.formGroup.get('nonRecycledPercentage').value
        : null
    );
    this.formGroup.get('recycledVolume').setValue({
      amount: amount !== null
        ? totalVolume - amount
        : null,
      unit: this.formGroup.get('recycledVolume').value.unit
    });
  }
}
