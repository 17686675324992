<form [formGroup]="data" *ngIf="hasControls()">
  <fieldset>
    <legend>Forest managers</legend>
    <div formArrayName="certificationRecords">
      <rb-repeatable-row [records]="data.get('certificationRecords')"
                         [newRecordSupplier]="formService.createForestCertificationRecord.bind(formService)">
        <ng-template #record let-certificationRecord let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="certification-{{uid}}-{{index}}">Certification (if certified):</label>
            <rb-dropdown inputId="certification-{{uid}}-{{index}}"
                         formControlName="certification"
                         [options]="referenceDataService.getForestCertification()"
                         styleClass="width-200px"></rb-dropdown>
            <rb-document-upload
              formControlName="document"
            ></rb-document-upload>
            <rb-calendar formControlName="date"></rb-calendar>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
    <div formArrayName="legalRightToHarvestRecords">
      <rb-repeatable-row [records]="data.get('legalRightToHarvestRecords')"
                         [newRecordSupplier]="formService.createForestLegalRightToHarvestRecord.bind(formService)">
        <ng-template #record let-legalRightToHarvestRecord let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="legal-right-to-harvest-{{uid}}-{{index}}">Legal right to harvest:</label>
            <rb-dropdown inputId="legal-right-to-harvest-{{uid}}-{{index}}"
                         formControlName="legalRightToHarvest"
                         [options]="referenceDataService.getForestLegalRightToHarvest()"
                         styleClass="width-200px"></rb-dropdown>
            <rb-document-upload
              formControlName="document"
            ></rb-document-upload>
            <div style="display: inline-block;">
              <rb-calendar formControlName="date"></rb-calendar>
              <i class="pi pi-info-circle"
                 pTooltip="Valid agreement that confers the company (public, private, community owned) the right to manage the forest and the right to harvest the forest.
                 Official audit reports; environmental clearance certificates; approved harvest plans; coupe closure reports; codes of conducts;
                 publicly available information showing rigorous legislative supervision and timber tracking and control procedures;
                 official documents issued by competent authorities in a country of harvest; etc.'"></i>
            </div>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
    <div formArrayName="legalityOfHarvestRecords">
      <rb-repeatable-row [records]="data.get('legalityOfHarvestRecords')"
                         [newRecordSupplier]="formService.createForestLegalityOfHarvestRecord.bind(formService)">
        <ng-template #record let-legalityOfHarvestRecord let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="legality-of-harvest-{{uid}}-{{index}}" class="required">Legality of harvest:</label>
            <rb-dropdown inputId="legality-of-harvest-{{uid}}-{{index}}"
                         formControlName="legalityOfHarvest"
                         [options]="referenceDataService.getForestLegalityOfHarvest()"
                         styleClass="width-200px"></rb-dropdown>
            <rb-document-upload
              formControlName="document"
            ></rb-document-upload>
            <div style="display: inline-block;">
              <rb-calendar formControlName="date"></rb-calendar>
            </div>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
    <div formArrayName="paymentOfTaxesAndRoyaltiesRecords">
      <rb-repeatable-row [records]="data.get('paymentOfTaxesAndRoyaltiesRecords')"
                         [newRecordSupplier]="formService.createForestPaymentOfTaxesAndRoyaltiesRecord.bind(formService)">
        <ng-template #record let-paymentOfTaxesAndRoyaltiesRecord let-index="index">
          <div class="grid-4-cols-div-flex" [formGroupName]="index">
            <label for="payment-of-taxes-and-royalties-{{uid}}-{{index}}">Payment of taxes and royalties:</label>
            <rb-dropdown inputId="payment-of-taxes-and-royalties-{{uid}}-{{index}}"
                         formControlName="paymentOfTaxesAndRoyalties"
                         [options]="referenceDataService.getForestPaymentOfTaxesAndRoyalties()"
                         styleClass="width-200px"></rb-dropdown>
            <rb-document-upload
              formControlName="document"
            ></rb-document-upload>
            <div style="display: inline-block;">
              <rb-calendar formControlName="date"></rb-calendar>
            </div>
          </div>
        </ng-template>
      </rb-repeatable-row>
    </div>
  </fieldset>
</form>
