import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormService} from "../../services/form.service";
import {EutrDocument} from "../contracts";

@Component({
  selector: 'rb-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss']
})
export class PrintButtonComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @Input() eutrDocument: EutrDocument;

  constructor(public formService: FormService) {
  }

  ngOnInit(): void {
  }

  download() {
    this.downloadLink.nativeElement.click();
  }
}
