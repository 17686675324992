<div [formGroup]="record">
  <div>
    <label for="component-type-{{index}}" style="padding-right: 10px;" class="required">Type:</label>
    <div>
      <rb-dropdown inputId="component-type-{{index}}"
                   formControlName="componentType"
                   [options]="referenceDataService.getComponentTypes()"
                   [autoDisplayFirst]="false"
                   [group]="true"
                   styleClass="width-200px"></rb-dropdown>
    </div>
  </div>
  <div class="grid-4-cols">
    <rb-wood-composition-percentages title="Massive wood:"
                                     [formGroup]="record.get('massiveWoodNode')"></rb-wood-composition-percentages>
    <rb-wood-composition-percentages title="Particle board: (Chipboard/MDF)"
                                     [formGroup]="record.get('particleBoardNode')"></rb-wood-composition-percentages>
    <rb-wood-composition-percentages title="Plywood:"
                                     [formGroup]="record.get('plywoodNode')"></rb-wood-composition-percentages>
    <div class="bold">
      <span>Total: </span>
    </div>
    <div class="total-percentage" [ngStyle]="{'color': (getTotal() === '100.00' ? 'green' : 'red')}">
      {{getTotal()}} %
    </div>
  </div>
</div>
