<fieldset>
  <legend>Origin of the wood / licenses
    <rb-error-pill [formGroup]="form"></rb-error-pill>
  </legend>
  <form [formGroup]="form" class="originWood">
    <div class="or-wood-grid">
      <rb-checkbox label="Product supplier located in EU"
                   formControlName="supplierLocatedInEurope"
      ></rb-checkbox>
      <i
        *ngIf="form.get('supplierLocatedInEurope').value"
        class="pi pi-info-circle"
        pTooltip="- Operators are any natural or legal person who places timber and timber products on the EU market.
             - Traders are any natural or legal person who sells or buys on the internal EU market timber or timber products already placed on the internal market."></i>
      <div
        *ngIf="form.get('supplierLocatedInEurope').value">
        <p-radioButton *ngFor="let type of referenceDataService.getSupplierTypes()"
                       [value]="type.value"
                       label="{{type.label}}"
                       formControlName="supplierType"
                       name="supplierType"
                       [ngStyle]="{'margin-right': '15px'}"
        >
        </p-radioButton>
      </div>
      <rb-checkbox label="Timber with FLEGT license"
                   formControlName="flegtLicense"
      ></rb-checkbox>
      <rb-document-upload
        formControlName="flegtDocument"
      ></rb-document-upload>
      <rb-checkbox label="Timber with CITES license"
                   formControlName="citesLicense"
      ></rb-checkbox>
      <rb-document-upload
        formControlName="citesDocument"
      ></rb-document-upload>
      <rb-checkbox label="Timber from Indonesia"
                   formControlName="fromIndonesia"
      ></rb-checkbox>
      <rb-document-upload
        formControlName="svlkDocument"
      ></rb-document-upload>
    </div>
  </form>
</fieldset>
