<p-accordion>
  <rb-repeatable-accordion-tab [header]="computeHeader()"
                               [records]="form.get('data').get('woodComposition').get('componentNodes')"
                               [newRecordSupplier]="formService.createComponentNode.bind(formService)">
    <ng-template #header let-componentRecord let-index="index">
            <span>Component {{index + 1}} {{referenceDataService.resolveComponentType(componentRecord.get('componentType').value) == null
              ? ""
              : (" - " + referenceDataService.resolveComponentType(componentRecord.get('componentType').value))}}</span>
    </ng-template>
    <ng-template #record let-componentRecord let-index="index">
      <rb-component-info [record]="componentRecord" [index]="index"></rb-component-info>
      <p-accordion [multiple]="true">
        <rb-wood-species-nodes class="massive-wood"
                               title="Massive wood species"
                               label="MassiveWood"
                               [records]="componentRecord?.get('massiveWoodNode').get('woodSpeciesNodes')"
                               [prevHeader]="computeHeaderWithIndex(index)"
        ></rb-wood-species-nodes>
        <rb-wood-species-nodes class="particle-board"
                               title="Particle board species"
                               label="ParticleBoard"
                               [records]="componentRecord?.get('particleBoardNode').get('woodSpeciesNodes')"
                               [prevHeader]="computeHeaderWithIndex(index)"
        ></rb-wood-species-nodes>
        <rb-wood-species-nodes class="plywood"
                               title="Plywood species"
                               label="Plywood"
                               [records]="componentRecord?.get('plywoodNode').get('woodSpeciesNodes')"
                               [prevHeader]="computeHeaderWithIndex(index)"
        ></rb-wood-species-nodes>
      </p-accordion>
    </ng-template>
  </rb-repeatable-accordion-tab>
</p-accordion>
