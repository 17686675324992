import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {ProductIdentificationItem} from "../shared/contracts";

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private httpClient: HttpClient) {

  }

  findItem(itemCode: string): Observable<ProductIdentificationItem> {
    return this.httpClient.get<ProductIdentificationItem>(environment.apiUrl + 'item', {
      params: {
        code: itemCode
      }
    });
  }

  findItemWithAdvancedOptions(itemCode: string, supplierItemReference: string, customer: string, customerItemNumber: string): Observable<ProductIdentificationItem> {
    return this.httpClient.get<ProductIdentificationItem>(environment.apiUrl + 'item', {
      params: {
        code: itemCode
      }
    });
  }
}
