<div class="container" contents [scrollingView]="container" *ngIf="eutrDocumentService.initialized">
  <rb-sidebar [form]="this.form" [eutrDocument]="eutrDocumentService.getEutrDocument()"></rb-sidebar>
  <div class="content" [class.statusChangeAttempt]="formService.stateChangeAttempted" #container>
    <div class="section"
         [contentsSection]="'policyAndAgreement'"
         [rbHideIfUnauthorized]="Section.POLICYAGREEMENT"
         [rbDisableIfUnauthorized]="Section.POLICYAGREEMENT"
         [state]="eutrDocument.state"
         [form]="form.get('agreement')"
    >
      <rb-policy-agreement [form]="form"></rb-policy-agreement>
    </div>
    <div class="section"
         [contentsSection]="'identification'"
         [rbHideIfUnauthorized]="Section.IDENTIFICATION"
         [rbDisableIfUnauthorized]="Section.IDENTIFICATION"
         [state]="eutrDocument.state"
         [form]="form.get('identification')"
    >
      <rb-identification [eutrDocument]="eutrDocument" [form]="form"></rb-identification>
    </div>
    <div class="section"
         [contentsSection]="'data'"
         [rbHideIfUnauthorized]="Section.DATA"
         [rbDisableIfUnauthorized]="Section.DATA"
         [state]="eutrDocument.state"
         [form]="form.get('data')"
    >
      <rb-data [eutrDocument]="eutrDocument" [form]="form"></rb-data>
    </div>
    <div class="section"
         [contentsSection]="'conclusion'"
         [rbHideIfUnauthorized]="Section.CONCLUSION"
         [rbDisableIfUnauthorized]="Section.CONCLUSION"
         [state]="eutrDocument.state"
         [form]="form.get('conclusion')"
    >
      <rb-conclusion *ngIf="form.get('conclusion')"
                     [form]="form"
      ></rb-conclusion>
    </div>
    <div class="section"
         [contentsSection]="'scoring'"
         [rbHideIfUnauthorized]="Section.SCORING"
         [state]="eutrDocument.state"
    >
      <rb-scoring *ngIf="eutrDocument.scoring"
                  [scoring]="form.get('scoring').value"
      ></rb-scoring>
    </div>
    <div class="white-space-page-end"></div>
  </div>
</div>
<p-confirmDialog
  key="confirmStateChange"
  header="Confirmation"
  acceptLabel="Confirm"
  [rejectVisible]="false"
></p-confirmDialog>
<p-toast></p-toast>
