import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {EutrDocument} from "../../../shared/contracts";
import {HttpRequestsService} from "../../../services/http-requests.service";

@Component({
  selector: 'rb-file-upload-pane',
  templateUrl: './file-upload-pane.component.html',
  styleUrls: ['./file-upload-pane.component.scss']
})
export class FileUploadPaneComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() eutrDocument: EutrDocument;

  constructor(public httpRequestsService: HttpRequestsService) {
  }

  ngOnInit() {
  }

}
