import {Component, Input} from '@angular/core';
import {FormArray} from "@angular/forms";
import {FormService} from "../../../../../../../services/form.service";
import {ReferenceDataService} from "../../../../../../../services/reference-data.service";
import {UidService} from "../../../../../../../services/uid.service";
import {ChainService} from "../../../../../../../services/chain.service";
import {SawmillNode} from "../../../../../../../shared/contracts";

@Component({
  selector: 'rb-sawmill-nodes',
  templateUrl: './sawmill-nodes.component.html',
  styleUrls: ['./sawmill-nodes.component.scss']
})
export class SawmillNodesComponent {
  @Input() records: FormArray;
  @Input() hdr: string;
  uid: number;

  constructor(
    public formService: FormService,
    public referenceDataService: ReferenceDataService,
    private uidService: UidService,
    public chainService: ChainService,
  ) {
    this.uid = uidService.generateUid();
  }

  computeTitle(base: string, index: number, data: string) {
    return base + " " + (index + 1) + (data ? (" - " + data) : "");
  }

  computeSawmillHeader(): string {
    return this.hdr + "Sawmill";
  }

  // TODO_JORIS cleanup
  computeSawmillHeaderWithIndex(smIndex: number): string {
    return this.computeSawmillHeader() + smIndex;
  }

  onConfirmedNode(node: SawmillNode, index: number) {
    let formGroup = this.formService.createSawmillNode(node);
    this.records.setControl(index, formGroup);
    this.formService.triggerTreeCuttingFromSawmill(formGroup);
    this.formService.recomputeWoodCompositionEvaluation();
  }
}
